import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import ReactPlayer from 'react-player';
import { Carousel } from 'react-responsive-carousel'
import './lotImageCarousel.scss'
import getYouTubeID from 'get-youtube-id'

import React, { useEffect } from 'react';
import IMedia from '../../interfaces/media';

interface InputProps {
    mediaList: IMedia[]
}


const YoutubeSlide = ({ videoId }: any) => (
    <ReactPlayer width="100%" height="100%" url={`https://www.youtube.com/embed/${getYouTubeID(videoId)}`} playing={false} />
);

const LotImageCarousel = (props: InputProps) => {

    const customRenderItem = (item: any, props: any) => <item.type {...item.props} {...props} />;

    const customRenderThumb = (children: any) => {
        return (
            children.map((item: any) => {
                if (item.type === "img") {
                    return <img alt={item.props.alt} src={item.props.src} />
                } else {
                    return <img alt={item.props.videoId} src={`https://img.youtube.com/vi/${getYouTubeID(item.props.videoId)}/default.jpg`} />;
                }
            })
        )
    }

    useEffect(() => {
        console.log(props.mediaList)

        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    return (
       
        <Carousel
            renderItem={customRenderItem}
            renderThumbs={customRenderThumb}
            infiniteLoop={props.mediaList.length > 1}
            emulateTouch={true}
            showArrows={props.mediaList.length > 1}
            showThumbs={props.mediaList.length > 1}
            showIndicators={props.mediaList.length > 1}
            showStatus={props.mediaList.length > 1}
        >
            {props.mediaList.map((media, index) => {
                if (media.mediaType === "YOUTUBE") {
                    return (<YoutubeSlide key={index} videoId={media.src} />)
                } else {
                    return (<img key={index} alt={media.title} src={media.src} />)
                }
            })}


        </Carousel>
    );

};

export default LotImageCarousel;