import React, { useState } from 'react'
import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import IUser from '../../interfaces/user'
import AddUserDialog from '../Dialogs/AddUsersDialog/AddUserDialog'

interface InputProps {
    allUsers: IUser[]
    setAllUsers: (allUsers: IUser[]) => void
    eventId: string
}

const UserSection = (props: InputProps) => {

    const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState<boolean>(false);

    const closeDialog = () => {
        setIsAddUserDialogOpen(false)
    }

    const removeUserFromEvent = (uid: string) => {

        console.log(uid)

        let tempUsers = [...props.allUsers]

        tempUsers.forEach(user => {
            if (user.uid === uid){
                user.allocatedEventId = ""
            }
        });

        console.log(tempUsers)

        props.setAllUsers(tempUsers)
    }

    return (
        <div className="custom-Purchase-detail-section">
            <h2 className="subtitle">Users.</h2>
            <span className="description">Enable access for users to be able to collect payments for this event.</span>
            <Paper className="input-wrapper">
                {props.allUsers.filter(user => user.allocatedEventId === props.eventId).length > 0 ?
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <div className="table-header-cell">
                                        <span>Username</span>
                                    </div>
                                </TableCell>
                                <TableCell className="hide-on-mobile">
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.allUsers.filter(user => user.allocatedEventId === props.eventId).map((user: IUser, index) => (
                                <TableRow key={user._id}>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell className="hide-on-mobile">
                                        <div className="action-cell">
                                            <div onClick={() => removeUserFromEvent(user.uid)}><Tooltip title="Remove User"><DeleteIcon /></Tooltip></div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    :
                    <div className="no-entries">No users defined.</div>
                }
                <div className="button-wrapper">
                    <Button className="lh-button" onClick={() => setIsAddUserDialogOpen(true)}>Add Users</Button>
                </div>
            </Paper>
            <AddUserDialog
                handleClose={closeDialog}
                isDialogOpen={isAddUserDialogOpen}
                allUsers={props.allUsers}
                setAllUsers={props.setAllUsers}
                eventId={props.eventId}
            />
        </div>
    )
}

export default UserSection