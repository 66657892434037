export default interface IUser {
    _id: string
    uid: string
    email: string
    isAdmin: boolean
    allocatedEventId: string
}

export const DEFAULT_USER: IUser = {
    _id: '',
    uid: '',
    email: '',
    isAdmin: false,
    allocatedEventId: ''
}

export const DEFAULT_FIRE_TOKEN = ''
