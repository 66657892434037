import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import logging from '../../config/logging'
import UserContext from '../../contexts/user'

export interface InputProps { }

const AuthRoute: React.FunctionComponent<InputProps> = (props) => {
    const { children } = props
    const userContext = useContext(UserContext)

    if (userContext.userState.user._id === '') {
        console.log(userContext)
        logging.info('Unauthorised, redirecting ...')
        return <Redirect to="/admin" />
    } else {
        return <>{children}</>
    }

}

export default AuthRoute
