import { Card, CardContent, Link, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import React, { useEffect, useRef, useState, useContext } from 'react'
import { RouteComponentProps } from 'react-router'
import Loading from '../../components/Loading/Loading'
import useFetch from '../../hooks/useFetch'
import IEvent from '../../interfaces/event'
import IPageProps from '../../interfaces/page'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import IPurchase from '../../interfaces/purchase'
import moment from 'moment'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import VisibilityIcon from '@mui/icons-material/Visibility'
import EditPurchaseStatusDialog from '../../components/Dialogs/EditPurchaseStatusDialog/EditPurchaseStatusDialog'
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog/ConfirmationDialog'
import useExcel from '../../hooks/useExcel'
import UserContext from '../../contexts/user'
import './purchaseReport.scss'
import PurchaseFilters from '../../components/PurchaseFilters/PurchaseFilters'
import _ from "lodash"


const PurchaseReportPage: React.FunctionComponent<IPageProps & RouteComponentProps<any>> = (props) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>("")
    const [event, setEvent] = useState<IEvent>({} as IEvent)
    const [columnToSort, setColumnToSort] = useState("timestamp");
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc')
    const [isPurchaseStausDialogOpen, setIsPurchaseStausDialogOpen] = useState<boolean>(false)
    const [tempPurchase, setTempPurchase] = useState<IPurchase>({} as IPurchase);
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
    const [dialogProperties, setDialogProperties] = useState<any>({ type: "", title: "", message: "" })
    const [purchaseToBeDeleted, setPurchaseToBeDeleted] = useState<IPurchase>({} as IPurchase)
    const [purchases, setPurchases] = useState<IPurchase[]>([])
    const [filteredPurchases, setFilteredPurchases] = useState<IPurchase[]>([])
    const isCancelled = useRef(false)
    const [purchaseFilters, setPurchaseFilters] = useState<any>({ name: "", tableNumber: "All" })


    // var _ = require('lodash')

    const eventApi = useFetch("events");
    const excel = useExcel();

    const userContext = useContext(UserContext)

    const getEvent = (): void => {
        if (!event._id) {
            setLoading(true)
        }

        eventApi.get(`/getEventById/${props.match.params.eventId}/true`).then((event: IEvent) => {
            setEvent(event)
            setPurchases(event.purchases.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()))
            filterPurchases(event.purchases.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()))
            setLoading(false)
        }).catch((err: Error) => {
            setError(err.message)
            setLoading(false)
        })
    }

    const savePurchase = () => {
        eventApi.post(`/updatePurchase`, { "_id": event._id, "purchase": { ...tempPurchase, paymentTakenByUserId: userContext.userState.user.email } })
            .then((result: any) => {
                setDialogProperties({ type: "SUCCESS", title: "Save complete.", message: "The purchase has successfully been updated." })
                setIsConfirmationDialogOpen(true)
                setIsPurchaseStausDialogOpen(false)
                getEvent()
            })
            .catch((err: Error) => {
                setDialogProperties({ type: "ERROR", title: "Save failed.", message: `Your event did not save due to the following error: ${err}` })
                setIsConfirmationDialogOpen(true)
                setIsPurchaseStausDialogOpen(false)
            })
    }

    const deletePurchase = (purchase: IPurchase): void => {
        console.log("DELETE PUCHASE")
        eventApi.post(`/deletePurchase`, { "_id": event._id, "purchase": purchase })
            .then((result: any) => {
                setDialogProperties({ type: "SUCCESS", title: "Purchase Deleted.", message: "The purchase has successfully been deleted." })
                setIsConfirmationDialogOpen(true)
                setIsPurchaseStausDialogOpen(false)
                getEvent()
            })
            .catch((err: Error) => {
                setDialogProperties({ type: "ERROR", title: "Delete failed.", message: `Your purchase was not deleted due to the following error: ${err}` })
                setIsConfirmationDialogOpen(true)
                setIsPurchaseStausDialogOpen(false)
            })
    }

    const handleSort = (columnName: string) => {
        setColumnToSort(columnName)
        let sortDirect: any = columnToSort === columnName ? invertDirection(sortDirection) : 'desc'
        setSortDirection(sortDirect)
    }

    const invertDirection = (currentDirection: string) => {
        if (currentDirection === "asc") {
            return "desc"
        } else if (currentDirection === "desc") {
            return "asc"
        }
    }

    const filterPurchases = (_purchases?: IPurchase[]): void => {

        let filteredPurchases
        if (_purchases) {
            filteredPurchases = _purchases;
        } else {
            filteredPurchases = purchases;
        }

        filteredPurchases = filteredPurchases.filter(purchase => {
            if (userContext.userState.user.isAdmin) {
                return (
                    (purchaseFilters.tableNumber === "All" || getTableNumber(purchase) === purchaseFilters.tableNumber) &&
                    (purchase.name.toLowerCase().indexOf(purchaseFilters.name.toLowerCase()) > -1)
                )
            } else {
                return (
                    (purchaseFilters.tableNumber === "All" || getTableNumber(purchase) === purchaseFilters.tableNumber) &&
                    (purchase.name.toLowerCase().indexOf(purchaseFilters.name.toLowerCase()) > -1) &&
                    (purchase.status === "PENDING")
                )
            }
        });

        sortFilteredPurchases(filteredPurchases)
    }

    const determineIsTableNumber = (event: IEvent): boolean => {
        let isTableNumber = false
        event.customPurchaseDetails && event.customPurchaseDetails.forEach(element => {
            if (element.type === "TABLE") {
                isTableNumber = true
            }
        });
        return isTableNumber
    }

    const getTableNumber = (purchase: IPurchase): number => {
        let tableNumber = ""
        purchase.customPurchaseDetails && purchase.customPurchaseDetails.forEach(element => {
            if (element.type === "TABLE") {
                tableNumber = element.value
            }
        });
        return Number(tableNumber)
    }

    const closePurchaseDialog = () => {
        setIsPurchaseStausDialogOpen(false)
    }


    const handleCloseDialog = (isConfirmed: boolean) => {
        if (dialogProperties.label === "DELETE") {
            if (isConfirmed) {
                deletePurchase(purchaseToBeDeleted)
            }
        }
        setDialogProperties({})
        setIsConfirmationDialogOpen(false)
    }


    const openDialog = (purchase: IPurchase) => {
        setTempPurchase(purchase)
        setIsPurchaseStausDialogOpen(true)
    }

    const openDeleteConfirmationDialog = (purchase: IPurchase) => {
        setDialogProperties({ type: "AREYOUSURE", title: "Confirm Delete", message: "Are you sure you want to delete this purchase?", label: "DELETE" })
        setIsConfirmationDialogOpen(true)
        setPurchaseToBeDeleted(purchase)
    }

    const sortFilteredPurchases = (purchasesToSort: IPurchase[]) => {
        let tempPurchases

        tempPurchases = [...purchasesToSort]

        if (columnToSort === "tickets-bought") {
            setFilteredPurchases(_.orderBy(tempPurchases,
                function (item: IPurchase) {
                    return item.ticketPricingBundle.multiple
                },
                sortDirection))
        } else if (columnToSort === "total-cost") {
            setFilteredPurchases(_.orderBy(tempPurchases,
                function (item: IPurchase) {
                    return item.ticketPricingBundle.price
                },
                sortDirection))
        } else if (columnToSort === "tableNumber") {
            setFilteredPurchases(_.orderBy(tempPurchases,
                function (item: IPurchase) {
                    return getTableNumber(item)
                },
                sortDirection))
        } else if (columnToSort === "status") {
            setFilteredPurchases(_.orderBy(tempPurchases,
                function (item: IPurchase) {
                    if (item.status === "PENDING") {
                        return "PENDING"
                    } else if (item.status === "PAID" && item.transactionType === "ONLINE") {
                        return "PAID (Online)"
                    } else if (item.status === "PAID" && item.transactionType === "OFFLINE" && item.paymentType === "CARD") {
                        return "PAID (Card)"
                    } else if (item.status === "PAID" && item.transactionType === "OFFLINE" && item.paymentType === "CASH") {
                        return "PAID (Cash)"
                    }
                },
                sortDirection))
        } else if (columnToSort === "ticket-numbers") {
            setFilteredPurchases(_.orderBy(tempPurchases,
                function (item: IPurchase) {
                    return item.ticketNumbers[0]
                },
                sortDirection))
        } else {
            setFilteredPurchases(_.orderBy(tempPurchases, columnToSort, sortDirection))
        }
    }


    useEffect(() => {
        sortFilteredPurchases(purchases)
        // eslint-disable-next-line
    }, [columnToSort, sortDirection])

    useEffect(() => {
        getEvent()

        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    useEffect(() => {
        let timer = setTimeout(() => {
            getEvent()
        }, 10000);

        return () => {
            isCancelled.current = true;
            clearTimeout(timer);

        };

        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [event, filteredPurchases]);

    useEffect(() => {
        filterPurchases()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [purchaseFilters]);

    if (loading) {
        return (
            <Loading />
        )
    }

    if (error) {
        if (error === "TypeError: Failed to fetch" && event._id) {
            console.log("Failed to fetch error after first fetch - simply do not refresh page.")
        } else {
            return (
                <p>{`ERROR: ${error}`}</p>
            )
        }
    }

    return (
        <div className="purchase-report-page">
            <p><i>This page automatically refreshes every 10 seconds to show the latest purchases.</i></p>
            <PurchaseFilters
                purchaseFilters={purchaseFilters}
                setPurchaseFilters={setPurchaseFilters}
                purchases={purchases}
                getTableNumber={getTableNumber}
                isTableNumber={determineIsTableNumber(event)}
            />
            {filteredPurchases && filteredPurchases.length > 0 ?
                <>
                    <Card>
                        <CardContent>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="hide-on-mobile">
                                            <div onClick={() => handleSort("timestamp")} className="table-header-cell sortable">
                                                <span>Timestamp</span>
                                                {
                                                    columnToSort === "timestamp" ? (
                                                        sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                                    ) : null
                                                }
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div onClick={() => handleSort("name")} className="table-header-cell sortable">
                                                <span>Purchaser Name</span>
                                                {
                                                    columnToSort === "name" ? (
                                                        sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                                    ) : null
                                                }
                                            </div>
                                        </TableCell>
                                        {determineIsTableNumber(event) &&
                                            <TableCell>
                                                <div onClick={() => handleSort("tableNumber")} className="table-header-cell sortable">
                                                    <span>Table Number</span>
                                                    {
                                                        columnToSort === "tableNumber" ? (
                                                            sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                                        ) : null
                                                    }
                                                </div>
                                            </TableCell>
                                        }
                                        <TableCell className="hide-on-mobile">
                                            <div onClick={() => handleSort("tickets-bought")} className="table-header-cell sortable">
                                                <span>Tickets Bought</span>
                                                {
                                                    columnToSort === "tickets-bought" ? (
                                                        sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                                    ) : null
                                                }
                                            </div>
                                        </TableCell>
                                        <TableCell className="hide-on-mobile">
                                            <div onClick={() => handleSort("total-cost")} className="table-header-cell sortable">
                                                <span>Total Cost</span>
                                                {
                                                    columnToSort === "total-cost" ? (
                                                        sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                                    ) : null
                                                }
                                            </div>
                                        </TableCell>
                                        <TableCell className="hide-on-mobile">
                                            <div onClick={() => handleSort("ticket-numbers")} className="table-header-cell sortable">
                                                <span>Ticket Numbers</span>
                                                {
                                                    columnToSort === "ticket-numbers" ? (
                                                        sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                                    ) : null
                                                }
                                            </div>
                                        </TableCell>
                                        <TableCell className="hide-on-mobile">
                                            <div onClick={() => handleSort("status")} className="table-header-cell sortable">
                                                <span>Status</span>
                                                {
                                                    columnToSort === "status" ? (
                                                        sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                                    ) : null
                                                }
                                            </div>
                                        </TableCell>



                                        <TableCell className="hide-on-mobile">
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredPurchases.map((purchase, index) => (
                                        <TableRow key={index}>
                                            <TableCell className="hide-on-mobile" onClick={() => openDialog(purchase)}>{moment(purchase.timestamp).format("HH:mm DD/MM/YYYY")}</TableCell>
                                            <TableCell onClick={() => openDialog(purchase)}>{purchase.name}</TableCell>
                                            {determineIsTableNumber(event) &&
                                                <TableCell>{getTableNumber(purchase)}</TableCell>
                                            }
                                            <TableCell className="hide-on-mobile" onClick={() => openDialog(purchase)}>{new Intl.NumberFormat().format(purchase.ticketPricingBundle.multiple)}</TableCell>
                                            <TableCell className="hide-on-mobile" onClick={() => openDialog(purchase)}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'GBP', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(purchase.ticketPricingBundle.price)}</TableCell>
                                            <TableCell className="hide-on-mobile" onClick={() => openDialog(purchase)}>
                                                {`${event.eventPrefix}_${purchase.ticketNumbers[0]}`}
                                                {purchase.ticketNumbers.length > 1 &&
                                                    <span>{` - ${event.eventPrefix}_${purchase.ticketNumbers[purchase.ticketNumbers.length - 1]}`}</span>
                                                }
                                            </TableCell>
                                            <TableCell className="hide-on-mobile" onClick={() => openDialog(purchase)}>
                                                {purchase.status}
                                                {purchase.status === "PAID" && purchase.transactionType === "ONLINE" && <span className='transaction-type'> (Online)</span>}
                                                {purchase.status === "PAID" && purchase.transactionType === "OFFLINE" && purchase.paymentType === "CARD" && <span className='transaction-type'> (Card)</span>}
                                                {purchase.status === "PAID" && purchase.transactionType === "OFFLINE" && purchase.paymentType === "CASH" && <span className='transaction-type'> (Cash)</span>}
                                            </TableCell>

                                            <TableCell className="hide-on-mobile">
                                                <div className="action-cell">
                                                    <div onClick={() => openDialog(purchase)}>
                                                        {purchase.transactionType === "ONLINE" ?
                                                            <Tooltip title="View Purchase" enterDelay={500}><VisibilityIcon /></Tooltip>
                                                            :
                                                            <Tooltip title="View / Edit Purchase" enterDelay={500}><CreateIcon /></Tooltip>
                                                        }
                                                    </div>
                                                    {userContext.userState.user.isAdmin &&
                                                        <div onClick={() => openDeleteConfirmationDialog(purchase)}>
                                                            <Tooltip title="Delete Purchase" enterDelay={500}><DeleteIcon /></Tooltip>
                                                        </div>
                                                    }
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card >
                    {userContext.userState.user.isAdmin &&
                        <div className="button-wrapper space-between">
                            <Link onClick={() => excel.generatePurchaseReport(event, purchases)}>Download Purchase Report</Link>
                            <div></div>
                        </div>
                    }
                </>
                :
                <Card><CardContent><div className="no-entries">No purchases found.</div></CardContent></Card>
            }
            <EditPurchaseStatusDialog
                handleClose={closePurchaseDialog}
                isDialogOpen={isPurchaseStausDialogOpen}
                purchase={tempPurchase}
                setPurchase={setTempPurchase}
                savePurchase={savePurchase}
                isTableNumber={determineIsTableNumber(event)}
                tableNumber={getTableNumber(tempPurchase)}
                event={event}
                totalTicketsBought={tempPurchase.ticketPricingBundle && tempPurchase.ticketPricingBundle.multiple}
                totalTicketPrice={tempPurchase.ticketPricingBundle && tempPurchase.ticketPricingBundle.price}
            />
            <ConfirmationDialog
                handleClose={handleCloseDialog}
                isDialogOpen={isConfirmationDialogOpen}
                message={dialogProperties.message}
                title={dialogProperties.title}
                type={dialogProperties.type}
                label={dialogProperties.label}
            />
        </div>

    )
}

export default PurchaseReportPage
