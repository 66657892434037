import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import moment from 'moment'
import IEvent from '../interfaces/event';
import IPurchase from '../interfaces/purchase';
import IWinningTicket from '../interfaces/winningTicket';

const useExcel = () => {

    const generatePurchaseReport = (event: IEvent, purchases: IPurchase[]) => {

        

        //Create workbook and worksheet
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet('Purchase Report');

        //set the headers
        let headers = ["Timestamp", "Purchaser Name", "Email", "Phone", "Tickets Bought", "Total Cost (£)", "Ticket Numbers", "Status", "Payment Taken By", "Payment Type", "Payment Reference"];
        event.customPurchaseDetails.forEach(customPurchaseDetail => {
            headers.push(customPurchaseDetail.label);
        });
        let headerRow = worksheet.addRow(headers);
        headerRow.font = { bold: true, color: { argb: "FFFFFFFF" } };

        //set the widths of the columns and fill the header row color
        headers.forEach((element, index) => {
            worksheet.getColumn(index + 1).width = 25;
            headerRow.getCell(index + 1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF1D3461' } };
        });

        const determineTicketNumbers = (purchase: IPurchase): string => {
            let ticketNumbers = `${event.eventPrefix}_${purchase.ticketNumbers && purchase.ticketNumbers[0]}`

            if (purchase.ticketNumbers.length > 1) {
                ticketNumbers += ` - ${event.eventPrefix}_${purchase.ticketNumbers[purchase.ticketNumbers.length - 1]}`
            }

            return ticketNumbers
        }


        purchases.forEach(purchase => {

            let status = ""

            if (purchase.status === "PENDING") {
                status = "PENDING"
            } else if (purchase.status === "PAID" && purchase.transactionType === "ONLINE") {
                status = "PAID (Online)"
            } else if (purchase.status === "PAID" && purchase.transactionType === "OFFLINE" && purchase.paymentType === "CARD") {
                status = "PAID (Card)"
            } else if (purchase.status === "PAID" && purchase.transactionType === "OFFLINE" && purchase.paymentType === "CASH") {
                status = "PAID (Cash)"
            }

            let row: any[] = [
                moment(purchase.timestamp).format('HH:mm:ss DD/MM/yyyy'),
                purchase.name,
                purchase.email,
                purchase.phone,
                purchase.ticketPricingBundle.multiple,
                purchase.ticketPricingBundle.price,
                // `${event.eventPrefix}_${purchase.ticketNumbers[0]} - ${event.eventPrefix}_${purchase.ticketNumbers[purchase.ticketNumbers.length - 1]}`,
                determineTicketNumbers(purchase),
                status,
                purchase.transactionType === "ONLINE" ? "Stripe" : purchase.paymentTakenByUserId,
                purchase.transactionType === "ONLINE" ? "CARD" : purchase.paymentType,
                purchase.transactionType === "ONLINE" ? purchase.stripePaymentIntentId : purchase.paymentReference

            ]

            purchase.customPurchaseDetails.forEach(element => {
                row.push(element.value)
            });

            worksheet.addRow(row)
        });

        //Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, `${event.name}_purchase_report.xlsx`);
        })
    }


    const generateWinnerReport = (event: IEvent) => {

        //Create workbook and worksheet
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet('Winner Report');

        //set the headers
        let headers = ["Winner Number", "Winning Ticket Number", "Ticket Owner", "Email", "Phone", "Tickets Bought", "Total Cost (£)", "Ticket Numbers", "Payment Taken By", "Payment Type", "Payment Reference"];
        event.customPurchaseDetails.forEach(customPurchaseDetail => {
            headers.push(customPurchaseDetail.label);
        });
        let headerRow = worksheet.addRow(headers);
        headerRow.font = { bold: true, color: { argb: "FFFFFFFF" } };

        //set the widths of the columns and fill the header row color
        headers.forEach((element, index) => {
            worksheet.getColumn(index + 1).width = 25;
            headerRow.getCell(index + 1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF1D3461' } };
        });

        const determineTicketNumbers = (winningTicket: IWinningTicket): string => {
            let ticketNumbers = `${event.eventPrefix}_${winningTicket.ticket.purchase.ticketNumbers && winningTicket.ticket.purchase.ticketNumbers[0]}`

            if (winningTicket.ticket.purchase.ticketNumbers.length > 1) {
                ticketNumbers += ` - ${event.eventPrefix}_${winningTicket.ticket.purchase.ticketNumbers[winningTicket.ticket.purchase.ticketNumbers.length - 1]}`
            }

            return ticketNumbers
        }

        event.winningTicketList.forEach((winningTicket, index) => {

            let row: any[] = [
                index + 1,
                winningTicket.ticket.ticketNumber,
                winningTicket.ticket.purchase.name,
                winningTicket.ticket.purchase.email,
                winningTicket.ticket.purchase.phone,
                Number(winningTicket.ticket.purchase.ticketPricingBundle.multiple),
                Number(winningTicket.ticket.purchase.ticketPricingBundle.price),
                determineTicketNumbers(winningTicket),

                // // `${event.eventPrefix}_${winningTicket.purchase.ticketNumbers[0]} - ${event.eventPrefix}_${winningTicket.purchase.ticketNumbers[winningTicket.purchase.ticketNumbers.length -1]}`,

                // `${event.eventPrefix}_${winningTicket.purchase.ticketNumbers && winningTicket.purchase.ticketNumbers[0]}${winningTicket.purchase.ticketNumbers && winningTicket.purchase.ticketNumbers.length > 1 && <> - {event.eventPrefix}_{winningTicket.purchase.ticketNumbers && winningTicket.purchase.ticketNumbers[winningTicket.purchase.ticketNumbers && winningTicket.purchase.ticketNumbers.length - 1]}</> }`,

                winningTicket.ticket.purchase.transactionType === "ONLINE" ? "Stripe" : winningTicket.ticket.purchase.paymentTakenByUserId,
                winningTicket.ticket.purchase.transactionType === "ONLINE" ? "CARD" : winningTicket.ticket.purchase.paymentType,
                winningTicket.ticket.purchase.transactionType === "ONLINE" ? winningTicket.ticket.purchase.stripePaymentIntentId : winningTicket.ticket.purchase.paymentReference
            ]

            winningTicket.ticket.purchase.customPurchaseDetails.forEach(element => {
                row.push(element.value)
            });

            worksheet.addRow(row)
        });

        //Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, `${event.name}_winner_report.xlsx`);
        })
    }

    return {
        generatePurchaseReport,
        generateWinnerReport
    };
};
export default useExcel;