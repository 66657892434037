import React, { useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import UserContext, { initialUserState } from '../../contexts/user'
import IPageProps from '../../interfaces/page'
import { Authenticate } from '../../modules/auth'
import logging from '../../config/logging'
import { Button, Card, TextField } from '@mui/material'
import './login.scss'
import { ReactComponent as LighthouseLogo } from '../../assets/lighthouse-logo-colour.svg';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const LoginPage: React.FunctionComponent<IPageProps> = (props) => {
    const [authenticating, setAuthenticating] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const userContext = useContext(UserContext)
    const history = useHistory();

    const signInWithEmailPassword = () => {
        if (error !== '') setError('')
        const auth = getAuth();

        if (email && password){
            signInWithEmailAndPassword(auth, email, password)
                .then(async (result) => {
                    logging.info(result)
                    let user = result.user
    
                    if (user) {
                        let uid = user.uid
                        let email = user.email
    
                        if (email) {
                            try {
                                let fire_token = await user.getIdToken()
                                Authenticate(uid, email, fire_token, (error, _user) => {
                                    if (error) {
                                        setError(error);
                                        setAuthenticating(false);
                                    }
                                    else if (_user) {
                                        userContext.userDispatch({ type: 'login', payload: { user: _user, fire_token } })
    
                                        if (_user.isAdmin) {
                                            history.push('/admin/events');
                                        } else if (_user.allocatedEventId) {
                                            history.push(`/admin/events/${_user.allocatedEventId}/purchases`)
                                        } else {
                                            userContext.userDispatch({ type: 'logout', payload: initialUserState })
                                            setError('User not configured to manage events. Please contact system admin.')
                                            setAuthenticating(false)
                                        }
                                    }
                                });
                            } catch (error) {
                                setError('Invalid Token')
                                logging.error(error)
                                setAuthenticating(false)
                            }
                        } else {
                            setError('No email address returned from Firebase.')
                            setAuthenticating(false)
                        }
                    } else {
                        setError('Provider does not provide necessary info, please try another account or provider')
                        setAuthenticating(false)
                    }
                })
                .catch((error) => {
                    setError(error.message)
                    setAuthenticating(false)
                });
        }else{
            setError('Please enter a valid email address and password')
        }

    }

    return (
        <div className="login flex-container">
            <Card className="login-card">
                <Card className="image-card">
                    <LighthouseLogo />
                </Card>

                <h2>Welcome to the <br /> Lighthouse Lotto Admin Portal</h2>

                {userContext.userState.user._id === '' ?
                    <>
                        <p className="medium-text">Please log in by entering your details below. <br /> Authorised users only. Please ask the event administrator for access.</p>
                        <div className="inputs-wrapper">
                            <TextField
                                id="outlined-basic"
                                label="Email Address"
                                variant="outlined"
                                onChange={(e: any) => setEmail(e.target.value)}
                            />
                            <TextField
                                id="outlined-password-input"
                                label="Password"
                                type="password"
                                autoComplete="current-password"
                                onChange={(e: any) => setPassword(e.target.value)}
                            />

                        </div>

                        <div className="button-wrapper">
                            {/* {isUnauthorised && <span className="validation-text">You must be logged in to access the portal.</span>} */}
                            {error && <span className="validation-text">{error}</span>}
                            <Button
                                variant="contained"
                                disabled={authenticating}
                                className="admin-login lh-button"
                                // onClick={() => SignInWithSocialMedia(Providers.google)}
                                onClick={() => signInWithEmailPassword()}
                            >
                                Login
                            </Button>
                        </div>
                    </>
                    :
                    <>
                        <span className="medium-text">You are currently logged in as <b>{userContext.userState.user.email}</b></span>
                        <p className="small-text">Not you? <span className="logout-link" onClick={() => userContext.userDispatch({ type: 'logout', payload: initialUserState })}>Click here to log out</span></p>
                        <span className="small-text">or</span>
                        <div className="button-wrapper">
                            <Button variant="contained" className="admin-login lh-button" component={Link} to={userContext.userState.user.isAdmin ? '/admin/events' : `/admin/events/${userContext.userState.user.allocatedEventId}/purchases`}>View Dashboard</Button>
                        </div>
                    </>
                }
            </Card>
            <span className="small-text white">Please contact the site administrator if you require access to the portal.</span>
        </div>
    )
}

export default LoginPage
