import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router'
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog/ConfirmationDialog'
import Loading from '../../components/Loading/Loading'
import MediaSection from '../../components/PrizeSettingsSections/MediaSection'
import PrizeConfigSection from '../../components/PrizeSettingsSections/PrizeConfigSection'
// import SupplierDetailsSection from '../../components/PrizeSettingsSections/SupplierDetailsSection'
import useFetch from '../../hooks/useFetch'
import IPageProps from '../../interfaces/page'
import IPrize from '../../interfaces/prize'
import usePrizeValidation from './usePrizeValidation'

const PrizeSettingsPage: React.FunctionComponent<IPageProps & RouteComponentProps<any>> = (props) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>("")
    const [prize, setPrize] = useState<IPrize>({eventId: props.match.params.eventId} as IPrize)
    const [prizeList, setPrizeList] = useState<IPrize[]>([])
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
    const [dialogProperties, setDialogProperties] = useState<any>({ type: "", title: "", message: "" })

    const prizeApi = useFetch("prizes")
    const eventApi = useFetch("events")
    let history = useHistory()

    const prizeValidation = usePrizeValidation(prize)

    const getPrize = (): void => {
        setLoading(true)
        prizeApi.get(`/${props.match.params.prizeId}`).then((prize: IPrize) => {
            setPrize(prize)
            eventApi.get(`/${props.match.params.eventId}/prizes`).then((prizeList: IPrize[]) => {
                setPrizeList(prizeList)
                setLoading(false)
            }).catch((err: Error) => {
                setError(err.message)
                setLoading(false)
            })
        }).catch((err: Error) => {
            setError(err.message)
            setLoading(false)
        })
    }

    const getPrizeList = (): void => {
        setLoading(true)
        eventApi.get(`/${props.match.params.eventId}/prizes`).then((prizeList: IPrize[]) => {
            setPrizeList(prizeList)
            setLoading(false)
        }).catch((err: Error) => {
            setError(err.message)
            setLoading(false)
        })
    }

    const determineOrderNumber = (): Number => {
        let topOrderNumber = 0;

        prizeList.forEach(element => {
            console.log(element.orderNumber)
            if (element.orderNumber > topOrderNumber) {
                topOrderNumber = element.orderNumber
            }
        });

        return topOrderNumber + 1;
    }

    const createPrize = (): void => {
        if (prizeValidation.validateInputs()) {
        prizeApi.post(`/create`, { ...prize, eventId: props.match.params.eventId, orderNumber: determineOrderNumber() })
            .then((prize: IPrize) => {
                setDialogProperties({ type: "SUCCESS", title: "Prize Created.", message: "Your prize has successfully been created." })
                setIsConfirmationDialogOpen(true)
                history.push(`/admin/events/${props.match.params.eventId}/prizes/`)
            })
            .catch((err: Error) => {
                setDialogProperties({ type: "ERROR", title: "Create failed.", message: `Your prize failed with the following error: ${err}` })
                setIsConfirmationDialogOpen(true)
            })
        }
    }

    const savePrize = (): void => {
        if (prizeValidation.validateInputs()) {
        prizeApi.post(`/update`, { "prizeId": props.match.params.prizeId, "prize": prize })
            .then((prize: IPrize) => {
                setDialogProperties({ type: "SUCCESS", title: "Save complete.", message: "Your prize has successfully been saved." })
                setIsConfirmationDialogOpen(true)
                getPrize()
                history.push(`/admin/events/${props.match.params.eventId}/prizes/`)
            })
            .catch((err: Error) => {
                setDialogProperties({ type: "ERROR", title: "Save failed.", message: `Your prize did not save due to the following error: ${err}` })
                setIsConfirmationDialogOpen(true)
            })
        }
    }

    const closeDialog = () => {
        setIsConfirmationDialogOpen(false)
    }

    useEffect(() => {
        if (props.match.params.prizeId !== "new") {
            getPrize()
        }else{
            getPrizeList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    if (loading) {
        return (
            <Loading />
        )
    }

    if (error) {
        return (
            <p>{error}</p>
        )
    }


    return (
        <>
            <PrizeConfigSection prize={prize} setPrize={setPrize} validationObject={prizeValidation.validationObject}/>
            {/* <SupplierDetailsSection prize={prize} setPrize={setPrize} /> */}
            <MediaSection prize={prize} setPrize={setPrize} />

            <div className="button-wrapper" style={{flexDirection: "column", alignItems: "flex-end"}}>
                {!prizeValidation.isValidationPassed && <span className="validation-text" style={{marginBottom: 12}}>Errors highlighted in form - please resolve before saving.</span>}
                {props.match.params.prizeId === "new" ?
                    <Button className="lh-button" onClick={createPrize}>Create Prize</Button>
                    :
                    <Button className="lh-button" onClick={savePrize}>Save Prize</Button>
                }

            </div>

            <ConfirmationDialog
                handleClose={closeDialog}
                isDialogOpen={isConfirmationDialogOpen}
                message={dialogProperties.message}
                title={dialogProperties.title}
                type={dialogProperties.type}
                label={dialogProperties.label}
            />
        </>
    )
}

export default PrizeSettingsPage
