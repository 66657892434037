import React, { useEffect, useReducer, useState } from 'react'
import './application.scss'
import { Switch, Route, RouteChildrenProps, BrowserRouter } from 'react-router-dom'
import AuthRoute from './components/AuthRoute/AuthRoute'
import logging from './config/logging'
import routes from './config/routes'

import { initialUserState, UserContextProvider, userReducer } from './contexts/user'
import IRoute from './interfaces/route'
import LayoutAuthenticated from './layouts/LayoutAuthenticated'
import LayoutAuthenticatedNoMenu from './layouts/LayoutAuthenticatedNoMenu'
import { Validate } from './modules/auth'
import HttpsRedirect from 'react-https-redirect'

export interface IApplicationProps { }

const Application: React.FunctionComponent<IApplicationProps> = props => {
    const [userState, userDispatch] = useReducer(userReducer, initialUserState)
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        CheckLocalStorageForCredentials();

        // eslint-disable-next-line
    }, []);

    const CheckLocalStorageForCredentials = () => {
        const fire_token = localStorage.getItem('fire_token');
        
        if (fire_token === null) {
            userDispatch({ type: 'logout', payload: initialUserState });
            setLoading(false);
        } else {
            return Validate(fire_token, (error, user) => {
                if (error) {
                    logging.error(error);
                    userDispatch({ type: 'logout', payload: initialUserState });
                    setLoading(false);

                } else if (user) {
                    userDispatch({ type: 'login', payload: { user, fire_token } });
                    setLoading(false);
                }
            })
        }
    }

    const userContextValues = {
        userState,
        userDispatch
    };

    const determineLayout = (route: IRoute, routeProps: RouteChildrenProps<any>) => {
        if (route.layout === "anonymous") {
            return (<route.component {...routeProps} />)
        } else if (route.layout === "authenticated") {
            return (
                <LayoutAuthenticated title={route.title}>
                    <route.component {...routeProps} />
                </LayoutAuthenticated>
            )
        } else if (route.layout === "authenticatedNoMenu") {
            return (
                <LayoutAuthenticatedNoMenu title={route.title}>
                    <route.component {...routeProps} />
                </LayoutAuthenticatedNoMenu>
            )
        } else {
            return (<route.component {...routeProps} />)
        }
    }

    if (loading) {
        return (
            <div>
                <p>validating user...</p>
            </div>
        )
    }

    return (

        <HttpsRedirect>
            <UserContextProvider value={userContextValues}>
                <BrowserRouter>
                    <Switch>
                        {routes.map((route, index) => {

                            if (route.auth) {
                                return (
                                    <Route
                                        key={index}
                                        exact={route.exact}
                                        path={route.path}
                                        render={(routeProps: RouteChildrenProps<any>) =>
                                            <AuthRoute>
                                                {determineLayout(route, routeProps)}
                                            </AuthRoute>
                                        }
                                    />
                                )
                            } else {
                                return (
                                    <Route
                                        key={index}
                                        exact={route.exact}
                                        path={route.path}
                                        render={(routeProps: RouteChildrenProps<any>) =>
                                            <route.component {...routeProps} />
                                        }
                                    />
                                )
                            }
                        })}
                    </Switch>
                </BrowserRouter>
            </UserContextProvider>
        </HttpsRedirect>
    )
}

export default Application