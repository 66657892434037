import React, { useEffect, useState } from 'react'
import Loading from '../../components/Loading/Loading'
import useFetch from '../../hooks/useFetch'
import IPageProps from '../../interfaces/page';
import { Card, TableCell } from '@mui/material';
import { CardContent, Table, TableBody, TableHead, TableRow } from '@mui/material';
import { RouteComponentProps } from 'react-router';
import { ILogEntry } from '../../interfaces/logEntry';
import moment from 'moment';
import TablePagination from '@mui/material/TablePagination';

const EventLogsPage: React.FunctionComponent<IPageProps & RouteComponentProps<any>> = (props) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>("")
    const [logList, setLogList] = useState<ILogEntry[]>([])
    const [paginationPage, setPaginationPage] = useState<number>(0)
    const [paginationRowsPerPage, setPaginationRowsPerPage] = useState<number>(10)
    const [totalLogCount, setTotalLogCount] = useState<number>(0)

    const logsApi = useFetch("logs");

    const getLogs = (): void => {
        setLoading(true)
        logsApi.get(`/${props.match.params.eventId}/${paginationRowsPerPage}/${paginationPage}`).then((result: any) => {
            setTotalLogCount(result.totalRecords)
            setLogList(result.logs)
            setLoading(false)
        }).catch((err: Error) => {
            setError(err.message)
            setLoading(false)
        })
    }

    const handlePaginationChangePage = (event: unknown, newPage: number) => {
        setPaginationPage(newPage);
    };

    const handlePaginationChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPaginationRowsPerPage(parseInt(event.target.value, 10));
        setPaginationPage(0);
    };

    useEffect(() => {
        getLogs()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        getLogs()
        // eslint-disable-next-line
    }, [paginationPage])

    if (loading) {
        return (
            <Loading />
        )
    }

    if (error) {
        return (
            <p>{error}</p>
        )
    }

    return (
        <div className="event-logs-page">
            {logList.length > 0 ?
                <>
                    <Card>
                        <CardContent>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <div className="table-header-cell">
                                                <span>Timestamp</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-header-cell">
                                                <span>Type</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-header-cell">
                                                <span>Action</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-header-cell">
                                                <span>Reference</span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {logList.map((log, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{moment(log.timestamp).format("HH:mm:ss DD/MM/YYYY")}</TableCell>
                                            <TableCell>{log.type}</TableCell>
                                            <TableCell>{log.action}</TableCell>
                                            <TableCell>{log.reference}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50]}
                                component="div"
                                count={totalLogCount}
                                rowsPerPage={paginationRowsPerPage}
                                page={paginationPage}
                                onPageChange={handlePaginationChangePage}
                                onRowsPerPageChange={handlePaginationChangeRowsPerPage}
                            />
                        </CardContent>
                    </Card >
                </>
                :
                <Card><CardContent><div className="no-entries">No logs</div></CardContent></Card>
            }
        </div >
    )
}

export default EventLogsPage
