import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog/ConfirmationDialog'
import Loading from '../../components/Loading/Loading'
import useFetch from '../../hooks/useFetch'
import IEvent from '../../interfaces/event'
import { useHistory } from 'react-router-dom'
import EventConfigSection from '../../components/EventSettingsSections/EventConfigSection'
import EventThemeSection from '../../components/EventSettingsSections/EventThemeSection'
import CustomPurchaseDetailSection from '../../components/EventSettingsSections/CustomPurchaseDetailSection'
import TicketPricingBundleSection from '../../components/EventSettingsSections/TicketPricingBundleSection'
import IPageProps from '../../interfaces/page'
import useEventValidation from './useEventValidation'
import UserSection from '../../components/EventSettingsSections/UserSection'
import IUser from '../../interfaces/user'
import EventOrganiserSection from '../../components/EventSettingsSections/EventOrganiserSection'

const EventSettingsPage: React.FunctionComponent<IPageProps & RouteComponentProps<any>> = (props) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')
    const [event, setEvent] = useState<IEvent>({} as IEvent)
    const [otherEventList, setOtherEventList] = useState<IEvent[]>([])
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false)
    const [dialogProperties, setDialogProperties] = useState<any>({ type: '', title: '', message: '' })
    const [allUsers, setAllUsers] = useState<IUser[]>([])

    const eventValidation = useEventValidation(otherEventList)

    const eventApi = useFetch('events')
    const userApi = useFetch('users')

    let history = useHistory()

    const getEvent = (eventId?: string): void => {
        let eventToGet = eventId ? eventId : props.match.params.eventId
        setLoading(true)

        eventApi
            .get(`/getEventById/${eventToGet}/false`)
            .then((event: IEvent) => {
                setEvent(event)
                getAllUsers()
            })
            .catch((err: Error) => {
                setError(err.message)
                setLoading(false)
            })
    }

    const getEvents = (): void => {
        setLoading(true)
        eventApi
            .get(`/getAllEvents/5000/0`)
            .then((result: any) => {
                let filteredList = result.events
                if (props.match.params.eventId !== 'new') {
                    filteredList = result.events.filter((element) => element.eventId !== props.match.params.eventId)
                }
                setOtherEventList(filteredList)
                getAllUsers()
            })
            .catch((err: Error) => {
                setError(err.message)
                setLoading(false)
            })
    }

    const getAllUsers = (): void => {
        userApi
            .get(`/`)
            .then((users: IUser[]) => {
                setAllUsers(users)
                setLoading(false)
            })
            .catch((err: Error) => {
                setError(err.message)
                setLoading(false)
            })
    }

    const createEvent = (): void => {
        if (eventValidation.validateInputs(event)) {
            eventApi
                .post(`/create`, event)
                .then((event: IEvent) => {
                    console.log(event)
                    setDialogProperties({ type: 'SUCCESS', title: 'Event Created.', message: 'Your event has successfully been created.' })
                    setIsConfirmationDialogOpen(true)
                    console.log('here')
                    console.log(`/admin/events/${event.eventId}`)
                    history.push(`/admin/events/${event.eventId}`)
                })
                .catch((err: Error) => {
                    setDialogProperties({ type: 'ERROR', title: 'Create failed.', message: `Your event failed with the following: ${err}` })
                    setIsConfirmationDialogOpen(true)
                })
        }
    }

    const saveEvent = (): void => {
        if (eventValidation.validateInputs(event)) {
            eventApi
                .post(`/update`, { eventId: props.match.params.eventId, event: event })
                .then((_event: IEvent) => {
                    userApi
                        .post(`/updateMany`, { users: allUsers })
                        .then((result: any) => {
                            setDialogProperties({ type: 'SUCCESS', title: 'Save complete.', message: 'Your event has successfully been saved.' })
                            setIsConfirmationDialogOpen(true)
                            history.push(`/admin/events/${_event.eventId}`)
                            getEvent(_event.eventId)
                        })
                        .catch((err: Error) => {
                            setDialogProperties({ type: 'ERROR', title: 'Save failed.', message: `Your event did not save due to the following: ${err}` })
                            setIsConfirmationDialogOpen(true)
                        })
                })
                .catch((err: Error) => {
                    setDialogProperties({ type: 'ERROR', title: 'Save failed.', message: `Your event did not save due to the following: ${err}` })
                    setIsConfirmationDialogOpen(true)
                })
        }
    }

    const closeDialog = () => {
        setIsConfirmationDialogOpen(false)
    }

    useEffect(() => {
        if (props.match.params.eventId !== 'new') {
            getEvent()
        }
        getEvents()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (loading) {
        return <Loading />
    }

    if (error) {
        return <p>{error}</p>
    }

    return (
        <>
            <EventConfigSection event={event} setEvent={setEvent} validationObject={eventValidation.validationObject} />
            <EventThemeSection event={event} setEvent={setEvent} />
            <TicketPricingBundleSection event={event} setEvent={setEvent} />
            <CustomPurchaseDetailSection event={event} setEvent={setEvent} />
            <UserSection allUsers={allUsers} setAllUsers={setAllUsers} eventId={event.eventId} />
            <EventOrganiserSection event={event} setEvent={setEvent} eventValidation={eventValidation} />

            <div className="button-wrapper" style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
                {!eventValidation.isValidationPassed && (
                    <span className="validation-text" style={{ marginBottom: 12 }}>
                        Errors highlighted in form - please resolve before saving.
                    </span>
                )}
                {props.match.params.eventId === 'new' ? (
                    <Button className="lh-button" onClick={createEvent}>
                        Create Event
                    </Button>
                ) : (
                    <Button className="lh-button" onClick={saveEvent}>
                        Save Event
                    </Button>
                )}
            </div>

            <ConfirmationDialog
                handleClose={closeDialog}
                isDialogOpen={isConfirmationDialogOpen}
                message={dialogProperties.message}
                title={dialogProperties.title}
                type={dialogProperties.type}
                label={dialogProperties.label}
            />
        </>
    )
}

export default EventSettingsPage
