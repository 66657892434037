import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useMemo, useState } from 'react'
import './winnerPdfDownloadDialog.scss'
import { PDFDownloadLink } from '@react-pdf/renderer'
import usePdfGenerator from '../../../hooks/usePdfGenerator'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import IEvent from '../../../interfaces/event'
import IWinningTicket from '../../../interfaces/winningTicket'
import IPrize from '../../../interfaces/prize'

interface InputProps {
    isDialogOpen: boolean,
    handleClose: (boolean: boolean) => void,
    event: IEvent
    winningTicket: IWinningTicket
    prize: IPrize
    prizeList: IPrize[]
    type: "SUMMARY" | "SINGLE"
}

const WinnerPdfDownloadDialog = (props: InputProps) => {

    const [errorText, setErrorText] = useState<string>("")
    const [successText, setSuccessText] = useState<string>("")

    const pdfGenerator = usePdfGenerator(props.event)


    const DownloadSinglePDFButton = () => {
        return useMemo(
            () => (
                <PDFDownloadLink document={pdfGenerator.generateWinnerPdf(props.winningTicket, props.prize)} fileName={`${props.event.name} - - Winner.pdf`}>
                    {({ loading }) => (loading ? 'loading...' : 'here')}
                </PDFDownloadLink>
                // eslint-disable-next-line react-hooks/exhaustive-deps
            ), [])
    }
    const DownloadSummaryPDFButton = () => {
        return useMemo(
            () => (
                <PDFDownloadLink document={pdfGenerator.generateWinnerSummaryPdf(props.prizeList)} fileName={`${props.event.name} - - Winner Summary.pdf`}>
                    {({ loading }) => (loading ? 'loading...' : 'here')}
                </PDFDownloadLink>
                // eslint-disable-next-line react-hooks/exhaustive-deps
            ), [])
    }

    const handleClose = () => {
        setErrorText("")
        setSuccessText("")
        props.handleClose(true)
    }

    return (
        <Dialog
            open={props.isDialogOpen}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            className="pdf-dialog"
        >
            <DialogTitle id="form-dialog-title">
                {props.type === "SUMMARY" ? 'Winner Summary PDF Download' : 'Winner PDF Download'}
            </DialogTitle>
            <DialogContent>

                <span>Please click {props.type === "SUMMARY" ? <DownloadSummaryPDFButton /> : <DownloadSinglePDFButton />} to download the <b>{props.type === "SUMMARY" ? 'Winner Summary PDF' : 'Winner PDF'}</b>.</span>

                {errorText &&
                    <div className='outcome-wrapper'>
                        <CancelIcon className='cross-icon' />
                        <span>{errorText}</span>
                    </div>
                }
                {successText &&
                    <div className='outcome-wrapper'>
                        <CheckCircleIcon className='tick-icon' />
                        <span>{successText}</span>
                    </div>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className="gg-button">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default WinnerPdfDownloadDialog