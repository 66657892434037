import { useState } from "react";
import IEventOrganiser from "../../../interfaces/eventOrganiser";
import { IValidation } from "../../../interfaces/validation";

const useEventOrganiserValidation = (eventOrganiser: IEventOrganiser) => {
    const [validationObject, setValidationObject] = useState<IValidation[]>([
        { name: "firstName", isValid: true, validationMessage: "" },
        { name: "surname", isValid: true, validationMessage: "" },
        { name: "email", isValid: true, validationMessage: "" },
        { name: "phone", isValid: true, validationMessage: "" },
    ])
    const [isValidationPassed, setIsValidationPassed] = useState<boolean>(true)

    const generateInitialValidationObject = (): void => {

        let tempValidationObject: IValidation[] = [
            { name: "firstName", isValid: true, validationMessage: "" },
            { name: "surname", isValid: true, validationMessage: "" },
            { name: "email", isValid: true, validationMessage: "" },
            { name: "phone", isValid: true, validationMessage: "" },
        ]

        setValidationObject(tempValidationObject)
        setIsValidationPassed(true)
    }

    const validateInputs = (): boolean => {

        let tempValidationObject: IValidation[] = [
            { name: "firstName", isValid: true, validationMessage: "" },
            { name: "surname", isValid: true, validationMessage: "" },
            { name: "email", isValid: true, validationMessage: "" },
            { name: "phone", isValid: true, validationMessage: "" },
        ]

        let tempIsValidationPassed = true

        const setFailedValidation = (name: string, message: string) => {
            tempIsValidationPassed = false
            tempValidationObject.forEach(element => {
                if (element.name === name) {
                    element.isValid = false
                    element.validationMessage = message
                }
            });
        }

        if (eventOrganiser.firstName === "" || typeof (eventOrganiser.firstName) === "undefined") {
            setFailedValidation("firstName", "Please enter the first name of the event organiser.")
        }
        if (eventOrganiser.surname === "" || typeof (eventOrganiser.surname) === "undefined") {
            setFailedValidation("surname", "Please enter the surname of the event organiser.")
        }
        if (eventOrganiser.email === "" || typeof (eventOrganiser.email) === "undefined") {
            setFailedValidation("email", "Please enter the email address of the event organiser.")
        }else {
            if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(eventOrganiser.email)) {
                
            } else {
                setFailedValidation("email", "Invalid email address.")
            }
        }
        if (eventOrganiser.phone === "" || typeof (eventOrganiser.phone) === "undefined") {
            setFailedValidation("phone", "Please enter the mobile phone number of the event organiser.")
        }else {

            let phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
            let number;

            try {
                if (eventOrganiser.phone.substring(0, 3) === "+44") {
                    number = phoneUtil.parseAndKeepRawInput(eventOrganiser.phone);
                } else if (eventOrganiser.phone.substring(0, 4) === "0044") {
                    var updatedNumber = `+44 ${eventOrganiser.phone.substring(4, eventOrganiser.phone.length)}`
                    number = phoneUtil.parseAndKeepRawInput(updatedNumber);
                } else {
                    number = phoneUtil.parseAndKeepRawInput(`+44 ${eventOrganiser.phone}`);
                }

                if (!phoneUtil.isValidNumber(number)) {
                    setFailedValidation("phone", "Invalid UK phone number")
                }

            }
            catch (err) {
                setFailedValidation("phone", "Invalid UK phone number")
            }
        }



        setValidationObject(tempValidationObject)
        setIsValidationPassed(tempIsValidationPassed)
        return tempIsValidationPassed
    }

    const getValidation = (name: string): IValidation => {
        let returnValue: IValidation = {} as IValidation
        validationObject.forEach(element => {
            if (name === element.name) {
                returnValue = element
            }
        });
        return returnValue
    }

    return {
        validationObject,
        setValidationObject,
        isValidationPassed,
        setIsValidationPassed,
        validateInputs,
        getValidation,
        generateInitialValidationObject
    }
}

export default useEventOrganiserValidation