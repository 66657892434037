import React, { useEffect, useState } from "react"
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, Select, FormHelperText, MenuItem } from "@mui/material"
// import useCustomPurchaseDetailValidation from "./useCustomPurchaseDetailValidation";
import '../dialog.scss'
import IEvent from "../../../interfaces/event";
import ICustomPurchaseDetail from "../../../interfaces/customPurchaseDetail";
import { v4 as uuidv4 } from 'uuid';
import useCustomPurchaseDetailValidation from "./useCustomPurchaseDetailValidation";

interface InputProps {
    isDialogOpen: boolean,
    handleClose: () => void,
    setEvent: (event: IEvent) => void,
    customPurchaseDetail: ICustomPurchaseDetail,
    event: IEvent
}



const CustomPurchaseDetailDialog = (props: InputProps) => {

    const blankCustomPurchaseDetail: ICustomPurchaseDetail = {
        _id: uuidv4(),
        label: "",
        value: "",
        placeholder: "",
        isRequired: false,
        type: "",
        optionArray: []
    }

    const [customPurchaseDetail, setCustomPurchaseDetail] = useState<ICustomPurchaseDetail>({ ...props.customPurchaseDetail });
    const [optionListCsv, setOptionListCsv] = useState<string>('');


    const validationHook = useCustomPurchaseDetailValidation(customPurchaseDetail, optionListCsv)

    const handleChange = (event: any) => {
        const { name, value } = event.target
        if (name === "type" && value === "TABLE") {
            setCustomPurchaseDetail({ ...customPurchaseDetail, [name]: value, isRequired: true })
        } else {
            setCustomPurchaseDetail({ ...customPurchaseDetail, [name]: value })
        }
    }

    const generateCsv = () => {
        let csv = ""

        props.customPurchaseDetail.optionArray && props.customPurchaseDetail.optionArray.forEach((option, index) => {
            if (index === props.customPurchaseDetail.optionArray.length - 1) {
                csv += `${option}`
            } else {
                csv += `${option},`
            }

        });

        setOptionListCsv(csv)
    }


    const saveCustomPurchaseDetail = () => {
        if (validationHook.validateInputs()) {
            let tempCustomPurchaseDetailObject: ICustomPurchaseDetail[] = []

            if (props.event.customPurchaseDetails && props.event.customPurchaseDetails.length > 0) {
                tempCustomPurchaseDetailObject = [...props.event.customPurchaseDetails]
            }

            if (props.customPurchaseDetail._id) {
                tempCustomPurchaseDetailObject.forEach((element, index) => {
                    if (customPurchaseDetail._id === element._id) {
                        tempCustomPurchaseDetailObject[index] = {...customPurchaseDetail, optionArray: optionListCsv.split(",")}
                    }
                })

                props.setEvent({ ...props.event, customPurchaseDetails: tempCustomPurchaseDetailObject })
                setCustomPurchaseDetail(blankCustomPurchaseDetail)
                props.handleClose()

            } else {
                tempCustomPurchaseDetailObject.push({...customPurchaseDetail, optionArray: optionListCsv.split(",")})
                props.setEvent({ ...props.event, customPurchaseDetails: tempCustomPurchaseDetailObject })
                setCustomPurchaseDetail(blankCustomPurchaseDetail)
                props.handleClose()
            }
        }

    }

    useEffect(() => {
        if (props.customPurchaseDetail._id) {
            setCustomPurchaseDetail({ ...props.customPurchaseDetail })
        } else {
            setCustomPurchaseDetail(blankCustomPurchaseDetail)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [props.customPurchaseDetail]);

    useEffect(() => {
        generateCsv()
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [props.customPurchaseDetail])

    return (
        <>
            <Dialog
                open={props.isDialogOpen}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Custom Purchase Field</DialogTitle>
                <DialogContent>
                    <FormControl
                        variant="outlined"
                        fullWidth
                        required
                        error={!validationHook.getValidation("type").isValid}
                    >
                        <InputLabel id="detail-type-label">Detail Type</InputLabel>
                        <Select
                            labelId="detail-type-label"
                            id="type"
                            name="type"
                            value={customPurchaseDetail.type}
                            onChange={handleChange}
                            label="Detail Type *"
                        >
                            <MenuItem value={"TEXT"}>Text Input</MenuItem>
                            <MenuItem value={"NUMBER"}>Number Input</MenuItem>
                            <MenuItem value={"SELECT"}>Dropdown List</MenuItem>
                            <MenuItem value={"TABLE"}>Table Number</MenuItem>
                        </Select>
                        {!validationHook.getValidation("type").isValid && <FormHelperText>{validationHook.getValidation("type").validationMessage} </FormHelperText>}
                    </FormControl>
                    <TextField
                        variant="outlined"
                        id="label"
                        name="label"
                        label="Label"
                        type="text"
                        fullWidth
                        value={customPurchaseDetail.label}
                        onChange={handleChange}
                        required
                        helperText={!validationHook.getValidation("label").isValid && validationHook.getValidation("label").validationMessage}
                        error={!validationHook.getValidation("label").isValid}
                    />
                    <FormControl
                        variant="outlined"
                        fullWidth
                        required
                        error={!validationHook.getValidation("isRequired").isValid}
                    >
                        <InputLabel id="is-mandatory-label">Mandatory?</InputLabel>
                        <Select
                            labelId="is-mandatory-label"
                            id="isRequired"
                            name="isRequired"
                            value={customPurchaseDetail.isRequired}
                            onChange={handleChange}
                            label="Mandatory? *"
                            disabled={customPurchaseDetail.type === "TABLE"}
                        >
                            <MenuItem value={'true'}>True</MenuItem>
                            <MenuItem value={'false'}>False</MenuItem>

                        </Select>
                        {!validationHook.getValidation("isRequired").isValid && <FormHelperText>{validationHook.getValidation("isRequired").validationMessage} </FormHelperText>}
                    </FormControl>

                    <TextField
                        variant="outlined"
                        id="placeholder"
                        name="placeholder"
                        label="Helper Text"
                        type="text"
                        fullWidth
                        value={customPurchaseDetail.placeholder}
                        onChange={handleChange}
                    />

                    {customPurchaseDetail.type === "SELECT" &&
                        <TextField
                            variant="outlined"
                            id="optionListCsv"
                            name="optionListCsv"
                            label="Comma Separated Options"
                            type="text"
                            fullWidth
                            required
                            value={optionListCsv}
                            onChange={(event) => setOptionListCsv(event.target.value)}
                            helperText={!validationHook.getValidation("optionListCsv").isValid && validationHook.getValidation("optionListCsv").validationMessage}
                            error={!validationHook.getValidation("optionListCsv").isValid}
                        />
                    }

                </DialogContent>
                <DialogActions>
                    <div className="save-button-wrapper">
                        {!validationHook.isValidationPassed && <span className="validation-text">Errors highlighted in form - please resolve before saving.</span>}
                        <div className="buttons">
                            <Button id="cancel-button" onClick={props.handleClose}>
                                Cancel
                            </Button>
                            <Button id="save-button" onClick={saveCustomPurchaseDetail} className="lh-button">
                                Save
                            </Button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CustomPurchaseDetailDialog