import React from 'react'
import { Paper, TextField } from '@mui/material'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import IPrize from '../../interfaces/prize'
import { IValidation } from '../../interfaces/validation'

interface InputProps {
    prize: IPrize
    setPrize: (prize: IPrize) => void
    validationObject: IValidation[]
}

const PrizeConfigSection: React.FunctionComponent<InputProps> = (props) => {

    const handleBlur = (prize: any) => {
        const { name, value } = prize.target
        props.setPrize({ ...props.prize, [name]: value })
    }

    const getValidation = (name): IValidation => {
        let returnValue: IValidation = {} as IValidation
        props.validationObject.forEach(element => {
            if (name === element.name) {
                returnValue = element
            }
        });
        return returnValue
    }

    return (
        <section className="prize-config">
            <h2 className="subtitle">Prize Congifuration.</h2>
            {/* <span className="description">This is the company that is hosting the auction. Eg Company x may be hosting an auction on behalf of company y.</span> */}
            <Paper className="input-wrapper">
                <div className="field-wrapper">
                    <TextField
                        id="title"
                        name="title"
                        label="Prize Title"
                        variant="outlined"
                        defaultValue={props.prize.title || ""}
                        onBlur={handleBlur}
                        helperText={getValidation("title").isValid ? "The prize title" : getValidation("title").validationMessage}
                        error={!getValidation("title").isValid}
                    />
                </div>
                <div className="field-wrapper">
                    <TextField
                        id="subtitle"
                        name="subtitle"
                        label="Prize Subtitle"
                        variant="outlined"
                        defaultValue={props.prize.subtitle || ""}
                        onBlur={handleBlur}
                    />
                </div>
                <div className="quill">
                    <span>Description</span>
                    <ReactQuill
                        defaultValue={props.prize.descriptionHtml || ""}
                        onChange={(value: any) => props.setPrize({ ...props.prize, descriptionHtml: value })}
                    />
                </div>
            </Paper>
        </section>
    )
}

export default PrizeConfigSection
