import React, { useState } from 'react'
import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import IEvent from '../../interfaces/event'
import ITicketPricingBundle from '../../interfaces/ticketPricingBundle'
import TicketPricingBundlesDialog from '../Dialogs/TicketPricingBundlesDialog/TicketPricingBundlesDialog'

interface InputProps {
    event: IEvent
    setEvent: (event: IEvent) => void,
}

const TicketPricingBundleSection = (props: InputProps) => {

    const [isTicketPricingBundleDialogOpen, setIsTicketPricingBundleDialogOpen] = useState<boolean>(false);
    const [tempTicketPricingBundle, setTempTicketPricingBundle] = useState<ITicketPricingBundle>({} as ITicketPricingBundle);

    const closeDialog = () => {
        setIsTicketPricingBundleDialogOpen(false)
    }
    const openDialog = (TicketPricingBundle: ITicketPricingBundle) => {
        setTempTicketPricingBundle(TicketPricingBundle)
        setIsTicketPricingBundleDialogOpen(true)
    }

    const deleteTicketPricingBundle = (index: number) => {
        let tempTicketPricingBundleObject: ITicketPricingBundle[] = [...props.event.ticketPricingBundles]
        tempTicketPricingBundleObject.splice(index, 1)
        props.setEvent({ ...props.event, ticketPricingBundles: tempTicketPricingBundleObject })
    }

    const promote = (_id: string) => {
        let index = props.event.ticketPricingBundles.findIndex(e => e._id === _id);
        if (index > 0) {
            let tempTicketPricingBundles = [...props.event.ticketPricingBundles]
            let el = tempTicketPricingBundles[index];
            tempTicketPricingBundles[index] = tempTicketPricingBundles[index - 1];
            tempTicketPricingBundles[index - 1] = el;
            props.setEvent({ ...props.event, ticketPricingBundles: tempTicketPricingBundles })
        }
    }

    const demote = (_id: string) => {
        let index = props.event.ticketPricingBundles.findIndex(e => e._id === _id);
        if (index !== -1 && index < props.event.ticketPricingBundles.length - 1) {
            let tempTicketPricingBundles = [...props.event.ticketPricingBundles]
            let el = tempTicketPricingBundles[index];
            tempTicketPricingBundles[index] = tempTicketPricingBundles[index + 1];
            tempTicketPricingBundles[index + 1] = el;
            props.setEvent({ ...props.event, ticketPricingBundles: tempTicketPricingBundles })
        }
    }

    return (
        <div className="custom-Purchase-detail-section">
            <h2 className="subtitle">Ticket Pricing Bundles.</h2>
            <span className="description">The options customers will have for purchasing tickets.</span>
            <Paper className="input-wrapper">
                {props.event.ticketPricingBundles && props.event.ticketPricingBundles.length > 0 ?
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                <div className="table-header-cell">
                                        <span>Ticket Multiple</span>
                                    </div>
                                </TableCell>
                                <TableCell className="hide-on-mobile">
                                <div className="table-header-cell">
                                        <span>Price</span>
                                    </div>
                                </TableCell>
                                <TableCell className="hide-on-mobile">
                                <div className="table-header-cell">
                                        <span>Label</span>
                                    </div>
                                </TableCell>
                                <TableCell className="hide-on-mobile">
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.event.ticketPricingBundles.map((ticketPricingBundle: ITicketPricingBundle, index) => (
                                <TableRow key={index}>
                                    <TableCell onClick={() => openDialog(ticketPricingBundle)}>{ticketPricingBundle.multiple}</TableCell>
                                    <TableCell className="hide-on-mobile">{ticketPricingBundle.price}</TableCell>
                                    <TableCell className="hide-on-mobile">{ticketPricingBundle.label}</TableCell>
                                    <TableCell className="hide-on-mobile">
                                        <div className="action-cell">
                                            <div onClick={() => promote(ticketPricingBundle._id)}>
                                                <Tooltip title="Promote"><ExpandLessIcon /></Tooltip>
                                            </div>
                                            <div onClick={() => demote(ticketPricingBundle._id)}>
                                                <Tooltip title="Demote"><ExpandMoreIcon /></Tooltip>
                                            </div>
                                            <div onClick={() => openDialog(ticketPricingBundle)}><Tooltip title="Edit Bundle"><CreateIcon /></Tooltip></div>
                                            <div onClick={() => deleteTicketPricingBundle(index)}><Tooltip title="Delete Bundle"><DeleteIcon /></Tooltip></div>
                                        </div>

                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    :
                    <div className="no-entries">No custom ticket bundles defined.</div>
                }
                <div className="button-wrapper">
                    <Button className="lh-button" onClick={() => openDialog({} as ITicketPricingBundle)}>Add New Bundle</Button>
                </div>
            </Paper>
            <TicketPricingBundlesDialog
                handleClose={closeDialog}
                isDialogOpen={isTicketPricingBundleDialogOpen}
                setEvent={props.setEvent}
                event={props.event}
                ticketPricingBundle={tempTicketPricingBundle}
            />
        </div>
    )
}

export default TicketPricingBundleSection