import React from 'react'
import { FormControl, FormHelperText, InputLabel, MenuItem, Paper, Select, TextField, InputAdornment } from '@mui/material'
import IEvent from '../../interfaces/event'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import DateAdapter from '@mui/lab/AdapterMoment';
import DateTimePicker from '@mui/lab/DateTimePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { IValidation } from '../../interfaces/validation'
import CloudinaryUploadWidget from '../CloudinaryUploadWidget/CloudinaryUploadWidget'

interface InputProps {
    event: IEvent
    setEvent: (event: IEvent) => void
    validationObject: IValidation[]
}

const EventConfigSection: React.FunctionComponent<InputProps> = (props) => {



    const handleBlur = (event: any) => {
        const { name, value } = event.target
        props.setEvent({ ...props.event, [name]: value })
    }

    const handleChange = (name, value) => {
        props.setEvent({ ...props.event, [name]: value })
    }

    const handleDateChange = (name: string, date: any) => {
        props.setEvent({ ...props.event, [name]: date })
    }

    const getValidation = (name): IValidation => {
        let returnValue: IValidation = {} as IValidation
        props.validationObject.forEach(element => {
            if (name === element.name) {
                returnValue = element
            }
        });
        return returnValue
    }

    return (
        <section className="event-config">
            <h2 className="subtitle">Event Configuration.</h2>
            <Paper className="input-wrapper">
                <div className="field-wrapper">
                    <TextField
                        id="name"
                        name="name"
                        label="Event Name"
                        variant="outlined"
                        defaultValue={props.event.name || ""}
                        onBlur={handleBlur}
                        required
                        helperText={getValidation("name").isValid ? "The name of the event." : getValidation("name").validationMessage}
                        error={!getValidation("name").isValid}
                    />
                </div>
                <div className="field-wrapper">
                    <TextField
                        id="eventPrefix"
                        name="eventPrefix"
                        label="Event Prefix"
                        variant="outlined"
                        defaultValue={props.event.eventPrefix || ""}
                        onBlur={handleBlur}
                        required
                        helperText={getValidation("eventPrefix").isValid ? "The characters that will prefix the ticket numbers eg ABC001, ABC002 etc" : getValidation("eventPrefix").validationMessage}
                        error={!getValidation("eventPrefix").isValid}
                    />
                </div>
                <div className="field-wrapper">
                    <TextField
                        id="title"
                        name="title"
                        label="Event Title"
                        variant="outlined"
                        defaultValue={props.event.title || ""}
                        onBlur={handleBlur}
                        helperText="The title on the homepage."
                    />
                </div>
                <div className="field-wrapper">
                    <TextField
                        id="subtitle"
                        name="subtitle"
                        label="Event Subtitle"
                        variant="outlined"
                        defaultValue={props.event.subtitle || ""}
                        onBlur={handleBlur}
                        helperText="The subtitle on the homepage."
                    />
                </div>
                <div className="field-wrapper">
                    <TextField
                        id="bannerUrl"
                        name="bannerUrl"
                        label="Banner URL"
                        variant="outlined"
                        value={props.event.bannerUrl || ""}
                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                        helperText={"The url for the banner image shown on the home page. Either use the icon to upload a new file or provide the url of an existing file hosted online. "}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="start">
                                    <CloudinaryUploadWidget
                                        folderName={props.event.eventId}
                                        allowMultipleUploads={false}
                                        functionOnComplete={(uploadedUrlList) => handleChange("bannerUrl", uploadedUrlList[0])}
                                        uploadPreset="unsigned"
                                    />
                                </InputAdornment>
                        }}
                    />
                </div>
                <div className="field-wrapper">
                    <TextField
                        id="logoUrl"
                        name="logoUrl"
                        label="Logo URL"
                        variant="outlined"
                        value={props.event.logoUrl || ""}
                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                        helperText={"The url for the logo image shown in the header on the home page. Either use the icon to upload a new file or provide the url of an existing file hosted online. "}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="start">
                                    <CloudinaryUploadWidget
                                        folderName={props.event.eventId}
                                        allowMultipleUploads={false}
                                        functionOnComplete={(uploadedUrlList) => handleChange("logoUrl", uploadedUrlList[0])}
                                        uploadPreset="unsigned"
                                    />
                                </InputAdornment>
                        }}
                    />
                </div>
                <div className="field-wrapper">
                    <TextField
                        id="bannerText"
                        name="bannerText"
                        label="Banner Text"
                        variant="outlined"
                        defaultValue={props.event.bannerText || ""}
                        onBlur={handleBlur}
                        helperText="Text to be super imposed over the banner."
                    />
                </div>
                {/* <div className="field-wrapper">
                    <TextField
                        id="buttonText"
                        name="buttonText"
                        label="Button Text"
                        variant="outlined"
                        defaultValue={props.event.buttonText || ""}
                        onBlur={handleBlur}
                        helperText="The text on the button on the homepage - taking customers to the ticket page."
                    />
                </div> */}
                <div className="field-wrapper">
                    <TextField
                        id="eventId"
                        name="eventId"
                        label="URL Suffix"
                        variant="outlined"
                        defaultValue={props.event.eventId || ""}
                        onBlur={handleBlur}
                        required
                        helperText={getValidation("eventId").isValid ? "The suffix in the url that direct cutstomers to this event." : getValidation("eventId").validationMessage}
                        error={!getValidation("eventId").isValid}
                    />
                </div>
                <div className="field-wrapper">
                    <TextField
                        id="purchaseConfirmationMessage"
                        name="purchaseConfirmationMessage"
                        label="Purchase Confirmation Message"
                        variant="outlined"
                        defaultValue={props.event.purchaseConfirmationMessage || ""}
                        onBlur={handleBlur}
                        helperText="The custom text to be send via SMS or email to a customer once their payment has been taken."
                    />
                </div>
                <div className="field-wrapper">
                    <LocalizationProvider dateAdapter={DateAdapter}>
                        <DateTimePicker
                            label="Event End Date/Time"
                            // inputVariant="outlined"
                            hideTabs
                            showTodayButton={true}
                            inputFormat="HH:mm DD/MM/yyyy"
                            ampm={false}

                            value={props.event.endDateTime}
                            onChange={(date: any) => handleDateChange("endDateTime", date)}
                            renderInput={(params) => <TextField {...params} />}

                        />
                    </LocalizationProvider>
                </div>
                <div className="field-wrapper">
                    <FormControl variant="outlined">
                        <InputLabel id="notification-type-label">Notification Type</InputLabel>
                        <Select
                            labelId="notification-type-label"
                            id="notificationType"
                            name="notificationType"
                            defaultValue={props.event.notificationType || ""}
                            onBlur={handleBlur}
                            label="Notification Type"
                        >
                            <MenuItem value={"NONE"}>None</MenuItem>
                            <MenuItem value={"SMS"}>SMS</MenuItem>
                            <MenuItem value={"EMAIL"}>Email</MenuItem>
                            <MenuItem value={"BOTH"}>Both</MenuItem>

                        </Select>
                        <FormHelperText>How customers will receive their confirmation message once payment has been taken for their tickets.</FormHelperText>
                    </FormControl>
                </div>
                <div className="field-wrapper">
                    <FormControl variant="outlined">
                        <InputLabel id="winner-generation-label">Winner Generation mode</InputLabel>
                        <Select
                            labelId="winner-generation-label"
                            id="winnerGenerationMode"
                            name="winnerGenerationMode"
                            defaultValue={props.event.winnerGenerationMode || ""}
                            onBlur={handleBlur}
                            label="Winner Generation Mode"
                        >
                            <MenuItem value={"AUTOMATIC"}>Automatic</MenuItem>
                            <MenuItem value={"MANUAL"}>Manual</MenuItem>
                        </Select>
                        <FormHelperText>If set to 'Automatic' winners will automatically be generated at the event end date and the defined event organisers (below) will be notified.</FormHelperText>
                    </FormControl>
                </div>
                <div className="field-wrapper">
                    <TextField
                        id="videoUrl"
                        name="videoUrl"
                        label="Video URL"
                        variant="outlined"
                        defaultValue={props.event.videoUrl || ""}
                        onBlur={handleBlur}
                        helperText="The ID of a video on youtube to be embedded on the homepage. Eg: '9t3wDRNZggQ'"
                    />
                </div>
                <div className="field-wrapper">
                    <FormControl variant="outlined">
                        <InputLabel id="payment-method-label">Payment Method</InputLabel>
                        <Select
                            labelId="payment-method-label"
                            id="paymentMethod"
                            name="paymentMethod"
                            defaultValue={props.event.paymentMethod || ""}
                            onBlur={handleBlur}
                            label="Notification Type"
                        >
                            <MenuItem value={"OFFLINE"}>Offline (Staff take payment)</MenuItem>
                            <MenuItem value={"ONLINE"}>Online (Stripe)</MenuItem>
                            <MenuItem value={"BOTH"}>Both</MenuItem>
                        </Select>
                        <FormHelperText>How customers can pay for their purchased tickets.</FormHelperText>
                    </FormControl>
                </div>

            </Paper>
            <Paper className="input-wrapper">
                <div className="field-wrapper quill">
                    <span>Introduction Text</span>
                    <ReactQuill
                        defaultValue={props.event.introHtml || ""}
                        onChange={(value: any) => props.setEvent({ ...props.event, introHtml: value })}
                    />
                    <span>The first block of text on the homepage.</span>
                </div>
                <div className="field-wrapper quill">
                    <span>Body Text</span>
                    <ReactQuill
                        defaultValue={props.event.descriptionHtml || ""}
                        onChange={(value) => props.setEvent({ ...props.event, descriptionHtml: value })}
                    />
                    <span>The second block of text on the homepage.</span>
                </div>
            </Paper>
        </section>
    )

}

export default EventConfigSection
