import { useEffect, useState } from "react"
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material"
import '../dialog.scss'
import IEvent from "../../../interfaces/event";
import ITicketPricingBundle from "../../../interfaces/ticketPricingBundle";
import { v4 as uuidv4 } from 'uuid';
import useTicketPricingBundleValidation from "./useTicketPricingBundlesValidation";

interface InputProps {
    isDialogOpen: boolean,
    handleClose: () => void,
    setEvent: (event: IEvent) => void,
    ticketPricingBundle: ITicketPricingBundle,
    event: IEvent
}


const TicketPricingBundlesDialog = (props: InputProps) => {

    const blankTicketPricingBundle: ITicketPricingBundle = {
        _id: uuidv4(),
        multiple: 0,
        price: 0,
        label: ""
    }

    const [ticketPricingBundle, setTicketPricingBundle] = useState<ITicketPricingBundle>({ ...props.ticketPricingBundle });

    const validationHook = useTicketPricingBundleValidation(ticketPricingBundle)

    const handleChange = (event: any) => {
        const { name, value } = event.target
        setTicketPricingBundle({ ...ticketPricingBundle, [name]: value })
    }

    const saveTicketPricingBundle = () => {
        if (validationHook.validateInputs()) {
            let tempTicketPricingBundleObject: ITicketPricingBundle[] = []

            if (props.event.ticketPricingBundles && props.event.ticketPricingBundles.length > 0) {
                tempTicketPricingBundleObject = [...props.event.ticketPricingBundles]
            }

            if (props.ticketPricingBundle._id) {
                tempTicketPricingBundleObject.forEach((element, index) => {
                    if (ticketPricingBundle._id === element._id) {
                        tempTicketPricingBundleObject[index] = ticketPricingBundle
                    }
                })

                props.setEvent({ ...props.event, ticketPricingBundles: tempTicketPricingBundleObject })
                setTicketPricingBundle(blankTicketPricingBundle)
                props.handleClose()

            } else {
                tempTicketPricingBundleObject.push(ticketPricingBundle)
                props.setEvent({ ...props.event, ticketPricingBundles: tempTicketPricingBundleObject })
                setTicketPricingBundle(blankTicketPricingBundle)
                props.handleClose()
            }
        }

    }

    useEffect(() => {
        if (props.ticketPricingBundle._id) {
            setTicketPricingBundle({ ...props.ticketPricingBundle })
        } else {
            setTicketPricingBundle(blankTicketPricingBundle)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [props.ticketPricingBundle]);

    return (
        <>
            <Dialog
                open={props.isDialogOpen}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Custom Purchase Field</DialogTitle>
                <DialogContent>
                    <TextField
                        variant="outlined"
                        id="multiple"
                        name="multiple"
                        label="Multiple"
                        type="number"
                        fullWidth
                        value={ticketPricingBundle.multiple}
                        onChange={handleChange}
                        required
                        helperText={!validationHook.getValidation("multiple").isValid && validationHook.getValidation("multiple").validationMessage}
                        error={!validationHook.getValidation("multiple").isValid}
                    />
                    <TextField
                        variant="outlined"
                        id="price"
                        name="price"
                        label="Price"
                        type="number"
                        fullWidth
                        value={ticketPricingBundle.price}
                        onChange={handleChange}
                        required
                        helperText={!validationHook.getValidation("price").isValid && validationHook.getValidation("price").validationMessage}
                        error={!validationHook.getValidation("price").isValid}
                    />
                    <TextField
                        variant="outlined"
                        id="label"
                        name="label"
                        label="Label"
                        type="text"
                        fullWidth
                        value={ticketPricingBundle.label}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <div className="save-button-wrapper">
                        {!validationHook.isValidationPassed && <span className="validation-text">Errors highlighted in form - please resolve before saving.</span>}
                        <div className="buttons">
                            <Button id="cancel-button" onClick={props.handleClose}>
                                Cancel
                            </Button>
                            <Button id="save-button" onClick={saveTicketPricingBundle} className="lh-button">
                                Save
                            </Button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default TicketPricingBundlesDialog