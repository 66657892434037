import "./ticketPricingBundleCard.scss"
import { Button, Card, CardContent } from '@mui/material'
import ITicketPricingBundle from '../../interfaces/ticketPricingBundle'

interface InputProps {
    ticketPricingBundle: ITicketPricingBundle,
    setSelectedTicketPricingBundle: (ticketPricingBundle: ITicketPricingBundle) => void
    setIsPurchaseDialogOpen: (boolean: boolean) => void
    isClosed: boolean
}

const TicketPricingBundleCard = (props: InputProps) => {

    const selectTicketBundle = () => {
        props.setSelectedTicketPricingBundle(props.ticketPricingBundle)
        props.setIsPurchaseDialogOpen(true)

    }

    return (
        <>
            <Card
                className={props.ticketPricingBundle.label ? "ticket-pricing-bundle-card" : "ticket-pricing-bundle-card no-label"}
                onClick={props.isClosed ? ()=>{} : selectTicketBundle}
            >
                {props.ticketPricingBundle.label &&
                    <div className="label-card-wrapper">
                        <Card className="label-card">{props.ticketPricingBundle.label}</Card>
                    </div>
                }
                <CardContent>
                    <span className="price">£{props.ticketPricingBundle.price} for</span>
                    <span className="multiple">{props.ticketPricingBundle.multiple}</span>
                    <span className="tickets">Tickets</span>
                    
                    {props.isClosed ?
                        <Button className="lh-button disabled" disabled>Event Closed</Button>
                        :
                        <Button className="lh-button confirm">SUBMIT YOUR ORDER</Button>
                    }
                </CardContent>
            </Card>
        </>
    )
}

export default TicketPricingBundleCard