import { Dialog, DialogContent, DialogTitle, FormControl, FormHelperText, InputLabel, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import useFetch from '../../../hooks/useFetch'
import IEvent from '../../../interfaces/event'
import IPurchase from '../../../interfaces/purchase'
import "./purchaseDialog.scss"
import usePurchaseValidation from './usePurchaseValidation'
import { v4 as uuidv4 } from 'uuid';
import ITicketPricingBundle from '../../../interfaces/ticketPricingBundle';
import ActionSection from './ActionSection';
import ICustomPurchaseDetail from '../../../interfaces/customPurchaseDetail';
import { useCookies } from 'react-cookie'

interface InputProps {
    isDialogOpen: boolean
    handleClose: (isSuccess: boolean, isError: boolean, error?: string) => void
    event: IEvent
    setEvent: (event: IEvent) => void
    ticketPricingBundle: ITicketPricingBundle
}

const PurchaseDialog = (props: InputProps) => {

    const [isTermsAndConditionsAgreed, setIsTermsAndConditionsAgreed] = useState<boolean>(false)
    const [isPurchaseInProgress, setIsPurchaseInProgress] = useState<boolean>(false)
    const [purchase, setPurchase] = useState<IPurchase>({} as IPurchase)
    const [customPurchaseDetails, setCustomPurchaseDetails] = useState<ICustomPurchaseDetail[]>([])
    const [bothPaymentMethod, setBothPaymentMethod] = useState<string>("")

    let cookieNames = ['ll_name', 'll_email', 'll_phone']

    props.event.customPurchaseDetails && props.event.customPurchaseDetails.forEach(element => {
        cookieNames.push(`ll_${element._id}`)
    });
    const [cookies, setCookie] = useCookies(cookieNames)
    const eventApi = useFetch("events");
    const stripeApi = useFetch("stripe");

    const purchaseValidation = usePurchaseValidation(purchase, isTermsAndConditionsAgreed, props.event, customPurchaseDetails, bothPaymentMethod)

    const createCookies = (name?: string, email?: string, phone?: string, customPurchaseDetails?: ICustomPurchaseDetail[]) => {
        if (name) {
            setCookie('ll_name', name, { path: '/' });
        }
        if (email) {
            setCookie('ll_email', email, { path: '/' });
        }
        if (phone) {
            setCookie('ll_phone', phone, { path: '/' });
        }
        if (bothPaymentMethod) {
            setCookie('ll_bothPaymentMethod', bothPaymentMethod, { path: '/' });
        }
        if (customPurchaseDetails) {
            customPurchaseDetails.forEach(element => {
                setCookie(`ll_${element._id}`, element.value, { path: '/' });
            });
        }
    }

    const getCookies = () => {
        let tempPurchase = { ...purchase } as IPurchase

        if (cookies.ll_name) {
            tempPurchase.name = cookies.ll_name
        }
        if (cookies.ll_email) {
            tempPurchase.email = cookies.ll_email
        }
        if (cookies.ll_phone) {
            tempPurchase.phone = cookies.ll_phone
        }
        if (cookies.ll_bothPaymentMethod) {
            setBothPaymentMethod(cookies.ll_bothPaymentMethod)
        }
        if (customPurchaseDetails) {
            customPurchaseDetails.forEach(element => {
                if (cookies[`ll_${element._id}`]) {
                    element.value = cookies[`ll_${element._id}`]
                }
            });
        }
        setPurchase(tempPurchase)
    }

    const clearPurchase = () => {
        setPurchase({} as IPurchase)

        let tempCustomPurchaseDetails = [...props.event.customPurchaseDetails]

        tempCustomPurchaseDetails.forEach(element => {
            element.value = ""
        });

        props.setEvent({ ...props.event, customPurchaseDetails: tempCustomPurchaseDetails })
    }

    const confirmPurchase = (transactionType: 'ONLINE' | 'OFFLINE') => {
        setIsPurchaseInProgress(true)

        createCookies(purchase.name, purchase.email, purchase.phone, customPurchaseDetails)

        let purchaseId = uuidv4()

        let purchaseObject: IPurchase = {
            ...purchase,
            _id: purchaseId,
            timestamp: new Date(),
            customPurchaseDetails: customPurchaseDetails,
            ticketPricingBundle: props.ticketPricingBundle,
            status: 'PENDING',
            transactionType: transactionType,
            paymentType: '',
            isActive: true
        }

        if (purchaseValidation.validateInputs()) {
            transactionType === "ONLINE" ?
                eventApi.post(`/addStripePendingPurchase`, { eventId: props.event.eventId, _id: props.event._id, purchase: purchaseObject })
                    .then((event: IEvent) => {
                        createStripeCheckoutSession(purchaseId)
                        setIsPurchaseInProgress(false)
                        clearPurchase()
                        props.handleClose(false, false)
                    })
                    .catch((err: Error) => {
                        setIsPurchaseInProgress(false)
                        clearPurchase()
                        props.handleClose(false, true, err.message)
                    })
                :
                eventApi.post(`/addPurchase`, { eventId: props.event.eventId, _id: props.event._id, purchase: purchaseObject })
                    .then((event: IEvent) => {
                        setIsPurchaseInProgress(false)
                        clearPurchase()
                        props.handleClose(true, false)
                    })
                    .catch((err: Error) => {
                        setIsPurchaseInProgress(false)
                        clearPurchase()
                        props.handleClose(false, true, err.message)
                    })
        } else {
            setIsPurchaseInProgress(false)
        }
    }

    const createStripeCheckoutSession = async (pendingPurchaseId: string) => {

        if (purchaseValidation.validateInputs()) {
            stripeApi.post(`/create-checkout-session`, {
                productName: `${props.ticketPricingBundle.multiple} Lighthouse Lotto Tickets`,
                price: props.ticketPricingBundle.price,
                eventId: props.event.eventId,
                event_id: props.event._id,
                customerEmail: purchase.email,
                pendingPurchaseId: pendingPurchaseId,
                eventName: props.event.name
            })
                .then((result: any) => {
                    console.log(result)
                    window.location.href = result.url
                })
                .catch((err: Error) => {
                    setIsPurchaseInProgress(false)
                    clearPurchase()
                    props.handleClose(false, true, err.message)
                })
        } else {
            setIsPurchaseInProgress(false)
        }
    }

    const handleChange = (event: any) => {
        const { name, value } = event.target
        setPurchase({ ...purchase, [name]: value })

    }

    const setCustomPurchaseDetail = (detailId: string, value: any) => {
        let tempCPDs = [...customPurchaseDetails]

        tempCPDs.forEach(element => {
            if (element._id === detailId) {
                element.value = value
            }
        });

        setCustomPurchaseDetails(tempCPDs)
    }

    useEffect(() => {
        if (props.isDialogOpen === true) {
            getCookies()

            //need to generate so that the custom purchase fields can be set to valid initially.
            purchaseValidation.generateInitialValidationObject()
            setIsTermsAndConditionsAgreed(false)
        }

        //set the CPDs once when dialog is opened
        setCustomPurchaseDetails(props.event.customPurchaseDetails)

        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [props.isDialogOpen]);

    return (
        <Dialog
            open={props.isDialogOpen}
            onClose={() => props.handleClose(false, false)}
            aria-labelledby="form-dialog-title"
            className="purchase-dialog"
        >
            <DialogTitle id="form-dialog-title" >
                <span>Confirm Purchase: <b>{new Intl.NumberFormat().format(props.ticketPricingBundle.multiple)}</b> ticket(s) for <b>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'GBP', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(props.ticketPricingBundle.price)}</b></span>
            </DialogTitle>

            <DialogContent>
                <div className="intro-wrapper">
                    <span>In order to confirm your purchase please complete the fields below.</span>
                </div>

                <div className="input-fields-wrapper">
                    <TextField
                        id="name"
                        name="name"
                        label="Your Full Name"
                        variant="outlined"
                        value={purchase.name}
                        onChange={handleChange}
                        required
                        error={!purchaseValidation.getValidation("name").isValid}
                        helperText={!purchaseValidation.getValidation("name").isValid && purchaseValidation.getValidation("name").validationMessage}
                    />
                    <TextField
                        id="email"
                        name="email"
                        label="Email Address"
                        variant="outlined"
                        value={purchase.email}
                        onChange={handleChange}
                        required
                        error={!purchaseValidation.getValidation("email").isValid}
                        helperText={!purchaseValidation.getValidation("email").isValid && purchaseValidation.getValidation("email").validationMessage}
                    />
                    <TextField
                        id="phone"
                        name="phone"
                        label="Phone Number"
                        variant="outlined"
                        value={purchase.phone}
                        onChange={handleChange}
                        required
                        error={!purchaseValidation.getValidation("phone").isValid}
                        helperText={!purchaseValidation.getValidation("phone").isValid && purchaseValidation.getValidation("phone").validationMessage}
                    />
                    
                    {customPurchaseDetails && customPurchaseDetails.map((detail, index) => {
                        return (
                            <div key={index}>
                                {detail.type === "TEXT" &&
                                    <TextField
                                        id={detail._id}
                                        name={detail._id}
                                        label={detail.label}
                                        variant="outlined"
                                        value={detail.value}
                                        onChange={(event) => setCustomPurchaseDetail(detail._id, event.target.value)}
                                        required={detail.isRequired}
                                        error={!purchaseValidation.getValidation(detail._id).isValid}
                                        helperText={purchaseValidation.getValidation(detail._id).isValid ? detail.placeholder && detail.placeholder : purchaseValidation.getValidation(detail._id).validationMessage}
                                    />
                                }
                                {detail.type === "NUMBER" &&
                                    <TextField
                                        id={detail._id}
                                        name={detail._id}
                                        label={detail.label}
                                        type="number"
                                        InputProps={{ inputProps: { min: 0 } }}
                                        variant="outlined"
                                        value={detail.value}
                                        onChange={(event) => setCustomPurchaseDetail(detail._id, event.target.value)}
                                        required={detail.isRequired}
                                        error={!purchaseValidation.getValidation(detail._id).isValid}
                                        helperText={purchaseValidation.getValidation(detail._id).isValid ? detail.placeholder && detail.placeholder : purchaseValidation.getValidation(detail._id).validationMessage}
                                    />
                                }
                                {detail.type === "TABLE" &&
                                    <TextField
                                        id={detail._id}
                                        name={detail._id}
                                        label={detail.label}
                                        type="number"
                                        inputProps={{ min: 1, max: 9999 }}
                                        variant="outlined"
                                        value={detail.value}
                                        onChange={(event) => setCustomPurchaseDetail(detail._id, event.target.value)}
                                        required={detail.isRequired}
                                        error={!purchaseValidation.getValidation(detail._id).isValid}
                                        helperText={purchaseValidation.getValidation(detail._id).isValid ? detail.placeholder && detail.placeholder : purchaseValidation.getValidation(detail._id).validationMessage}
                                    />
                                }

                                {detail.type === "SELECT" &&
                                    <FormControl variant="outlined" required>
                                        <InputLabel id="notification-type-label">{detail.label}</InputLabel>
                                        <Select
                                            labelId="notification-type-label"
                                            id="notificationType"
                                            name="notificationType"
                                            value={detail.value}
                                            onChange={(event) => setCustomPurchaseDetail(detail._id, event.target.value)}
                                            label={detail.label}
                                            required={detail.isRequired}
                                            error={!purchaseValidation.getValidation(detail._id).isValid}
                                        >
                                            {detail.optionArray.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item}>{item}</MenuItem >
                                                )
                                            })}
                                        </Select>

                                        {purchaseValidation.getValidation(detail._id).isValid ?
                                            detail.placeholder && <FormHelperText>{detail.placeholder}</FormHelperText>
                                            :
                                            <FormHelperText className="Mui-error">{purchaseValidation.getValidation(detail._id).validationMessage}</FormHelperText>
                                        }
                                    </FormControl>
                                }
                            </div>
                        )
                    })}

                    {props.event.paymentMethod === "BOTH" &&
                        <div className='payment-method-selector-wrapper'>
                            <div className='horizontal-divider'></div>
                            <FormControl variant="outlined" required>
                                <InputLabel id="payment-method-selector-label">How would you like to pay?</InputLabel>
                                <Select
                                    labelId="payment-method-selector-label"
                                    id="bothPaymentMethod"
                                    name="bothPaymentMethod"
                                    value={bothPaymentMethod}
                                    onChange={(event) => setBothPaymentMethod(event.target.value)}
                                    label="How would you like to pay?"
                                    required
                                error={!purchaseValidation.getValidation("bothPaymentMethod").isValid}
                                >
                                    <MenuItem value="ONLINE">Online</MenuItem >
                                    <MenuItem value="OFFLINE">With a staff member</MenuItem >

                                </Select>

                                {purchaseValidation.getValidation("bothPaymentMethod").isValid ?
                                    bothPaymentMethod === "ONLINE" ?
                                        <FormHelperText >Click the 'Proceed to Purchase Page' button below to be redirected to a secure payment page powered by Stripe to complete your payment online.</FormHelperText>
                                        :
                                        <FormHelperText >Click the 'Confirm' button below to inform a member of event staff to come and take your payment. Payment can be made either in cash or by card.</FormHelperText>
                                    :
                                    <FormHelperText className="Mui-error">{purchaseValidation.getValidation("bothPaymentMethod").validationMessage}</FormHelperText>
                                }
                            </FormControl>
                        </div>
                    }
                </div>

                <ActionSection
                    paymentMethod={props.event.paymentMethod}
                    bothPaymentMethod={bothPaymentMethod}
                    purchaseValidation={purchaseValidation}
                    isTermsAndConditionsAgreed={isTermsAndConditionsAgreed}
                    setIsTermsAndConditionsAgreed={setIsTermsAndConditionsAgreed}
                    handleClose={props.handleClose}
                    confirmPurchase={confirmPurchase}
                    isPurchaseInProgress={isPurchaseInProgress}
                />
            </DialogContent>
        </Dialog>
    )
}

export default PurchaseDialog