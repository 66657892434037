import { useState } from "react";
import IPurchase from "../../../interfaces/purchase";
import { IValidation } from "../../../interfaces/validation";

const usePurchaseValidation = (purchase: IPurchase) => {
    const [validationObject, setValidationObject] = useState<IValidation[]>([
        { name: "paymentType", isValid: true, validationMessage: "" }
    ])
    const [isValidationPassed, setIsValidationPassed] = useState<boolean>(true)
    
    const validateInputs = (): boolean => {
        let tempValidationObject: IValidation[] = [
            { name: "paymentType", isValid: true, validationMessage: "" }
        ]

        let tempIsValidationPassed = true

        const setFailedValidation = (name: string, message: string) => {
            tempIsValidationPassed = false
            tempValidationObject.forEach(element => {
                if (element.name === name) {
                    element.isValid = false
                    element.validationMessage = message
                }
            });
        }

        if (purchase.paymentType === null || typeof (purchase.paymentType) === "undefined" || purchase.paymentType === '') {
            setFailedValidation("paymentType", "Please enter a payment type.")
        }

        setValidationObject(tempValidationObject)
        setIsValidationPassed(tempIsValidationPassed)
        return tempIsValidationPassed
    }

    const getValidation = (name: string): IValidation => {
        let returnValue: IValidation = {} as IValidation
        validationObject.forEach(element => {
            if (name === element.name) {
                returnValue = element
            }
        });
        return returnValue
    }

    return {
        validationObject,
        setValidationObject,
        isValidationPassed,
        setIsValidationPassed,
        validateInputs,
        getValidation
    }
}

export default usePurchaseValidation