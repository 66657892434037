import React, { useState } from 'react'
import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import CustomPurchaseFieldDialog from '../Dialogs/CustomPurchaseDetailDialog/CustomPurchaseDetailDialog'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import IEvent from '../../interfaces/event'
import ICustomPurchaseDetail from '../../interfaces/customPurchaseDetail'

interface InputProps {
    event: IEvent
    setEvent: (event: IEvent) => void,
}

const CustomPurchaseDetailSection = (props: InputProps) => {

    const [isCustomPurchaseDetailDialogOpen, setIsCustomPurchaseDetailDialogOpen] = useState<boolean>(false);
    const [tempCustomPurchaseDetail, setTempCustomPurchaseDetail] = useState<ICustomPurchaseDetail>({} as ICustomPurchaseDetail);

    const closeDialog = () => {
        setIsCustomPurchaseDetailDialogOpen(false)
    }
    const openDialog = (customPurchaseDetail: ICustomPurchaseDetail) => {
        setTempCustomPurchaseDetail(customPurchaseDetail)
        setIsCustomPurchaseDetailDialogOpen(true)
    }

    const deleteCustomPurchaseDetail = (index: number) => {
        let tempCustomPurchaseDetailObject: ICustomPurchaseDetail[] = [...props.event.customPurchaseDetails]
        tempCustomPurchaseDetailObject.splice(index, 1)
        props.setEvent({ ...props.event, customPurchaseDetails: tempCustomPurchaseDetailObject })
    }

    const promote = (_id: string) => {
        let index = props.event.customPurchaseDetails.findIndex(e => e._id === _id);
        if (index > 0) {
            let tempCustomPurchaseDetails = [...props.event.customPurchaseDetails]
            let el = tempCustomPurchaseDetails[index];
            tempCustomPurchaseDetails[index] = tempCustomPurchaseDetails[index - 1];
            tempCustomPurchaseDetails[index - 1] = el;
            props.setEvent({ ...props.event, customPurchaseDetails: tempCustomPurchaseDetails })
        }
    }

    const demote = (_id: string) => {
        let index = props.event.customPurchaseDetails.findIndex(e => e._id === _id);
        if (index !== -1 && index < props.event.customPurchaseDetails.length - 1) {
            let tempCustomPurchaseDetails = [...props.event.customPurchaseDetails]
            let el = tempCustomPurchaseDetails[index];
            tempCustomPurchaseDetails[index] = tempCustomPurchaseDetails[index + 1];
            tempCustomPurchaseDetails[index + 1] = el;
            props.setEvent({ ...props.event, customPurchaseDetails: tempCustomPurchaseDetails })
        }
    }

    return (
        <div className="custom-Purchase-detail-section">
            <h2 className="subtitle">Custom Purchase Fields.</h2>
            <span className="description">When making a purchase each customer will always be asked for: Name, Email and Phone Number. Below, please specify what further information customers must enter to make a purchase.</span>
            <Paper className="input-wrapper">
                {props.event.customPurchaseDetails && props.event.customPurchaseDetails.length > 0 ?
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                <div className="table-header-cell">
                                        <span>Field Name</span>
                                    </div>
                                </TableCell>
                                <TableCell className="hide-on-mobile">
                                <div className="table-header-cell">
                                        <span>Field Type</span>
                                    </div>
                                </TableCell>
                                <TableCell className="hide-on-mobile">
                                <div className="table-header-cell">
                                        <span>Mandatory?</span>
                                    </div>
                                </TableCell>
                                <TableCell className="hide-on-mobile">
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.event.customPurchaseDetails.map((detail: ICustomPurchaseDetail, index) => (
                                <TableRow key={detail._id}>
                                    <TableCell onClick={() => openDialog(detail)}>{detail.label}</TableCell>
                                    <TableCell className="hide-on-mobile">
                                        {detail.type === "SELECT" && <span>Dropdown List</span>}
                                        {detail.type === "TEXT" && <span>Text Input</span>}
                                        {detail.type === "NUMBER" && <span>Number Input</span>}
                                        {detail.type === "TABLE" && <span>Table Number</span>}
                                    </TableCell>
                                    <TableCell className="hide-on-mobile">{String(detail.isRequired)}</TableCell>
                                    <TableCell className="hide-on-mobile">
                                        <div className="action-cell">
                                            <div onClick={() => promote(detail._id)}>
                                                <Tooltip title="Promote"><ExpandLessIcon /></Tooltip>
                                            </div>
                                            <div onClick={() => demote(detail._id)}>
                                                <Tooltip title="Demote"><ExpandMoreIcon /></Tooltip>
                                            </div>
                                            <div onClick={() => openDialog(detail)}><Tooltip title="Edit Detail"><CreateIcon /></Tooltip></div>
                                            <div onClick={() => deleteCustomPurchaseDetail(index)}><Tooltip title="Delete Detail"><DeleteIcon /></Tooltip></div>
                                        </div>

                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    :
                    <div className="no-entries">No custom purchase fields defined.</div>
                }
                <div className="button-wrapper">
                    <Button className="lh-button" onClick={() => openDialog({} as ICustomPurchaseDetail)}>Add New Field</Button>
                </div>
            </Paper>
            <CustomPurchaseFieldDialog
                handleClose={closeDialog}
                isDialogOpen={isCustomPurchaseDetailDialogOpen}
                setEvent={props.setEvent}
                event={props.event}
                customPurchaseDetail={tempCustomPurchaseDetail}
            />
        </div>
    )
}

export default CustomPurchaseDetailSection