import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import Loading from '../../components/Loading/Loading'
import WinnerCard from '../../components/WinnerCard/WinnerCard'
import useFetch from '../../hooks/useFetch'
import IEvent from '../../interfaces/event'
import IPageProps from '../../interfaces/page'
import './winnerGenerator.scss'
import { useHistory } from "react-router-dom";
import IWinningTicket from '../../interfaces/winningTicket'

var _ = require('lodash')

interface IWinnerObject {
    ticket: IWinningTicket
    isShowWinnerCard: boolean
    isShowWinnerName: boolean
}

const WinnerGeneratorPage: React.FunctionComponent<IPageProps & RouteComponentProps<any>> = (props) => {

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>("")
    const [event, setEvent] = useState<IEvent>({} as IEvent)
    const [winnerCardList, setWinnerCardList] = useState<IWinnerObject[]>([])
    const [isAllWinnersShown, setIsAllWinnersShown] = useState<boolean>(false)
    const [isAllNamesShown, setIsAllNamesShown] = useState<boolean>(false)


    const eventApi = useFetch("events")
    const history = useHistory()

    const getEvent = (): void => {
        setLoading(true)
        eventApi.get(`/getEventById/${props.match.params.eventId}/false`).then((event: IEvent) => {
            setEvent(event)
            let tempWinnerCardList: IWinnerObject[] = event.winningTicketList.map(ticket => {
                return ({ ticket: ticket, isShowWinnerCard: false, isShowWinnerName: false })
            })
            setWinnerCardList(tempWinnerCardList)
            setLoading(false)
        }).catch((err: Error) => {
            setError(err.message)
            setLoading(false)
        })
    }

    const resetWinnerCards = () => {
        let tempWinnerCardList: IWinnerObject[] = _.cloneDeep(winnerCardList)
        tempWinnerCardList.forEach(element => {
            element.isShowWinnerCard = false
            element.isShowWinnerName = false
        });

        setWinnerCardList(tempWinnerCardList)
        setIsAllNamesShown(false)
        setIsAllWinnersShown(false)
    }

    const showNextWinner = () => {
        let tempWinnerCardList = _.cloneDeep(winnerCardList)

        for (let index = 0; index < tempWinnerCardList.length; index++) {
            if (tempWinnerCardList[index].isShowWinnerCard === false) {
                tempWinnerCardList[index].isShowWinnerCard = true
                break
            }
        }

        if (countRemainingHiddenWinners(tempWinnerCardList) === 0) {
            console.log("setting winners true")
            setIsAllWinnersShown(true)
        }

        setWinnerCardList(tempWinnerCardList)
    }

    const showNextName = () => {
        let tempWinnerCardList = _.cloneDeep(winnerCardList)

        for (let index = tempWinnerCardList.length - 1; index > -1; index--) {
            if (tempWinnerCardList[index].isShowWinnerName === false) {
                tempWinnerCardList[index].isShowWinnerName = true
                break
            }
        }

        if (countRemainingHiddenNames(tempWinnerCardList) === 0) {
            setIsAllNamesShown(true)
        }

        setWinnerCardList(tempWinnerCardList)
    }

    const countRemainingHiddenWinners = (tempWinnerCardList: IWinnerObject[]): number => {
        let remainingHiddenWinners = 0
        tempWinnerCardList.forEach(element => {
            if (element.isShowWinnerCard === false) {
                remainingHiddenWinners++
            }
        });

        return remainingHiddenWinners
    }

    const countRemainingHiddenNames = (tempWinnerCardList: IWinnerObject[]): number => {
        let remainingHiddenNames = 0
        tempWinnerCardList.forEach(element => {
            if (element.isShowWinnerName === false) {
                remainingHiddenNames++
            }
        });

        return remainingHiddenNames
    }

    useEffect(() => {
        getEvent()

        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    if (loading) {
        return (
            <Loading />
        )
    }

    if (error) {
        return (
            <p>{error}</p>
        )
    }

    return (
        <div className="winner-generator-page">
            <section className="header-section" style={{ backgroundColor: event.headerBackgroundColor }}>
                <div className="action-link-wrapper">
                    <span onClick={() => history.push(`/admin/events/${event.eventId}/winners`)}>exit</span>
                    <span onClick={resetWinnerCards}>reset</span>
                </div>

                <img alt="event-logo" src={event.logoUrl} />
                <h1 style={{ color: event.headerFontColor }}>
                    {event.title}
                </h1>
                <h2 style={{ color: event.headerFontColor }}>
                    {event.subtitle}
                </h2>
            </section>
            <section className="winner-generator-section">
                <div>

                    <div className="buttons-wrapper">
                        {!isAllWinnersShown &&
                            <Button className="lh-button" onClick={showNextWinner}>Generate Winner ({countRemainingHiddenWinners(winnerCardList)})</Button>
                        }
                        {(isAllWinnersShown && !isAllNamesShown) &&
                            <Button className="lh-button" onClick={showNextName}>Show Winner Name ({countRemainingHiddenNames(winnerCardList)})</Button>
                        }
                    </div>
                    <div className="winner-card-wrapper">

                        {winnerCardList && winnerCardList.map((winnerCard, index) => {
                            return (
                                <div key={index} style={{ display: winnerCard.isShowWinnerCard ? 'block' : 'none' }}>
                                    <WinnerCard
                                        winnerCard={winnerCard}
                                        prizeNumber={index + 1}
                                        event={event}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default WinnerGeneratorPage
