import React, { useEffect, useState } from 'react'
import { Button, Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, TablePagination } from '@mui/material'
import './eventList.scss'
import { Link } from 'react-router-dom'
import useFetch from '../../hooks/useFetch'
import IEvent from '../../interfaces/event'
import IPageProps from '../../interfaces/page'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CreateIcon from '@mui/icons-material/Create'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import moment from 'moment'
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog/ConfirmationDialog'
import Loading from '../../components/Loading/Loading'
import IPrize from '../../interfaces/prize'
import _ from "lodash"

const EventListPage: React.FunctionComponent<IPageProps> = (props) => {

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>("")
    const [eventList, setEventList] = useState<IEvent[]>([])
    const eventApi = useFetch("events");
    const prizeApi = useFetch("prizes");
    const [columnToSort, setColumnToSort] = useState("endDateTime");
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc')
    const [dialogProperties, setDialogProperties] = useState<any>({ type: "", title: "", label: "", message: "" })
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false)
    const [eventToBeDeleted, setEventToBeDeleted] = useState<string>("")
    const [paginationPage, setPaginationPage] = useState<number>(0)
    const [paginationRowsPerPage, setPaginationRowsPerPage] = useState<number>(10)
    const [totalEventCount, setTotalEventCount] = useState<number>(0)

    // var _ = require('lodash')

    const getEvents = (): void => {
        setLoading(true)
        eventApi.get(`/getAllEvents/${paginationRowsPerPage}/${paginationPage}`).then((result: any) => {
            setTotalEventCount(result.totalRecords)
            setEventList(_.orderBy(result.events,
                function (event: IEvent) {
                    return new Date(event.endDateTime).getTime()
                },
                sortDirection))

            setLoading(false)
        }).catch((err: Error) => {
            setError(err.message)
            setLoading(false)
        })
    }

    const duplicateEvent = (existingEvent: IEvent): void => {
        let eventToBeCopied: IEvent = { ...existingEvent };

        eventToBeCopied.eventId = "c" + new Date().getTime();
        eventToBeCopied.name = existingEvent.name + "-copy-" + new Date().getTime();
        eventToBeCopied.purchases = []
        eventToBeCopied.winningTicketList = []
        eventToBeCopied.stripePendingPurchases = []
        eventToBeCopied.currentHighestTicketNumber = 0

        eventApi.post("/create", eventToBeCopied).then((createdEvent: IEvent) => {
            eventApi.get(`/${existingEvent.eventId}/prizes`).then((prizes: IPrize[]) => {

                prizes.forEach((prize, index) => {
                    prize.eventId = eventToBeCopied.eventId;
                });

                prizeApi.post("/createMultiplePrizes", prizes).then(() => {
                    getEvents()
                }).catch((err: Error) => {
                    setError(err.message)
                })
            }).catch((err: Error) => {
                setError(err.message)
            })
        }).catch((err: Error) => {
            setError(err.message)
        })
    }

    const deleteEvent = (eventId: string): void => {
        eventApi.get(`/delete/${eventId}`).then(() => {
            openDeleteSuccessDialog()
            getEvents()
        }).catch((err: Error) => {
            setError(err.message)
        })
    }

    const countTickets = (event: IEvent, type: "VALUE" | "COUNT"): number => {
        // console.log(`${event.name} - ${type}`)
        let total: number = 0
        event.purchases.forEach(purchase => {
            if (purchase.isActive && purchase.status === "PAID") {
                if (type === "VALUE") {
                    total += purchase.ticketPricingBundle && Number(purchase.ticketPricingBundle.price)
                } else {
                    total += purchase.ticketPricingBundle && Number(purchase.ticketPricingBundle.multiple)
                }
            }
        });

        // console.log(total)

        return total
    }

    const handleSort = (columnName: string) => {
        setColumnToSort(columnName)
        let sortDirect: any = columnToSort === columnName ? invertDirection(sortDirection) : 'desc'
        setSortDirection(sortDirect)
    }

    const invertDirection = (currentDirection: string) => {
        if (currentDirection === "asc") {
            return "desc"
        } else if (currentDirection === "desc") {
            return "asc"
        }
    }

    const sortEvents = () => {
        if (columnToSort === "editedDate") {
            setEventList(_.orderBy(eventList,
                function (event: IEvent) {
                    return new Date(event.editedDate).getTime()
                },
                sortDirection))
        } else if (columnToSort === "endDateTime") {
            setEventList(_.orderBy(eventList,
                function (event: IEvent) {
                    return new Date(event.endDateTime).getTime()
                },
                sortDirection))
        } else if (columnToSort === "totalTicketsSold") {
            setEventList(_.orderBy(eventList,
                function (event: IEvent) {
                    return countTickets(event, "COUNT")
                },
                sortDirection))
        } else if (columnToSort === "totalTicketValue") {
            setEventList(_.orderBy(eventList,
                function (event: IEvent) {
                    return countTickets(event, "VALUE")
                },
                sortDirection))
        } else {
            setEventList(_.orderBy(eventList, columnToSort, sortDirection))
        }
    }

    const handleCloseDialog = (isConfirmed: boolean) => {
        if (dialogProperties.type === "AREYOUSURE") {
            if (isConfirmed) {
                deleteEvent(eventToBeDeleted)
            }
        }
        setDialogProperties({})
        setIsConfirmationDialogOpen(false)
    }

    const openAreYouSureDialog = (eventId: string) => {
        setEventToBeDeleted(eventId)
        setDialogProperties({ type: "AREYOUSURE", title: "Confirm Event Delete", message: "Are you sure you want to delete this event?" })
        setIsConfirmationDialogOpen(true)
    }

    const openDeleteSuccessDialog = () => {
        setDialogProperties({ type: "SUCCESS", title: "Event Deleted", message: "Event deleted successfully." })
        setIsConfirmationDialogOpen(true)
    }

    const handlePaginationChangePage = (event: unknown, newPage: number) => {
        setPaginationPage(newPage);
    };

    const handlePaginationChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPaginationRowsPerPage(parseInt(event.target.value, 10));
        setPaginationPage(0);
    };

    useEffect(() => {
        getEvents()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        getEvents()
        // eslint-disable-next-line
    }, [paginationPage])

    useEffect(() => {
        sortEvents()
        // eslint-disable-next-line
    }, [columnToSort, sortDirection])

    if (loading) {
        return (
            <Loading />
        )
    }

    if (error) {
        return (
            <p>{error}</p>
        )
    }

    return (
        <div className="event-list-page">
            <h1>Event List.</h1>
            {eventList.length > 0 ?
                <>
                    <Card>
                        <CardContent>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <div onClick={() => handleSort("name")} className="table-header-cell sortable">
                                                <span>Event</span>
                                                {
                                                    columnToSort === "name" ? (
                                                        sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                                    ) : null
                                                }
                                            </div>
                                        </TableCell>

                                        <TableCell className="hide-on-mobile">
                                            <div onClick={() => handleSort("editedDate")} className="table-header-cell sortable">
                                                <span>Edited Date</span>
                                                {
                                                    columnToSort === "editedDate" ? (
                                                        sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                                    ) : null
                                                }
                                            </div>
                                        </TableCell>
                                        <TableCell className="hide-on-mobile">

                                            <div onClick={() => handleSort("endDateTime")} className="table-header-cell sortable">
                                                <span>Event End Date</span>
                                                {
                                                    columnToSort === "endDateTime" ? (
                                                        sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                                    ) : null

                                                }
                                            </div>
                                        </TableCell>
                                        <TableCell className="hide-on-mobile">
                                            <div onClick={() => handleSort("totalTicketsSold")} className="table-header-cell sortable">
                                                <span>Tickets Sold (Paid)</span>
                                                {
                                                    columnToSort === "totalTicketsSold" ? (
                                                        sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                                    ) : null
                                                }
                                            </div>
                                        </TableCell>
                                        <TableCell className="hide-on-mobile">
                                            <div onClick={() => handleSort("totalTicketValue")} className="table-header-cell sortable">
                                                <span>Total Value (Paid)</span>
                                                {
                                                    columnToSort === "totalTicketValue" ? (
                                                        sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                                    ) : null
                                                }
                                            </div>
                                        </TableCell>


                                        <TableCell className="hide-on-mobile">
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {eventList.map((event, index) => (
                                        <TableRow key={index}>
                                            <TableCell><Link to={`/admin/events/${event.eventId}`}>{event.name}</Link></TableCell>
                                            <TableCell className="hide-on-mobile">{moment(event.editedDate).format("HH:mm DD/MM/YYYY")}</TableCell>
                                            <TableCell className="hide-on-mobile">{moment(event.endDateTime).format("HH:mm DD/MM/YYYY")}</TableCell>
                                            <TableCell className="hide-on-mobile">{countTickets(event, "COUNT")}</TableCell>
                                            <TableCell className="hide-on-mobile">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'GBP', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(countTickets(event, "VALUE"))}</TableCell>
                                            <TableCell className="hide-on-mobile">
                                                <div className="action-cell">
                                                    <Link to={`/${event.eventId}`} target="_blank"><Tooltip title="Open Event (in new tab)" enterDelay={500}><OpenInNewIcon /></Tooltip></Link>
                                                    <Link to={`/admin/events/${event.eventId}`}><Tooltip title="Edit Event" enterDelay={500} ><CreateIcon /></Tooltip></Link>
                                                    <div onClick={() => duplicateEvent(event)}>
                                                        <Tooltip title="Duplicate Event" enterDelay={500} ><FileCopyIcon /></Tooltip>
                                                    </div>
                                                    <div onClick={() => openAreYouSureDialog(event.eventId)}>
                                                        <Tooltip title="Delete Event" enterDelay={500}><DeleteIcon /></Tooltip>
                                                    </div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50]}
                                component="div"
                                count={totalEventCount}
                                rowsPerPage={paginationRowsPerPage}
                                page={paginationPage}
                                onPageChange={handlePaginationChangePage}
                                onRowsPerPageChange={handlePaginationChangeRowsPerPage}
                            />
                        </CardContent>
                    </Card >
                </>
                :
                <Card><CardContent><div className="no-entries">No events configured</div></CardContent></Card>
            }
            <div className="button-wrapper space-between">
                <div className="hide-on-mobile"></div>
                <Link to={'/admin/events/new'}>
                    <Button className="lh-button">New Event</Button>
                </Link>
            </div>
            <ConfirmationDialog
                handleClose={handleCloseDialog}
                isDialogOpen={isConfirmationDialogOpen}
                message={dialogProperties.message}
                title={dialogProperties.title}
                type={dialogProperties.type}
                label={dialogProperties.label}
            />
        </div >
    )
}

export default EventListPage
