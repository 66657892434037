import React from 'react'
import "./home.scss"
import { Card } from '@mui/material';
import { ReactComponent as LighthouseLogo } from '../../assets/lighthouse-logo-colour.svg';

const HomePage = () => {

    return (
        <div className="home flex-container">
            <Card className="home-card">
                <Card className="image-card">
                    <LighthouseLogo />
                </Card>
                <h2>Welcome to Lighthouse Lotto</h2>
                <span>Please navigate to the correct event using the link provided by the event organiser.</span>
            </Card>
        </div>
    )
}

export default HomePage
