import './mediaSection.scss'
import React, { useState } from 'react'
import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MediaDialog from '../Dialogs/MediaDialog/MediaDialog'
import IPrize from '../../interfaces/prize'
import IMedia from '../../interfaces/media'
import getYouTubeID from 'get-youtube-id'

interface InputProps {
    prize: IPrize
    setPrize: (prize: IPrize) => void,
}

const MediaSection = (props: InputProps) => {

    const [isMediaDialogOpen, setIsMediaDialogOpen] = useState<boolean>(false);
    const [tempMedia, setTempMedia] = useState<IMedia>({} as IMedia);

    const closeDialog = () => {
        setIsMediaDialogOpen(false)
    }
    const openDialog = (media: IMedia) => {
        setTempMedia(media)
        setIsMediaDialogOpen(true)
    }

    const deleteMedia = (index: number) => {
        let tempMediaObject: any[] = [...props.prize.media]
        tempMediaObject.splice(index, 1)
        props.setPrize({ ...props.prize, media: tempMediaObject })
    }

    const increaseMediaOrder = (index: number) => {
        let tempMediaObject: any[] = [...props.prize.media]

        if (index !== 0) {
            let mediaItemToSwap: IMedia = props.prize.media[index]
            let mediaItemToBeSwapped: IMedia = props.prize.media[index - 1]

            tempMediaObject[index] = mediaItemToBeSwapped
            tempMediaObject[index - 1] = mediaItemToSwap

            props.setPrize({ ...props.prize, media: tempMediaObject })
        } else {
            console.log("Can't promote - media item already first in the array")
        }
    }

    const decreaseMediaOrder = (index: number) => {
        let tempMediaObject: any[] = [...props.prize.media]

        if (index !== props.prize.media.length - 1) {
            let mediaItemToSwap: IMedia = props.prize.media[index]
            let mediaItemToBeSwapped: IMedia = props.prize.media[index + 1]

            tempMediaObject[index] = mediaItemToBeSwapped
            tempMediaObject[index + 1] = mediaItemToSwap

            props.setPrize({ ...props.prize, media: tempMediaObject })
        } else {
            console.log("Can't demote - media item already last in the array")
        }

    }

    return (
        <div className="media-section">
            <h2 className="subtitle">Prize Media.</h2>
            <span className="description">Select the images and/or videos for the prize.</span>
            <Paper className="input-wrapper">
                {props.prize.media && props.prize.media.length > 0 ?
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                <div className="table-header-cell">
                                        <span>Source</span>
                                    </div>
                                </TableCell>
                                <TableCell className="hide-on-mobile">
                                <div className="table-header-cell">
                                        <span>Type</span>
                                    </div>
                                </TableCell>
                                <TableCell className="hide-on-mobile">
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.prize.media.map((media: IMedia, index) => (
                                <TableRow key={new Date().getTime() + index}>
                                    <TableCell>
                                        {media.mediaType === "IMAGE" ?
                                            <img alt={media.title} onClick={() => openDialog(media)} className="media-thumbnail" src={media.src} /> :
                                            <img alt={media.title} onClick={() => openDialog(media)} className="media-thumbnail" src={`http://img.youtube.com/vi/${getYouTubeID(media.src)}/0.jpg`} />
                                        }

                                    </TableCell>
                                    {/* <TableCell><span>{media.src}</span></TableCell> */}
                                    <TableCell className="hide-on-mobile">{media.mediaType}</TableCell>
                                    <TableCell className="hide-on-mobile">
                                        <div className="action-cell">
                                            <div onClick={() => increaseMediaOrder(index)}><Tooltip title="Promote Media"><ExpandLessIcon /></Tooltip></div>
                                            <div onClick={() => decreaseMediaOrder(index)}><Tooltip title="Demote Mdeia"><ExpandMoreIcon /></Tooltip></div>
                                            <div onClick={() => openDialog(media)}><Tooltip title="Edit Media"><CreateIcon /></Tooltip></div>
                                            <div onClick={() => deleteMedia(index)}><Tooltip title="Delete Media"><DeleteIcon /></Tooltip></div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    :
                    <div className="no-entries">No media defined.</div>
                }
                <div className="button-wrapper">
                    {/* <Button className="lh-button" onClick={() => openDialog({} as IMedia)}>Add New Media</Button> */}
                    <Button className="lh-button" style={{marginRight: 12}} onClick={() => openDialog({mediaType: "YOUTUBE"} as IMedia)}>Add Youtube Video</Button>
                    <Button className="lh-button" onClick={() => openDialog({mediaType: "IMAGE"} as IMedia)}>Add Image(s)</Button>
                </div>
            </Paper>
            <MediaDialog
                handleClose={closeDialog}
                isDialogOpen={isMediaDialogOpen}
                setPrize={props.setPrize}
                prize={props.prize}
                media={tempMedia}
            />
        </div >
    )
}

export default MediaSection