import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import Error from '../../components/Error/Error'
import EventFooter from '../../components/EventFooter/EventFooter'
import EventMenuBar from '../../components/EventMenuBar/EventMenuBar'
import Loading from '../../components/Loading/Loading'
import LotImageCarousel from '../../components/LotImageCarousel/LotImageCarousel'
import useFetch from '../../hooks/useFetch'
import IEvent from '../../interfaces/event'
import IPageProps from '../../interfaces/page'
import IPrize from '../../interfaces/prize'
import './prizeDetails.scss'
import { useHistory } from "react-router-dom";
import { Tooltip } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';




const PrizeDetailsPage: React.FunctionComponent<IPageProps & RouteComponentProps<any>> = (props) => {

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>("")
    const [prize, setPrize] = useState<IPrize>({} as IPrize)
    const [event, setEvent] = useState<IEvent>({} as IEvent)

    const prizeApi = useFetch("prizes")
    const eventApi = useFetch("events")
    const history = useHistory()

    const getEvent = (): void => {
        setLoading(true)
        eventApi.get(`/getEventById/${props.match.params.eventId}/false`).then((event: IEvent) => {
            prizeApi.get(`/${props.match.params.prizeId}`).then((prize: IPrize) => {
                setPrize(prize)
                setEvent(event)
                setLoading(false)
            }).catch((err: Error) => {
                setError(err.message)
                setLoading(false)
            })
        }).catch((err: Error) => {
            setError(err.message)
            setLoading(false)
        })
    }

    useEffect(() => {
        getEvent()

        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    if (loading) {
        return (
            <div className="prize-details-page">
                <EventMenuBar event={event} />
                <Loading />
            </div>
        )
    }

    if (error) {
        return (
            <div className="prize-details-page">
                <EventMenuBar event={event} />
                <Error buttonAction={() => window.location.reload()} error={error} eventId={props.match.params.auctionId} />
                <EventFooter event={event} />
            </div>
        )
    }


    return (
        <div className="prize-details-page">
            <EventMenuBar event={event} />
            <div className="header-section" style={{ backgroundColor: event.headerBackgroundColor, color: event.headerFontColor }}>
            <span onClick={() => history.push(`/${event.eventId}/prizes`)}><Tooltip title="Back to prizes"><ArrowBackIcon /></Tooltip></span>
                Prize Details
                </div>
            <div className="content">
                <div className="two-columns">
                    {prize.media && 
                    <div className="carousel-wrapper">
                        <LotImageCarousel mediaList={prize.media} />
                    </div>
                    }
                    <div className="description-wrapper">
                        <div className="title">{prize.title}</div>
                        <div className="sub-title">{prize.subtitle}</div>
                        <div dangerouslySetInnerHTML={{ __html: prize.descriptionHtml }}></div>
                    </div>
                </div>

            </div>
            <EventFooter event={event} />
        </div>
    )
}

export default PrizeDetailsPage
