import React from "react"
import './eventNotFound.scss'
import GhostImage from '../../assets/ghost.png'

const EventNotFound = () => {

    return (
        <div className="eventNotFound-wrapper">
            <h2>Auction Not Found</h2>
            <img src={GhostImage} alt="ghost" />
            <p>This event does not exist. Please check you have entered the web address correctly.</p>
            <p>If the error persists please notify the event organiser.</p>
        </div>
    )
}

export default EventNotFound