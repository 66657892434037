import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import Error from '../../components/Error/Error'
import EventFooter from '../../components/EventFooter/EventFooter'
import EventMenuBar from '../../components/EventMenuBar/EventMenuBar'
import Loading from '../../components/Loading/Loading'
import useFetch from '../../hooks/useFetch'
import IEvent from '../../interfaces/event'
import IPageProps from '../../interfaces/page'

const TermsAndConditionsPage: React.FunctionComponent<IPageProps & RouteComponentProps<any>> = (props) => {

    const [loading, setLoading] = useState<boolean>(false)
    const [event, setEvent] = useState<IEvent>({} as IEvent)
    const [error, setError] = useState<string>("")

    const eventApi = useFetch("events")


    const getEvent = (): void => {
        setLoading(true)
        eventApi.get(`/getEventById/${props.match.params.eventId}/false`).then((event: IEvent) => {
            setEvent(event)
        }).catch((err: Error) => {
            setError(err.message)
            setLoading(false)
        })
    }

    useEffect(() => {
        getEvent()

        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    if (loading) {
        return (
            <div className="event-page">
                <EventMenuBar event={event} />
                <Loading />
            </div>
        )
    }

    if (error) {
        return (
            <div className="event-page">
                <EventMenuBar event={event} />
                <Error buttonAction={() => window.location.reload()} error={error} eventId={props.match.params.eventId} />
            </div>
        )
    }

    return (
        <div className="terms-and-conditions-page" >
            <EventMenuBar event={event} />
            <div className="header-section" style={{ backgroundColor: event.headerBackgroundColor, color: event.headerFontColor }}>
                Terms and Conditions
            </div>
            <div className='content' style={{ fontSize: '0.8rem' }}>
                <h2>1. INTRODUCTION</h2>
                <ol>
                    <li>Lighthouse Construction Industry Charity  (the “Charity”) manages, promotes and offers the Lighthouse Lotto (the “Lottery”), an incidental lottery/raffle platform with a paid entry method, in accordance with these Terms and Conditions (the “Terms”)</li>
                    <li>Lighthouse Lotto is operated by Charity and is intended for use as an incidental lottery at an event and governed by the rules of such lotteries see: <a href='https://www.gamblingcommission.gov.uk/public-and-players/guide/page/how-to-run-a-fundraiser-with-lotteries-or-raffles-at-events' target="_blank" rel="noreferrer">https://www.gamblingcommission.gov.uk/public-and-players/guide/page/how-to-run-a-fundraiser-with-lotteries-or-raffles-at-events</a> </li>
                    <li>Players can participate online via web link (the “Website”).</li>
                    <li>All entries into and participation in the Lottery are strictly governed by these Terms. The Charity reserves the right to amend these Terms from time to time and shall publish the most up-to- date version on the Website.</li>
                    <li>By entering a Lottery, a Player (as defined herein) agrees to be bound by these Terms at the date of entry.</li>
                    <li>These Terms are published on the Website. The Charity will provide a written copy of the Terms by post if requested to do so upon receipt of a stamped, self-addressed envelope. Requests should be mailed to: The Lighthouse Construction Industry Charity, Suffolk Enterprise Centre, Felaw Maltings, Felaw Street, Ipswich, IP2 8SJ.</li>
                    <li>Players should play responsibly. Information on how to gamble responsibly and how to access information about problem gambling can be found on this Website. <a href='https://www.begambleaware.org/' target="_blank" rel="noreferrer">https://www.begambleaware.org/</a> </li>
                </ol>

                <h2>2. DEFINITIONS</h2>
                Capitalised words shall have the following meanings:
                <ol>
                    <li>“Authorised Seller” means such person as the Charity has expressly authorised to sell Tickets at the Venue.</li>
                    <li>“Charity” means Lighthouse Construction Industry Charity</li>
                    <li>“Help Desk” means the Charity help desk which can be contacted at 0345 605 2956</li>
                    <li>“Draw” means the draw conducted by the Charity to determine the winner(s) of Prize(s) for each Lottery.</li>
                    <li>“Platform” means the computer system operated by the Charity, or its software suppliers or subcontractors, for the purpose of registering Players, accepting entries for the Lotteries  and selecting Winning Numbers.</li>
                    <li>“Excluded Person” means any person who:</li>
                    <ol>
                        <li>is a director or an employee of the Charity;</li>
                        <li>is a spouse or dependent of a director or an employee of the Charity </li>
                        <li>makes any misrepresentation of fact as to the Player’s eligibility whether intentional or unintentional;</li>
                        <li>Is a person  the Gambling Commission or the Charity may specify from time <a href='http://www.gamcare.org.uk/' target="_blank" rel="noreferrer">www.gamcare.org.uk</a>  <a href='mailto:help@matchdaylottery.com' target="_blank" rel="noreferrer">help@matchdaylottery.com</a> Terms and Conditions 2/8 to time as being ineligible to participate in a Lottery; or</li>
                        <li>the Club or Ascend believes, in its sole discretion, is using technology to gain an unfair advantage over other Players.</li>
                    </ol>
                    <li>“Grand Prize” means the top prize in any Lottery. There is one (1) Grand Prize awarded in every Lottery. The Grand Prize is determined by the Charity and may vary in value between draws.</li>
                    <li>“Lottery” means the prize competition operated by the Charity.</li>
                    <li>"Net Proceeds” means Proceeds less reasonable expenses incurred to operate the Lottery.</li>
                    <li>“Online Account” means an account opened by a Player to participate in a Lottery online via the Website.</li>
                    <li>"Player” and “Online Player” means any person who purchases a Ticket in a Lottery in Venue or online via the Website in accordance with these Terms and who is not an Excluded Person.</li>
                    <li>"Privacy Policy” means the Lighthouse Lotto Privacy Policy which is available for review</li>
                    <li>“Prize(s)” means any one of following: a) the Grand Prize, b) a Supporter Prize depending upon the context in which the term is used.</li>
                    <li>“Proceeds” means the total gross proceeds received by the Charity from all Tickets sales for a Draw.</li>
                    <li>“Supporter Prize(s)” means multiple non-cash prize(s) that may be added from time to time by the Charity ,in its sole discretion, and won by entering a Lottery in accordance with these Terms. The frequency and number of Supporter Prizes may change every draw.</li>
                    <li>“Terms and Conditions” and “Terms” means these terms and conditions.</li>
                    <li>“Ticket” means a ticket to participate in a Lottery which is purchased by a Player at the Venue or online via the Website.</li>
                    <li>“Ticket Number” means the Player’s Lottery number displayed on a paper or digital Ticket.</li>
                    <li>"Website” means the Lottery website hosted by the Charity.</li>
                    <li>“Winner” means the person in possession of the Winning Number of any Lottery that has been verified by the Charity, and who is not an Excluded Person.</li>
                    <li>“Winning Number” means a number produced by the Platform’s certified random number generator integrated into the Platform which during the process of selecting the numbers will entitle the holder of a Ticket with a matching Ticket Number to the Grand Prize.</li>
                </ol>

                <h2>3. CONDITIONS OF ENTRY</h2>
                <ol>
                    <li>To enter a Lottery and to be eligible to win any Prize, a person must:</li>
                    <ol>
                        <li>be 18 years of age or over;</li>
                        <li>comply with these Terms at the time of entry as the same may be here (/privacy) Terms and Conditions 3/8 2. comply with these Terms at the time of entry, as the same may be amended from time to time; any other rules of the Lottery provided or published by the Charity; any applicable provisions of the Act; and any relevant regulations made thereunder from time to time; and</li>
                        <li>not be an Excluded Person.</li>
                    </ol>
                    <li>In purchasing a Ticket, Players acknowledge and agree that a minimum of twenty percent (80%) of the Ticket price will go to the Charity in furtherance of its charitable causes.</li>
                    <li>The Charity will determine, in their sole discretion, relying on their own technical records, when determining whether a Player is eligible to receive a Prize and designated as a Winner.</li>
                    <li>The Charity reserve the right to refuse any entry to the draw, to withhold any Prize and not designate a Winner if it has reasonable grounds to believe that these Terms, or any applicable provisions of the Act, have not been complied with.</li>
                </ol>

                <h2>4. HOW TO ENTER THE PRIZE DRAW</h2>
                <ol>
                    <li>Players can enter a Lottery either by purchasing a paper Ticket from an Authorised Seller at the Venue, by purchasing a Ticket using the Website at the venue</li>
                    <li>There are price discounts for purchasing multiple Tickets.</li>
                    <li>Each Ticket will set out a Ticket Number for the Lottery. Prizes will be allocated based on the Ticket Number as set out in Clause 6.</li>
                    <li>Authorised Sellers may require proof of age or other identification to be shown before a Ticket will be sold. The Charity and Authorised Sellers each reserve the right to refuse sales to anyone who they believe is an Excluded Person or is otherwise not eligible under Clause 3.1.</li>
                    <li>A Ticket will be valid only for the Draw to be held on the date(s) shown on the face of the Ticket. It is the Player’s responsibility to check at the time of purchase that the date of the Draw on their Ticket is as requested.</li>
                    <li>Ticket Numbers are issued at random and not chosen by the Player.</li>
                    <li>The Player is solely responsible for ensuring that the Ticket remains safe and in a good and legible condition. For Players having participated in the Lottery by purchasing a Ticket from an Authorised Seller at the Venue, the Ticket is the sole evidence of entry into the Draw and must be in the possession of the Player to enable them to claim a Prize.</li>
                    <li>The Charity accepts no responsibility for lost, stolen, damaged or defaced Tickets. Prizes will not be awarded where the relevant Ticket cannot be produced.</li>
                </ol>

                <h2>5. ONLINE PLAYERS</h2>
                <ol>
                    <li>It is a condition of entering a Draw, that all Players must be located in the UK at the time of purchase. Online Players agree that by entering a Lottery whilst located anywhere other than Great Britain is strictly prohibited. In such circumstances:</li>
                    <ol>
                        <li>the Person shall be considered an Excluded Person; and</li>
                        <li>the Charity has the right to terminate the Online Account and withhold  any Prize.</li>
                    </ol>
                    <li>Players may be asked if they wish to be contacted for advertising, promotional and marketing purposes in accordance with the terms of the Privacy Policy. If Players wish to be removed from the Charity mailing lists, they should click on the unsubscribe link shown on all distributed e- mails or in their online account. They may also contact the Help Desk.</li>
                    <li>Online Players can play by making one-time purchases.</li>
                    <li>It is the responsibility of the Player to ensure that the information supplied by the Player is accurate and up-to-date.</li>
                    <li>Player credit card details are not stored on the platform. A token is stored in place of the credit card details and used to purchase Tickets.</li>
                    <li>The Charity reserves the right to retain personal details for its own reasonable legal, regulatory and operational purposes after an Online Account is closed in accordance with the Privacy Policy. Reasons may include, but are not limited to, compliance with anti-money laundering legislation and any other record keeping requirements for legal or regulatory compliance.</li>
                    <li>The Charity, at its sole discretion, may terminate, vary or suspend any provision of the Draw or access to an Online Account without prior notice:</li>
                    <ol>
                        <li>on breach of these Terms;</li>
                        <li>for maintenance work required to upgrade or update the Platform;</li>
                        <li>if the Charity cease to offer Lotteries; or</li>
                        <li>for any other reason at the Charity’s sole and reasonable discretion.</li>
                    </ol>
                </ol>

                <h2>6. THE DRAW AND PRIZES</h2>
                <ol>
                    <li>The Draws will take place either at the Venue or remotely. </li>
                    <li>The results of a Draw will be published on the Website and a Prize shall only be awarded to a Player holding (in person or online) a Ticket which has the Ticket Number matching the selected Winning Number(s) by the Platform, as set out in this Clause 6. Draw results will also be announced at the Venue, and the method for announcing these results is at the discretion of the Charity.</li>
                    <li>For each Draw, the Winning Number will be chosen randomly.</li>
                    <li>The Winning Number(s) for the Supporter Prize(s) will be chosen randomly immediately after the drawing of the Grand Prize.</li>
                    <li>Subject to this Clause 6, where a Ticket Number matches a Winning Number, Prizes will be paid to the holders of Tickets with those matching Ticket Numbers as follows:</li>
                    <ol>
                        <li>If a Winning Number for a Grand Prize is matched by the Ticket Number on a Player’s Ticket, the Player will win the prize as promoted by the Charity.</li>
                        <li>If a Winning Number for a Runner up or consolation Prize is matched by the Ticket Number on a Player’s Ticket, the Player will win the relevant runner up or consolation prize.</li>
                    </ol>
                    <li>Players who believe they have won a prize contact the Help Desk.</li>
                    <li>In any event, the Charity will only pay out Prizes to Players who have won with a paper Ticket by cheque or electronic transfer to a UK bank account after verifying the Player’s identity. The identity details of any bank account nominated to receive Prize winnings must match Player identity  details.8.	No Prize will be paid on a Ticket that has been forged, altered, damaged or defaced in any way.</li>
                    <li>No Prize will be paid on a Ticket that fails the Charity validation process.</li>
                    <li>Any Player with a winning Ticket may be required to provide such proof of their identity, age and compliance with these Terms as the Charity require before the Prize payment is made.</li>
                    <li>Where a Prize is paid to an Excluded Person, a person in breach of these Terms, or is otherwise paid in error, the Prize must be repaid immediately to the Charity.</li>
                    <li>The Charity shall provide the Prize payment to the Winner within fourteen (14) business days of receiving satisfactory proof of the Player’s identity and validation of the Winning Number on the Ticket submitted for verification. The Charity will not be liable for late payments resulting from inaccuracies of payment details provided by a Player, or any rejection of payments caused by the Player’s bank.</li>
                    <li>Each Winner is responsible for paying any and all taxes due on any Prize(s) won.</li>
                    <li>Prizes must be claimed no later than 12:00pm sixty (60) days following the day of the Draw.</li>
                    <li>Any Prize not claimed within sixty (60) days will be forfeited and returned to the Charity.</li>
                    <li>Except when required to do so by law, the Charity will not make information about   Winners public without first obtaining their written consent.</li>
                    <li>Any questions or complaints relating to any Lottery should be communicated to the Help Desk.</li>
                </ol>



                <h2>7. LIMITATION OF LIABILITY & RESERVATION OF RIGHTS</h2>
                <ol>
                    <li>Nothing in these Terms shall exclude or limit the Charity’s liability for fraudulent misrepresentation or death or personal injury resulting from the Charity’s gross negligence, or for any other liability that cannot be excluded or limited by law.</li>
                    <li>Neither the Charity will be liable for any loss or damage, including the loss of opportunity to enter a given Lottery and/or the right to receive a Prize(s), suffered by a Player if such Player has not complied with these Terms.</li>
                    <li>Subject to Clause 9.1, the Charity shall be liable in contract, tort (including but not limited to negligence), or otherwise in connection with the Lotteries for: </li>
                    <ol>
                        <li>loss of revenue, contract, profits, business or anticipated savings or loss of data;</li>
                        <li>any special or indirect or consequential losses; Terms and Conditions</li>
                        <li>any loss of goodwill or reputation; or</li>
                        <li>the loss of opportunity to enter a Lottery and/or right to receive a Prize.</li>
                    </ol>
                    <li>Subject to Clause 9.1, the Charity’s liability to any Player shall be limited to the amount   of any Prize which may have become due to that Player upon confirmation of that Player as a Winner.</li>
                    <li>The Charity make no warranty, representation or guarantee that use of the Websites, Platform, Help Desk, telephone system, and broadcasts or live transmissions will be uninterrupted, timely and/or error-free.</li>
                    <li>Where the Website contains links to other sites and resources provided by third parties, these links are provided for the Player’s information only. Such links should not be interpreted as approval by the Charity of those linked websites, the content of those websites, or information a Player may obtain from them. The Charity has no control over the contents of those sites or resources, and links from the Website should not be interpreted as an endorsement in any way by the Club or Ascend.</li>
                    <li>Nothing contained in these Terms shall affect the statutory rights of any Player or the Charity.</li>
                </ol>



                <h2>8.	INTELLECTUAL PROPERTY</h2>
                <ol>
                    <li>The Player acknowledges that, as between the Player and the Charity, all intellectual property rights in the Lottery throughout the world belong to the Charity, and its affiliates, and that the Player has no intellectual property rights in, or to, any Lottery, the Website or the Platform.</li>
                </ol>
                
                <h2>9.	DATA PROTECTION</h2>
                <ol>
                    <li>All information provided by Players to the Charity for the purposes of the Lottery will be stored, managed and used in accordance with the Lighthouse Lotto Privacy Policy which is available</li>
                </ol>
                <h2>10.	GOVERNING LAW</h2>
                <ol>
                    <li> These Terms are governed by and construed in accordance with the laws of England and Wales and shall be subject to the jurisdiction of the English and Welsh courts. However, if a Player lives in Scotland, the Player can bring legal proceedings in in either the Scottish or the English courts.</li>
                </ol>
                <h2>11.	REFUND POLICY</h2>
                <ol>
                    <li>Ticket purchases are non-refundable</li>
                </ol>
                













            </div>
            <EventFooter event={event} />
        </div>
    )
}

export default TermsAndConditionsPage