import React, { useContext, useState } from 'react'
import { AppBar, Menu, MenuItem, Toolbar } from '@mui/material'
import { Link } from 'react-router-dom'
import "./adminMenuBar.scss"
import { ReactComponent as LighthouseLogo } from '../../assets/lighthouse-logo-inline-white.svg';
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import UserContext, { initialUserState } from '../../contexts/user'

interface InputProps {}

const AdminMenuBar: React.FunctionComponent<InputProps> = (props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const userContext = useContext(UserContext)

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logOut = () => {
        userContext.userDispatch({ type: 'logout', payload: initialUserState })
    }

    return (
        <>
            <div className="menu-bar">
                <AppBar>
                    <Toolbar>
                        <div className="logo-wrapper">
                            <Link to={'/admin/events'}>
                                <LighthouseLogo />
                            </Link>
                        </div>
                        <div className="menu-items">
                            <div className="authorised-email" onClick={handleClick}>
                                <span className="username">{userContext.userState.user.email}</span>
                                <AccountCircleIcon />
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
                <Menu
                    id="logout-menu"
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={logOut}>Log Out</MenuItem>
                </Menu>
            </div>
        </>
    )
}

export default AdminMenuBar