import { Button, Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import { ReactComponent as PoweredByStripeLogo } from '../../../assets/powered-by-stripe.svg';

interface InputProps {
    paymentMethod: string
    bothPaymentMethod: string
    purchaseValidation: any
    isTermsAndConditionsAgreed: boolean
    setIsTermsAndConditionsAgreed: (isTermsAndConditionsAgreed: boolean) => void
    handleClose: (isSuccess: boolean, isError: boolean, error?: string) => void
    confirmPurchase: (transactionType: 'ONLINE' | 'OFFLINE') => void
    isPurchaseInProgress: boolean
}

const ActionSection = (props: InputProps) => {

    const determinePaymentButton = () => {
        if (props.paymentMethod === "OFFLINE") {
            return (
                <Button
                    onClick={() => props.confirmPurchase("OFFLINE")}
                    className="lh-button confirm"
                    disabled={props.isPurchaseInProgress}
                >
                    <span>Confirm Purchase</span>
                </Button>
            )
        } else if (props.paymentMethod === "ONLINE") {
            return (
                <div className='stripe-button-wrapper'>
                    <Button
                        onClick={() => props.confirmPurchase("ONLINE")}
                        className="lh-button confirm"
                        disabled={props.isPurchaseInProgress}
                    >
                        <span>PROCEED TO PURCHASE PAGE</span>
                    </Button>
                    <PoweredByStripeLogo />
                </div>
            )
        } else {
            if (props.bothPaymentMethod === "ONLINE"){
                return (
                    <div className='stripe-button-wrapper'>
                        <Button
                            onClick={() => props.confirmPurchase("ONLINE")}
                            className="lh-button confirm"
                            disabled={props.isPurchaseInProgress}
                        >
                            <span>PROCEED TO PURCHASE PAGE</span>
                        </Button>
                        <PoweredByStripeLogo />
                    </div>
                )
            }else{
                return (
                    <Button
                        onClick={() => props.confirmPurchase("OFFLINE")}
                        className="lh-button confirm"
                        disabled={props.isPurchaseInProgress}
                    >
                        <span>Confirm Purchase</span>
                    </Button>
                )
            }
            
        }
    }

    return (
        <div className="action-section">
            {!props.purchaseValidation.getValidation("termsAndConditions").isValid ?
                <p className="validation-text">{props.purchaseValidation.getValidation("termsAndConditions").validationMessage}</p>
                :
                <>
                    {!props.purchaseValidation.isValidationPassed && <p className="validation-text">Something's not right, please check highlighted fields above.</p>}
                </>
            }

            <FormControlLabel
                control={
                    <Checkbox
                        checked={props.isTermsAndConditionsAgreed}
                        onChange={() => props.setIsTermsAndConditionsAgreed(!props.isTermsAndConditionsAgreed)}
                    />
                }
                label="I agree to the terms and conditions of this site"
                labelPlacement="start"
            />

            <div className="dialog-button-wrapper">

                <Button onClick={() => props.handleClose(false, false)} className="cancel">
                    Cancel
                </Button>
                {determinePaymentButton()}
            </div>
        </div>
    )
};

export default ActionSection;
