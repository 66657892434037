import React, { useEffect, useRef, useState } from 'react'
import EventMenuBar from '../../components/EventMenuBar/EventMenuBar'
import IPageProps from '../../interfaces/page'
import useFetch from '../../hooks/useFetch';
import IEvent from '../../interfaces/event';
import { RouteComponentProps } from 'react-router';
import Loading from '../../components/Loading/Loading';
import Error from '../../components/Error/Error';
import './tickets.scss'
import TicketPricingBundleCard from '../../components/TicketPricingBundleCard/TicketPricingBundleCard';
import PurchaseDialog from '../../components/Dialogs/PurchaseDialog/PurchaseDialog';
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog/ConfirmationDialog';
import EventFooter from '../../components/EventFooter/EventFooter';
import moment from 'moment';
import ITicketPricingBundle from '../../interfaces/ticketPricingBundle';
import { useHistory } from "react-router-dom";

const TicketsPage: React.FunctionComponent<IPageProps & RouteComponentProps<any>> = (props) => {

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>("")
    const [event, setEvent] = useState<IEvent>({} as IEvent)
    const [isPurchaseDialogOpen, setIsPurchaseDialogOpen] = useState<boolean>(false);
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
    const [confirmationDialogProperties, setConfirmationDialogProperties] = useState<any>({ type: "", title: "", message: "" })
    const [isClosed, setIsClosed] = useState<boolean>(false)
    const [selectedTicketPricingBundle, setSelectedTicketPricingBundle] = useState<ITicketPricingBundle>({} as ITicketPricingBundle)


    const eventApi = useFetch("events");
    const isCancelled = useRef(false)
    let history = useHistory();

    const getEvent = (): void => {
        //only set loading on the first fetch
        if (!event._id) {
            setLoading(true)
        }
        eventApi.get(`/getEventById/${props.match.params.eventId}/false`).then((_event: IEvent) => {
            setEvent(_event)
            checkEventClosed(_event)
            setLoading(false)
        }).catch((err: Error) => {
            setError(err.message)
            setLoading(false)
        })
    }

    const handlePurchaseDialogClose = (isSuccess: boolean, isError: boolean, error?: string) => {
        setIsPurchaseDialogOpen(false)

        if (isSuccess) {
            //open success notification dialog
            setIsConfirmationDialogOpen(true)
            setConfirmationDialogProperties({ type: "SUCCESS", title: "Purchase Submitted.", message: "Your Lighthouse Lotto tickets are pending payment. Someone will be with you shortly to collect payment and release your tickets." })
        } else if (isError) {
            setIsConfirmationDialogOpen(true)
            setConfirmationDialogProperties({ type: "ERROR", title: "Error.", message: error })
        }
    }

    const handleConfirmationDialogClose = (isSuccess: boolean) => {
        setIsConfirmationDialogOpen(false)
        history.push(`tickets`)
    }

    const checkEventClosed = (event: IEvent) => {
        if (moment(event.endDateTime).isBefore(moment())) {
            setIsClosed(true)
            setIsPurchaseDialogOpen(false)
        } else {
            if (isClosed) {
                setIsClosed(false)
            }
        }
    }

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            console.log("success")
            setIsConfirmationDialogOpen(true)
            setConfirmationDialogProperties({ type: "SUCCESS", title: "Payment Successful.", message: "Your stripe payment was successful. A confirmation message has been sent to the contact details provided." })
        }

        if (query.get("canceled")) {
            setIsConfirmationDialogOpen(true)
            setConfirmationDialogProperties({ type: "ERROR", title: "Stripe Payment Cancelled.", message: "Your stripe payment was cancelled. No funds have been debited from your account and your ticket(s) have not been purchased." })
        }

        getEvent()

        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    useEffect(() => {
        let timer = setTimeout(() => {
            getEvent()
        }, 10000);

        return () => {
            isCancelled.current = true;
            clearTimeout(timer);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [event]);

    if (loading) {
        return (
            <div className="tickets-page">
                <EventMenuBar event={event} />
                <Loading />
            </div>
        )
    }

    if (error) {
        if (error === "TypeError: Failed to fetch" && event._id) {
            console.log("Failed to fetch error after first fetch - simply do not refresh page.")
        } else {
            return (
                <div className="tickets-page">
                    <EventMenuBar event={event} />
                    <Error buttonAction={() => window.location.reload()} error={error} eventId={props.match.params.auctionId} />
                    <EventFooter event={event} />
                </div>
            )
        }
    }

    return (
        <div className="tickets-page">
            <EventMenuBar event={event} />
            <div className="header-section" style={{ backgroundColor: event.headerBackgroundColor, color: event.headerFontColor }}>Tickets</div>
            <div className="content">

                {event.ticketPricingBundles && event.ticketPricingBundles.length > 0 ?
                    <>
                        <p className="intro-text">
                            Please use the button(s) below to place your ticket order.
                        </p>
                        <div className="ticket-wrapper">
                            {event.ticketPricingBundles.map((ticketPricingBundle, index) => {
                                return (
                                    <TicketPricingBundleCard
                                        key={index}
                                        ticketPricingBundle={ticketPricingBundle}
                                        setSelectedTicketPricingBundle={setSelectedTicketPricingBundle}
                                        setIsPurchaseDialogOpen={setIsPurchaseDialogOpen}
                                        isClosed={isClosed}
                                    />
                                )
                            })}
                        </div>
                    </>
                    :
                    <div className="no-tickets-wrapper">
                        <h2>This event currently has no tickets!</h2>
                    </div>
                }
            </div>
            <EventFooter event={event} />

            <PurchaseDialog
                handleClose={handlePurchaseDialogClose}
                isDialogOpen={isPurchaseDialogOpen}
                event={event}
                setEvent={setEvent}
                ticketPricingBundle={selectedTicketPricingBundle}
            />

            <ConfirmationDialog
                handleClose={handleConfirmationDialogClose}
                isDialogOpen={isConfirmationDialogOpen}
                message={confirmationDialogProperties.message}
                title={confirmationDialogProperties.title}
                type={confirmationDialogProperties.type}
                label={confirmationDialogProperties.label}
            />
        </div>
    )
}

export default TicketsPage
