import firebase from 'firebase/compat/app'
import 'firebase/auth'
import { auth } from '../config/firebase'
import logging from '../config/logging'
import axios from 'axios'
import IUser from '../interfaces/user'

const NAMESPACE = 'Auth'

export const Authenticate = async (uid: string, email: string, fire_token: string, callback: (error: string | null, user: IUser | null) => void) => {
    try {
        let response: any = await axios({
            method: 'POST',
            url: process.env.PUBLIC_URL ? `${process.env.PUBLIC_URL}/users/login` : 'http://localhost:8080/users/login',
            data: {
                uid,
                email
            },
            headers: { Authorization: `Bearer ${fire_token}` }
        })

        if (response.status === 200 || response.status === 201 || response.status === 304) {
            logging.info('Successfully authenticated.', NAMESPACE)
            callback(null, response.data.user)
        } else {
            logging.warn('Unable to authenticate.', NAMESPACE)
            callback('Unable to authenticate.', null)
        }
    } catch (error) {
        logging.error(error, NAMESPACE)
        callback('Unable to authenticate.', null)
    }
}

export const Validate = async (fire_token: string, callback: (error: string | null, user: IUser | null) => void) => {
    try {
        let response: any = await axios({
            method: 'GET',
            url: process.env.PUBLIC_URL ? `${process.env.PUBLIC_URL}/users/validate` : 'http://localhost:8080/users/validate',
            headers: { Authorization: `Bearer ${fire_token}` }
        })

        if (response.status === 200 || response.status === 304) {
            logging.info('Successfully validated.', NAMESPACE)
            callback(null, response.data.user)
        } else {
            logging.warn(response, NAMESPACE)
            callback('Unable to validate.', null)
        }
    } catch (error) {
        logging.error(error, NAMESPACE)
        callback('Unable to validate.', null)
    }
}

export const SignInWithSocialMedia = (provider: firebase.auth.AuthProvider) =>
    new Promise<firebase.auth.UserCredential>((resolve, reject) => {
        auth.signInWithPopup(provider)
            .then((result: any) => resolve(result))
            .catch((error: any) => reject(error))
    })
