import { useState } from "react";
import ICustomPurchaseDetail from "../../../interfaces/customPurchaseDetail";
import IEvent from "../../../interfaces/event";
import IPurchase from "../../../interfaces/purchase";
import { IValidation } from "../../../interfaces/validation";

const usePurchaseValidation = (purchase: IPurchase, isTermsAndConditionsAgreed: boolean, event: IEvent, customPurchaseDetails: ICustomPurchaseDetail[], bothPaymentMethod: string) => {
    const [validationObject, setValidationObject] = useState<IValidation[]>([
        { name: "name", isValid: true, validationMessage: "" },
        { name: "email", isValid: true, validationMessage: "" },
        { name: "phone", isValid: true, validationMessage: "" },
        { name: "termsAndConditions", isValid: true, validationMessage: "" },
        { name: "bothPaymentMethod", isValid: true, validationMessage: "" },
    ])
    const [isValidationPassed, setIsValidationPassed] = useState<boolean>(true)

    const generateInitialValidationObject = (): void => {

        let tempValidationObject: IValidation[] = [
            { name: "name", isValid: true, validationMessage: "" },
            { name: "email", isValid: true, validationMessage: "" },
            { name: "phone", isValid: true, validationMessage: "" },
            { name: "termsAndConditions", isValid: true, validationMessage: "" },
            { name: "bothPaymentMethod", isValid: true, validationMessage: "" },
        ]

        customPurchaseDetails.forEach(element => {
            tempValidationObject.push({
                name: element._id,
                isValid: true,
                validationMessage: ""
            }) 
        });

        setValidationObject(tempValidationObject)

    }

    const validateInputs = (): boolean => {

        let tempValidationObject: IValidation[] = [
            { name: "name", isValid: true, validationMessage: "" },
            { name: "email", isValid: true, validationMessage: "" },
            { name: "phone", isValid: true, validationMessage: "" },
            { name: "termsAndConditions", isValid: true, validationMessage: "" },
            { name: "bothPaymentMethod", isValid: true, validationMessage: "" },
        ]

        customPurchaseDetails.forEach(element => {
            tempValidationObject.push({
                name: element._id,
                isValid: true,
                validationMessage: ""
            }) 
        });

        let tempIsValidationPassed = true

        const setFailedValidation = (name: string, message: string) => {
            tempIsValidationPassed = false
            tempValidationObject.forEach(element => {
                if (element.name === name) {
                    element.isValid = false
                    element.validationMessage = message
                }
            });
        }

        if (!isTermsAndConditionsAgreed) {
            setFailedValidation("termsAndConditions", "You must agree to the terms and conditions to make a purchase.")
        }

        if (purchase.name === "" || typeof (purchase.name) === "undefined") {
            setFailedValidation("name", "Please enter your name.")
        }

        if (event.paymentMethod === "BOTH" && (bothPaymentMethod === "" || typeof (bothPaymentMethod) === "undefined")) {
            setFailedValidation("bothPaymentMethod", "Please select how you would like to pay.")
        }

        if (purchase.email === "" || typeof (purchase.email) === "undefined") {
            setFailedValidation("email", "Please enter your email address.")
        } else {
            if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(purchase.email)) {
            // if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(purchase.purchasederId)) {
            } else {
                setFailedValidation("email", "Invalid email address.")
            }
        }


        if (purchase.phone === "" || typeof (purchase.phone) === "undefined") {
            setFailedValidation("phone", "Please enter your phone number.")
        } else {

            let phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
            let number;

            try {
                if (purchase.phone.substring(0, 3) === "+44") {
                    number = phoneUtil.parseAndKeepRawInput(purchase.phone);
                } else if (purchase.phone.substring(0, 4) === "0044") {
                    var updatedNumber = `+44 ${purchase.phone.substring(4, purchase.phone.length)}`
                    number = phoneUtil.parseAndKeepRawInput(updatedNumber);
                } else {
                    number = phoneUtil.parseAndKeepRawInput(`+44 ${purchase.phone}`);
                }

                if (!phoneUtil.isValidNumber(number)) {
                    setFailedValidation("phone", "Invalid UK phone number")
                }

            }
            catch (err) {
                setFailedValidation("phone", "Invalid UK phone number")
            }
        }

        customPurchaseDetails.forEach(element => {
            if (element.isRequired && (element.value === "" || typeof (element.value) === "undefined")) {
                setFailedValidation(element._id, "Please complete this field.")
            }
        })
        
        console.log(tempValidationObject)
        setValidationObject(tempValidationObject)
        setIsValidationPassed(tempIsValidationPassed)
        return tempIsValidationPassed
    }

    const getValidation = (name: string): IValidation => {
        let returnValue: IValidation = {} as IValidation
        validationObject.forEach(element => {
            if (name === element.name) {
                returnValue = element
            }
        });
        return returnValue
    }


    return {
        validationObject,
        setValidationObject,
        isValidationPassed,
        setIsValidationPassed,
        validateInputs,
        getValidation,
        generateInitialValidationObject
    }

}

export default usePurchaseValidation