import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import Error from '../../components/Error/Error'
import EventMenuBar from '../../components/EventMenuBar/EventMenuBar'
import EventNotFound from '../../components/EventNotFound/EventNotFound'
import Loading from '../../components/Loading/Loading'
import useFetch from '../../hooks/useFetch'
import IEvent from '../../interfaces/event'
import IPageProps from '../../interfaces/page'
import { Button } from '@mui/material'
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom'
import './event.scss'
import EventFooter from '../../components/EventFooter/EventFooter'

const EventPage: React.FunctionComponent<IPageProps & RouteComponentProps<any>> = (props) => {

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>("")
    const [event, setEvent] = useState<IEvent>({} as IEvent)

    const eventApi = useFetch("events")

    const getEvent = (): void => {
        setLoading(true)
        eventApi.get(`/getEventById/${props.match.params.eventId}/false`).then((event: IEvent) => {
            setEvent(event)
            setLoading(false)
        }).catch((err: Error) => {
            setError(err.message)
            setLoading(false)
        })
    }

    useEffect(() => {
        getEvent()

        // ******************POPULATE TEST DATA**************************
        // let index = 0
        // let timerId = setInterval(() => {
        //     populateTestData(index)
        //     index++
        // }, 1000);

        // setTimeout(() => { clearInterval(timerId); alert('stop'); }, 60000);
        // ******************************************************************

        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    if (loading) {
        return (
            <div className="event-page">
                <EventMenuBar event={event} />
                <Loading />
            </div>
        )
    }

    if (error) {
        return (
            <div className="event-page">
                <EventMenuBar event={event} />
                <Error buttonAction={() => window.location.reload()} error={error} eventId={props.match.params.eventId} />
                {/* <AuctionFooter appConfig={event.appConfig} isFixedBidSection={false} /> */}
            </div>
        )
    }

    if (!event.eventId) {
        return (
            <EventNotFound />
        )
    }


    return (
        <div className="event-page">
            <EventMenuBar event={event} />
            <div className="header-section" style={
                event.bannerUrl ?
                    { backgroundColor: event.headerBackgroundColor, padding: "24px 24px 150px 24px" } :
                    { backgroundColor: event.headerBackgroundColor, padding: "24px" }
            }>
                <img alt="event-logo" src={event.logoUrl} />
                <h1 style={{ color: event.headerFontColor }}>
                    {event.title}
                </h1>
                <h2 style={{ color: event.headerFontColor }}>
                    {event.subtitle}
                </h2>
                <div className="event-buttons-wrapper">
                    <Link to={`/${event.eventId}/tickets`}>
                        <Button
                            className="lh-button border-pop-animation"
                            style={{ color: event.buttonFontColor, '--background-color': event.buttonBackgroundColor } as React.CSSProperties}
                        >
                            Buy Tickets
                        </Button>
                    </Link>
                    <Link to={`/${event.eventId}/prizes`}>
                        <Button
                            className="lh-button border-pop-animation"
                            style={{ color: event.buttonFontColor, '--background-color': event.buttonBackgroundColor } as React.CSSProperties}
                        >
                            View the Prizes
                        </Button>
                    </Link>
                </div>
            </div>
            {event.bannerUrl &&
                    // <div className="banner-wrapper" style={{ backgroundImage: `url(${event.bannerUrl})` }}>
                    //     {event.bannerText && <span className="banner-text">{event.bannerText}</span>}
                    // </div>
                    <div className='banner-wrapper'>
                        <img alt="banner" className='banner-image' src={event.bannerUrl} />
                    </div>
                }
            <div className="content">
                {/* {event.bannerUrl &&
                    // <div className="banner-wrapper" style={{ backgroundImage: `url(${event.bannerUrl})` }}>
                    //     {event.bannerText && <span className="banner-text">{event.bannerText}</span>}
                    // </div>
                    <img alt="banner-image" className='banner-image' src={event.bannerUrl} />
                } */}
                {event.introHtml &&
                    <div className="intro-text-wrapper">
                        <div dangerouslySetInnerHTML={{ __html: event.introHtml }}></div>
                    </div>
                }

                {event.videoUrl &&
                    <div className="youtube-wrapper">
                        <ReactPlayer width="100%" height="100%" url={`https://www.youtube.com/embed/${event.videoUrl}`} playing={false} />
                    </div>
                }

                {event.descriptionHtml &&
                    <div className="description-text-wrapper">
                        <div dangerouslySetInnerHTML={{ __html: event.descriptionHtml }}></div>
                    </div>
                }
            </div>
            <EventFooter event={event} />
        </div>
    )
}

export default EventPage
