import React, { useState, useEffect } from "react"
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment } from "@mui/material"
import useMediaValidation from "./useMediaValidation";
import IMedia from "../../../interfaces/media";
import IPrize from "../../../interfaces/prize";
import { v4 as uuidv4 } from 'uuid';
import CloudinaryUploadWidget from "../../CloudinaryUploadWidget/CloudinaryUploadWidget";

interface InputProps {
    isDialogOpen: boolean,
    handleClose: () => void,
    setPrize: (prize: IPrize) => void,
    media: IMedia,
    prize: IPrize
}

const MediaDialog = (props: InputProps) => {

    const [media, setMedia] = useState<IMedia>({ ...props.media });

    const mediaValidation = useMediaValidation(media)

    const handleChange = (event: any) => {
        const { name, value } = event.target
        setMedia({ ...media, [name]: value })
    }

    const saveMedia = () => {
        if (mediaValidation.validateInputs()) {
            let tempMediaObject: IMedia[] = props.prize.media && props.prize.media.length > 0 ? [...props.prize.media] : []

            if (props.media._id) {
                tempMediaObject.forEach((element, index) => {
                    if (media._id === element._id) {
                        tempMediaObject[index] = media
                    }
                })

                props.setPrize({ ...props.prize, media: tempMediaObject })
                setMedia({} as IMedia)
                props.handleClose()

            } else {
                tempMediaObject.push({ ...media, mediaId: uuidv4() } as IMedia)
                props.setPrize({ ...props.prize, media: tempMediaObject })
                setMedia({} as IMedia)
                props.handleClose()
            }
        }

    }

    const addImagesToMediaObject = (imageUrlList: string[]) => {
        if (!props.prize.media){
            props.prize.media = []
        }

        imageUrlList.forEach(imageUrl => {
            props.prize.media.push({
                mediaId: uuidv4(),
                src: imageUrl,
                thumb: "",
                mediaType: "IMAGE",
                title: ""
            })
        });
        props.handleClose()
    }

    useEffect(() => {
        setMedia({ ...props.media, mediaId: uuidv4() } as IMedia)

        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [props.media]);

    return (
        <>
            <Dialog
                open={props.isDialogOpen}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Media</DialogTitle>
                <DialogContent>
                    <TextField
                        variant="outlined"
                        id="src"
                        name="src"
                        label="Source"
                        type="text"
                        style={{ marginTop: 6 }}
                        fullWidth
                        value={media.src}
                        onChange={handleChange}
                        required
                        helperText={!mediaValidation.getValidation("src").isValid ?
                            mediaValidation.getValidation("src").validationMessage :
                            props.media.mediaType === "YOUTUBE" ? 'Enter a full Youtube URL' : ""
                        }
                        error={!mediaValidation.getValidation("src").isValid}
                        InputProps={props.media.mediaType === "IMAGE" ? {
                            endAdornment:
                                <InputAdornment position="start">
                                    <CloudinaryUploadWidget
                                        folderName={`${props.prize.eventId ? props.prize.eventId : "new"}/${props.prize.title ? props.prize.title : "new"}`}
                                        allowMultipleUploads={true}
                                        functionOnComplete={addImagesToMediaObject}
                                        uploadPreset="lotto_prize_preset"
                                    />
                                </InputAdornment>
                        } : undefined}
                    />

                    {/* <FormControl
                        variant="outlined"
                        fullWidth
                        required
                        error={!mediaValidation.getValidation("mediaType").isValid}
                    >
                        <InputLabel id="type-label">Media Type</InputLabel>
                        <Select
                            labelId="type-label"
                            id="mediaType"
                            name="mediaType"
                            value={media.mediaType}
                            onChange={handleChange}
                            label="Media Type"
                        >
                            <MenuItem value={'IMAGE'}>Image</MenuItem>
                            <MenuItem value={'YOUTUBE'}>Youtube</MenuItem>
                        </Select>
                        {!mediaValidation.getValidation("mediaType").isValid && <FormHelperText>{mediaValidation.getValidation("mediaType").validationMessage} </FormHelperText>}
                    </FormControl> */}

                </DialogContent>
                <DialogActions>
                    <div className="save-button-wrapper">
                        {!mediaValidation.isValidationPassed && <span className="validation-text">Errors highlighted in form - please resolve before saving.</span>}
                        <div className="buttons">
                            <Button id="cancel-button" onClick={props.handleClose} >
                                Cancel
                            </Button>
                            <Button id="save-button" onClick={saveMedia} className="lh-button">
                                Save
                            </Button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default MediaDialog