import firebase from 'firebase/compat'
import 'firebase/auth'
import 'firebase/firestore'
// import config from '../config/config'

// const Firebase = firebase.initializeApp({
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: 'lighthouse-lotto.firebaseapp.com',
//     projectId: 'lighthouse-lotto',
//     storageBucket: 'lighthouse-lotto.appspot.com',
//     messagingSenderId: '1040711487372',
//     appId: '1:1040711487372:web:6cd481d539a100c78850a9',
//     measurementId: 'G-19D1RCY5RD'
// })
const Firebase = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    // apiKey: 'AIzaSyDbO7hMeIYQyaoMhHNpZ7ZuPeHLwhy1HiI',
    authDomain: 'lighthouse-lotto-4cbe7.firebaseapp.com',
    projectId: 'lighthouse-lotto-4cbe7',
    storageBucket: 'lighthouse-lotto-4cbe7.appspot.com',
    messagingSenderId: '410823093006',
    appId: '1:410823093006:web:278365100ef7759f8c455f',
    measurementId: 'G-HFHM6NS1B1'
})

export const Providers = {
    google: new firebase.auth.GoogleAuthProvider()
}

export const auth = firebase.auth()
export default Firebase
