import { useState } from "react";
import ITicketPricingBundle from "../../../interfaces/ticketPricingBundle";
import { IValidation } from "../../../interfaces/validation";

const useTicketPricingBundleValidation = (ticketPricingBundle: ITicketPricingBundle) => {
    const [validationObject, setValidationObject] = useState<IValidation[]>([
        { name: "multiple", isValid: true, validationMessage: "" },
        { name: "price", isValid: true, validationMessage: "" }
    ])
    const [isValidationPassed, setIsValidationPassed] = useState<boolean>(true)

    const validateInputs = (): boolean => {

        let tempValidationObject: IValidation[] = [
            { name: "multiple", isValid: true, validationMessage: "" },
            { name: "price", isValid: true, validationMessage: "" }
        ]

        let tempIsValidationPassed = true

        const setFailedValidation = (name: string, message: string) => {
            tempIsValidationPassed = false
            tempValidationObject.forEach(element => {
                if (element.name === name) {
                    element.isValid = false
                    element.validationMessage = message
                }
            });
        }

        if (ticketPricingBundle.multiple === 0 || typeof (ticketPricingBundle.multiple) === "undefined") {
            setFailedValidation("multiple", "Please enter a multiple for the bundle.")
        }

        if (ticketPricingBundle.price === 0 || typeof (ticketPricingBundle.price) === "undefined") {
            setFailedValidation("price", "Please enter a price for the bundle.")
        }

        setValidationObject(tempValidationObject)
        setIsValidationPassed(tempIsValidationPassed)
        return tempIsValidationPassed
    }

    const getValidation = (name: string): IValidation => {
        let returnValue: IValidation = {} as IValidation
        validationObject.forEach(element => {
            if (name === element.name) {
                returnValue = element
            }
        });
        return returnValue
    }

    return {
        validationObject,
        setValidationObject,
        isValidationPassed,
        setIsValidationPassed,
        validateInputs,
        getValidation
    }
}

export default useTicketPricingBundleValidation