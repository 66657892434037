import { Card } from '@mui/material'
import IEvent from '../../interfaces/event'
import IWinningTicket from '../../interfaces/winningTicket'
import './winnerCard.scss'

interface IWinnerObject {
    ticket: IWinningTicket
    isShowWinnerCard: boolean
    isShowWinnerName: boolean
}

interface InputProps {
    winnerCard: IWinnerObject
    prizeNumber: number
    event: IEvent
}

const WinnerCard = (props: InputProps) => {

    const ordinal_suffix_of = (i: number): string => {
        var j = i % 10,
            k = i % 100;
        if (j === 1 && k !== 11) {
            return i + "st";
        }
        if (j === 2 && k !== 12) {
            return i + "nd";
        }
        if (j === 3 && k !== 13) {
            return i + "rd";
        }
        return i + "th";
    }

    const isTableNumber = (): boolean => {
        let isTableNumberFound = false
        props.winnerCard.ticket.ticket.purchase.customPurchaseDetails.forEach(customPurchaseDetail => {
            if (customPurchaseDetail.type === "TABLE") {
                isTableNumberFound = true
            }
        })

        return isTableNumberFound
    }

    const getTableNumber = (): string => {
        let tableNumber = ""
        props.winnerCard.ticket.ticket.purchase.customPurchaseDetails.forEach(customPurchaseDetail => {
            if (customPurchaseDetail.type === "TABLE") {
                tableNumber = customPurchaseDetail.value
            }
        })

        return tableNumber
    }

    return (
        <Card className="winner-card" >
            <Card className="prize-number-wrapper">{`${ordinal_suffix_of(props.prizeNumber)} Prize`}</Card>
            <div className="prize-details-wrapper">

                <table>
                    <tbody>
                        <tr>
                            <td className="label">
                                <span>Ticket Number:</span>
                            </td>
                            <td className="value">
                                <span>{`${props.event.eventPrefix}_${props.winnerCard.ticket.ticket.ticketNumber}`}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="label">
                                <span>Owner:</span>
                            </td>
                            <td className="value" style={{ display: props.winnerCard.isShowWinnerName ? "table-cell" : "none" }}>
                                <span>{props.winnerCard.ticket.ticket.purchase.name}</span>
                                {isTableNumber() && <span> (Table: {getTableNumber()})</span>}
                            </td>
                        </tr>
                    </tbody>
                </table>


                {/* <div>{`Ticket Number: ${props.winningTicket.ticketNumber}`}</div>
                <div>{`Owner: ${props.winningTicket.purchase.name}`}</div> */}
            </div>
        </Card>
    )
}

export default WinnerCard