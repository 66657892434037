import React from 'react'
import AdminFooter from '../components/AdminFooter/AdminFooter'

import AdminMenuBar from '../components/AdminMenuBar/AdminMenuBar'

import "./layoutAuthenticatedNoMenu.scss"

interface InputProps {
    children?: any
    auth?: any
    authorisedUserProfile?: any
    title?: string
}

const LayoutAuthenticatedNoMenu = (props: InputProps) => {

    return (
        <div className="layout-authenticated-no-menu">
            <AdminMenuBar/>
            <div className="root">
                <main>
                    <div className="full-height-content">
                        {props.children}
                    </div>
                    <AdminFooter />
                </main>

            </div>
        </div>
    )
}

export default LayoutAuthenticatedNoMenu