import IRoute from '../interfaces/route'
import EventListPage from '../pages/admin/eventList'
import EventPage from '../pages/public/event'
import LoginPage from '../pages/admin/login'
import EventSettingsPage from '../pages/admin/eventSettings'
import PrizeListPage from '../pages/admin/prizeList'
import PrizeSettingsPage from '../pages/admin/prizeSettings'
import PurchaseReportPage from '../pages/admin/purchaseReport'
import WinnerReportPage from '../pages/admin/winnerReport'
import WinnerGeneratorPage from '../pages/admin/winnerGenerator'
import TicketsPage from '../pages/public/tickets'
import PrizesPage from '../pages/public/prizes'
import HomePage from '../pages/public/home'
import PrizeDetailsPage from '../pages/public/prizeDetails'
import TermsAndConditionsPage from '../pages/public/termsAndConditions'
import EventLogsPage from '../pages/admin/eventLogs'

const adminRoutes: IRoute[] = [
    {
        path: '/admin',
        exact: true,
        auth: false,
        component: LoginPage,
        title: 'Login',
        layout: 'anonymous'
    },
    {
        path: '/admin/events',
        exact: true,
        auth: true,
        component: EventListPage,
        title: 'Event List',
        layout: 'authenticatedNoMenu'
    },
    {
        path: '/admin/events/:eventId',
        exact: true,
        auth: true,
        component: EventSettingsPage,
        title: 'Event Settings',
        layout: 'authenticated'
    },
    {
        path: '/admin/events/:eventId/prizes',
        exact: true,
        auth: true,
        component: PrizeListPage,
        title: 'Prize List',
        layout: 'authenticated'
    },
    {
        path: '/admin/events/:eventId/prizes/:prizeId',
        exact: true,
        auth: true,
        component: PrizeSettingsPage,
        title: 'Prize Settings',
        layout: 'authenticated'
    },
    {
        path: '/admin/events/:eventId/purchases',
        exact: true,
        auth: true,
        component: PurchaseReportPage,
        title: 'Purchase Report',
        layout: 'authenticated'
    },
    {
        path: '/admin/events/:eventId/winners',
        exact: true,
        auth: true,
        component: WinnerReportPage,
        title: 'Winner Report',
        layout: 'authenticated'
    },
    {
        path: '/admin/events/:eventId/winner-generator',
        exact: true,
        auth: true,
        component: WinnerGeneratorPage,
        title: 'Winner Generator',
        layout: 'anonymous'
    },
    {
        path: '/admin/events/:eventId/logs',
        exact: true,
        auth: true,
        component: EventLogsPage,
        title: 'Event Logs',
        layout: 'authenticated'
    }
]

const publicRoutes: IRoute[] = [
    {
        path: '/',
        exact: true,
        auth: false,
        component: HomePage,
        title: 'Home',
        layout: 'anonymous'
    },

    {
        path: '/:eventId',
        exact: true,
        auth: false,
        component: EventPage,
        title: 'Event Page',
        layout: 'anonymous'
    },
    {
        path: '/:eventId/tickets',
        exact: true,
        auth: false,
        component: TicketsPage,
        title: 'Tickets',
        layout: 'anonymous'
    },
    {
        path: '/:eventId/prizes',
        exact: true,
        auth: false,
        component: PrizesPage,
        title: 'Prizes',
        layout: 'anonymous'
    },
    {
        path: '/:eventId/prizes/:prizeId',
        exact: true,
        auth: false,
        component: PrizeDetailsPage,
        title: 'Prize Details',
        layout: 'anonymous'
    },
    {
        path: '/:eventId/terms-and-conditions',
        exact: true,
        auth: false,
        component: TermsAndConditionsPage,
        title: 'Terms and Conditions',
        layout: 'anonymous'
    }
]

const routes: IRoute[] = [...adminRoutes, ...publicRoutes]

export default routes
