import React from 'react'
import "./prizeCard.scss"
import { Button, Card, CardActionArea, CardContent, CardMedia, } from '@mui/material'
import { Link } from 'react-router-dom'
import PlaceHolderImage from '../../assets/placeholder_image.png'
import IEvent from '../../interfaces/event';
import IPrize from '../../interfaces/prize'

interface InputProps {
    prize: IPrize
    event: IEvent
}

const PrizeCard = (props: InputProps) => {

    const ordinal_suffix_of = (i: number): string => {
        var j = i % 10,
            k = i % 100;
        if (j === 1 && k !== 11) {
            return i + "st";
        }
        if (j === 2 && k !== 12) {
            return i + "nd";
        }
        if (j === 3 && k !== 13) {
            return i + "rd";
        }
        return i + "th";
    }

    return (
            <Card className="prize-card" style={{ "--header-background-color": props.event.headerBackgroundColor } as React.CSSProperties} >
                <CardActionArea >
                    <Link to={`/${props.event.eventId}/prizes/${props.prize._id}`}>
                        <div className="prize-number">
                            {ordinal_suffix_of(props.prize.orderNumber)}
                            <span> Prize</span>
                        </div>
                        <CardMedia
                            component="img"
                            alt={props.prize.title}
                            image={props.prize.media[0] && props.prize.media[0].src ? props.prize.media[0].src : PlaceHolderImage}
                            title={props.prize.title}
                        />
                        <CardContent>
                            <div className="title-wrapper">
                                <span className="title">{props.prize.title}</span>
                                {props.prize.subtitle && <span className="sub-title">{props.prize.subtitle}</span>}
                            </div>

                        </CardContent>
                        <div className="view-button-wrapper">
                            <Button className=" lh-button confirm">
                                More Detail
                            </Button>
                        </div>
                    </Link>
                </CardActionArea>
            </Card>
    )
}

export default PrizeCard