import React, { useEffect, useState } from "react"
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material"
import '../dialog.scss'
import IEvent from "../../../interfaces/event";
import { v4 as uuidv4 } from 'uuid';
import IEventOrganiser from "../../../interfaces/eventOrganiser";
import useEventOrganiserValidation from "./useEventOrganiserValidation";

interface InputProps {
    isDialogOpen: boolean,
    handleClose: () => void,
    setEvent: (event: IEvent) => void,
    eventOrganiser: IEventOrganiser,
    event: IEvent
    eventValidation: any
}

const EventOrganiserDialog = (props: InputProps) => {

    const [eventOrganiser, setEventOrganiser] = useState<IEventOrganiser>({ ...props.eventOrganiser });
    const validationHook = useEventOrganiserValidation(eventOrganiser)

    const handleChange = (event: any) => {
        const { name, value } = event.target
        setEventOrganiser({ ...eventOrganiser, [name]: value })  
    }


    const saveEventOrganiser = () => {
        if (validationHook.validateInputs()) {
            let tempEventOrganiserObject: IEventOrganiser[] = []

            if (props.event.eventOrganiserList && props.event.eventOrganiserList.length > 0) {
                tempEventOrganiserObject = [...props.event.eventOrganiserList]
            }

            if (props.eventOrganiser._id) {
                tempEventOrganiserObject.forEach((element, index) => {
                    if (eventOrganiser._id === element._id) {
                        tempEventOrganiserObject[index] = {...eventOrganiser}
                    }
                })

                props.setEvent({ ...props.event, eventOrganiserList: tempEventOrganiserObject })
                setEventOrganiser({} as IEventOrganiser)
                props.handleClose()

            } else {
                tempEventOrganiserObject.push({...eventOrganiser, _id: uuidv4()})
                props.setEvent({ ...props.event, eventOrganiserList: tempEventOrganiserObject })
                setEventOrganiser({} as IEventOrganiser)
                props.handleClose()
            }
        }
    }

    useEffect(() => {
        if (props.eventOrganiser._id) {
            setEventOrganiser({ ...props.eventOrganiser })
        } else {
            setEventOrganiser({} as IEventOrganiser)
        }

        validationHook.generateInitialValidationObject()
        props.eventValidation.generateInitialValidationObject()

        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [props.eventOrganiser]);

    return (
        <>
            <Dialog
                open={props.isDialogOpen}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Event Organiser</DialogTitle>
                <DialogContent>
                 
                    <TextField
                        variant="outlined"
                        id="firstName"
                        name="firstName"
                        label="First Name"
                        type="text"
                        fullWidth
                        value={eventOrganiser.firstName}
                        onChange={handleChange}
                        required
                        helperText={!validationHook.getValidation("firstName").isValid && validationHook.getValidation("firstName").validationMessage}
                        error={!validationHook.getValidation("firstName").isValid}
                    />
                    <TextField
                        variant="outlined"
                        id="surname"
                        name="surname"
                        label="Surname"
                        type="text"
                        fullWidth
                        value={eventOrganiser.surname}
                        onChange={handleChange}
                        required
                        helperText={!validationHook.getValidation("surname").isValid && validationHook.getValidation("surname").validationMessage}
                        error={!validationHook.getValidation("surname").isValid}
                    />
                    <TextField
                        variant="outlined"
                        id="email"
                        name="email"
                        label="Email"
                        type="text"
                        fullWidth
                        value={eventOrganiser.email}
                        onChange={handleChange}
                        required
                        helperText={!validationHook.getValidation("email").isValid && validationHook.getValidation("email").validationMessage}
                        error={!validationHook.getValidation("email").isValid}
                    />
                    <TextField
                        variant="outlined"
                        id="phone"
                        name="phone"
                        label="Phone"
                        type="text"
                        fullWidth
                        value={eventOrganiser.phone}
                        onChange={handleChange}
                        required
                        helperText={!validationHook.getValidation("phone").isValid && validationHook.getValidation("phone").validationMessage}
                        error={!validationHook.getValidation("phone").isValid}
                    />
                                   

                </DialogContent>
                <DialogActions>
                    <div className="save-button-wrapper">
                        {!validationHook.isValidationPassed && <span className="validation-text">Errors highlighted in form - please resolve before saving.</span>}
                        <div className="buttons">
                            <Button id="cancel-button" onClick={props.handleClose}>
                                Cancel
                            </Button>
                            <Button id="save-button" 
                            onClick={saveEventOrganiser}
                             className="lh-button">
                                Save
                            </Button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default EventOrganiserDialog