import { useState } from "react";
import IEvent from "../../interfaces/event";
import { IValidation } from "../../interfaces/validation";

const useEventValidation = (otherEventList: IEvent[]) => {
    const [validationObject, setValidationObject] = useState<IValidation[]>([
        { name: "name", isValid: true, validationMessage: "" },
        { name: "eventId", isValid: true, validationMessage: "" },
        { name: "eventPrefix", isValid: true, validationMessage: "" },
        { name: "eventOrganiserList", isValid: true, validationMessage: "" }
    ])
    const [isValidationPassed, setIsValidationPassed] = useState<boolean>(true)

    const generateInitialValidationObject = (): void => {

        let tempValidationObject: IValidation[] = [
            { name: "name", isValid: true, validationMessage: "" },
            { name: "eventId", isValid: true, validationMessage: "" },
            { name: "eventPrefix", isValid: true, validationMessage: "" },
            { name: "eventOrganiserList", isValid: true, validationMessage: "" }
        ]

        setValidationObject(tempValidationObject)
        setIsValidationPassed(true)
    }

    const validateInputs = (event: IEvent): boolean => {

        let tempValidationObject: IValidation[] = [
            { name: "name", isValid: true, validationMessage: "" },
            { name: "eventId", isValid: true, validationMessage: "" },
            { name: "eventPrefix", isValid: true, validationMessage: "" },
            { name: "eventOrganiserList", isValid: true, validationMessage: "" }
        ]

        let tempIsValidationPassed = true

        const setFailedValidation = (name, message) => {
            tempIsValidationPassed = false
            tempValidationObject.forEach(element => {
                if (element.name === name) {
                    element.isValid = false
                    element.validationMessage = message
                }
            });
        }

        if (event.eventId === "" || typeof (event.eventId) === "undefined") {
            setFailedValidation("eventId", "Please enter a URL-Suffix")
        } else { 
            otherEventList.forEach(element => {
                if (event.eventId === element.eventId) {
                    setFailedValidation("eventId", "URL-Suffix must be unique - please try something different.")
                }
            });
        }

        if (event.name === "" || typeof (event.name) === "undefined") {
            setFailedValidation("name", "Please give the event a name.")
        }
        if (event.eventPrefix === "" || typeof (event.eventPrefix) === "undefined") {
            setFailedValidation("eventPrefix", "Please provide an event prefix - this will be used as a prefix to all tickets.")
        }

        if (event.winnerGenerationMode === "AUTOMATIC" && ((event.eventOrganiserList && event.eventOrganiserList.length === 0) || typeof (event.eventOrganiserList) === "undefined")) {
            setFailedValidation("eventOrganiserList", "You must define at least 1 event organiser if the 'Winner Generation Mode' is set to 'Automatic'")
        }

        // console.log(tempValidationObject)

        setValidationObject(tempValidationObject)
        setIsValidationPassed(tempIsValidationPassed)
        return tempIsValidationPassed
    }


    return {
        validationObject,
        setValidationObject,
        isValidationPassed,
        setIsValidationPassed,
        validateInputs,
        generateInitialValidationObject
    }

}

export default useEventValidation