import { Accordion, AccordionDetails, AccordionSummary, Button } from "@mui/material"
import React from "react"
import './error.scss'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

interface InputProps {
    error: string
    buttonAction: () => void;
    eventId: string
}

const Error = (props: InputProps) => {

    return (
        <div className="error-wrapper content">
            <h2>Houston we have a problem...</h2>
            <p>There seems to be an issue, please ensure your device is connected to the internet and click the button below to try and reload the page.</p>
            <Accordion >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    View Error Details
                </AccordionSummary>
                <AccordionDetails>
                    {props.error}
                </AccordionDetails>
            </Accordion>
            <Button className="lh-button" onClick={() => props.buttonAction()}>Reload Page</Button>
            <p>If the error persists please notify the auction organiser.</p>
        </div>
    )
}

export default Error