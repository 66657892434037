import { useState } from "react";
import ICustomPurchaseDetail from "../../../interfaces/customPurchaseDetail";
import { IValidation } from "../../../interfaces/validation";

const useCustomPurchaseDetailValidation = (customPurchaseDetail: ICustomPurchaseDetail, optionListCsv: string) => {
    const [validationObject, setValidationObject] = useState<IValidation[]>([
        { name: "label", isValid: true, validationMessage: "" },
        { name: "isRequired", isValid: true, validationMessage: "" },
        { name: "type", isValid: true, validationMessage: "" },
        // { name: "optionListCsv", isValid: true, validationMessage: "" }
    ])
    const [isValidationPassed, setIsValidationPassed] = useState<boolean>(true)

    const validateInputs = (): boolean => {

        let tempValidationObject: IValidation[] = [
            { name: "label", isValid: true, validationMessage: "" },
            { name: "isRequired", isValid: true, validationMessage: "" },
            { name: "type", isValid: true, validationMessage: "" },
            // { name: "optionListCsv", isValid: true, validationMessage: "" }
        ]

        let tempIsValidationPassed = true

        const setFailedValidation = (name: string, message: string) => {
            tempIsValidationPassed = false
            tempValidationObject.forEach(element => {
                if (element.name === name) {
                    element.isValid = false
                    element.validationMessage = message
                }
            });
        }

        if (customPurchaseDetail.label === "" || typeof (customPurchaseDetail.label) === "undefined") {
            setFailedValidation("label", "Please enter a label for the custom field.")
        }

        if (typeof (customPurchaseDetail.isRequired) === "undefined") {
            setFailedValidation("isRequired", "Please select if the field is required.")
        }

        if (customPurchaseDetail.type === "" || typeof (customPurchaseDetail.type) === "undefined") {
            setFailedValidation("type", "Please select the type of the custom field.")
        }

        // if ((customPurchaseDetail.type === "SELECT" || customPurchaseDetail.type === "TABLE") && (optionListCsv === "" || typeof (optionListCsv) === "undefined")) {
        //     setFailedValidation("optionListCsv", "Please enter the options for the dropdownlist (separated with commas)")
        // }

        setValidationObject(tempValidationObject)
        setIsValidationPassed(tempIsValidationPassed)
        return tempIsValidationPassed
    }

    const getValidation = (name: string): IValidation => {
        let returnValue: IValidation = {} as IValidation
        validationObject.forEach(element => {
            if (name === element.name) {
                returnValue = element
            }
        });
        return returnValue
    }

    return {
        validationObject,
        setValidationObject,
        isValidationPassed,
        setIsValidationPassed,
        validateInputs,
        getValidation
    }
}

export default useCustomPurchaseDetailValidation