import React, { useState } from 'react'
import {  Paper, TextField } from '@mui/material'
import { BlockPicker } from 'react-color'
import IEvent from '../../interfaces/event'

interface InputProps {
    event: IEvent
    setEvent: (event: IEvent) => void
}

const EventThemeSection: React.FunctionComponent<InputProps> = (props) => {

    const [isDisplayMenuBarBackgroundPicker, setIsDisplayMenuBarBackgroundPicker] = useState<boolean>(false)
    const [isDisplayMenuBarFontPicker, setIsDisplayMenuBarFontPicker] = useState<boolean>(false)
    const [isDisplayFooterBackgroundPicker, setIsDisplayFooterBackgroundPicker] = useState<boolean>(false)
    const [isDisplayFooterFontPicker, setIsDisplayFooterFontPicker] = useState<boolean>(false)
    const [isDisplayHeaderBackgroundPicker, setIsDisplayHeaderBackgroundPicker] = useState<boolean>(false)
    const [isDisplayHeaderFontPicker, setIsDisplayHeaderFontPicker] = useState<boolean>(false)
    const [isDisplayButtonBackgroundPicker, setIsDisplayButtonBackgroundPicker] = useState<boolean>(false)
    const [isDisplayButtonFontPicker, setIsDisplayButtonFontPicker] = useState<boolean>(false)

    const handleClick = (identifier: string) => {
        if (identifier === "menuBarBackgroundColor") {
            setIsDisplayMenuBarBackgroundPicker(!isDisplayMenuBarBackgroundPicker)
        } else if (identifier === "menuBarFontColor") {
            setIsDisplayMenuBarFontPicker(!isDisplayMenuBarFontPicker)
        } else if (identifier === "footerBackgroundColor") {
            setIsDisplayFooterBackgroundPicker(!isDisplayFooterBackgroundPicker)
        } else if (identifier === "footerFontColor") {
            setIsDisplayFooterFontPicker(!isDisplayFooterFontPicker)
        } else if (identifier === "headerBackgroundColor") {
            setIsDisplayHeaderBackgroundPicker(!isDisplayHeaderBackgroundPicker)
        } else if (identifier === "headerFontColor") {
            setIsDisplayHeaderFontPicker(!isDisplayHeaderFontPicker)
        } else if (identifier === "buttonBackgroundColor") {
            setIsDisplayButtonBackgroundPicker(!isDisplayButtonBackgroundPicker)
        } else if (identifier === "buttonFontColor") {
            setIsDisplayButtonFontPicker(!isDisplayButtonFontPicker)
        }
    }

    const handleClose = (identifier: string) => {
        if (identifier === "menuBarBackgroundColor") {
            setIsDisplayMenuBarBackgroundPicker(false)
        } else if (identifier === "menuBarFontColor") {
            setIsDisplayMenuBarFontPicker(false)
        } else if (identifier === "footerBackgroundColor") {
            setIsDisplayFooterBackgroundPicker(false)
        } else if (identifier === "footerFontColor") {
            setIsDisplayFooterFontPicker(false)
        } else if (identifier === "headerBackgroundColor") {
            setIsDisplayHeaderBackgroundPicker(false)
        } else if (identifier === "headerFontColor") {
            setIsDisplayHeaderFontPicker(false)
        } else if (identifier === "buttonBackgroundColor") {
            setIsDisplayButtonBackgroundPicker(false)
        } else if (identifier === "buttonFontColor") {
            setIsDisplayButtonFontPicker(false)
        }
    }

    const handleColorChange = (name: string, color: any) => {
        props.setEvent({ ...props.event, [name]: color.hex })
    }

    return (
        <section className="event-theme">
            <h2 className="subtitle">Event Theme.</h2>
            {/* <span className="description">This is the company that is hosting the auction. Eg Company x may be hosting an auction on behalf of company y.</span> */}
            <Paper className="input-wrapper">
                
                {/* *****************TODO COME BACK TO IMPLEMENTING THIS! *******************************/}
                {/* <ColorPickerInput
                    label="Header Background Colour"
                    helperText="This will set the background colour of the header serction."
                    id="headerBackgroundColor"
                    color={props.event.headerBackgroundColor}
                    isDisplay={isDisplayHeaderBackgroundPicker}
                    handleClick={() => handleClick("headerBackgroundColor")}
                    handleClose={() => handleClose("headerBackgroundColor")}
                    handleColorChange={(color) => handleColorChange("headerBackgroundColor", color)}
                /> */}
                <div className="color-wrapper field-wrapper">
                    <TextField
                        id="headerBackgroundColor"
                        name="headerBackgroundColor"
                        className="color-picker"
                        label="Header Background Colour"
                        variant="outlined"
                        value={props.event.headerBackgroundColor || ""}
                        helperText="This will set the background colour of the header serction."
                        onClick={() => handleClick("headerBackgroundColor")}
                    />
                    {isDisplayHeaderBackgroundPicker ?
                        <div style={{ position: 'absolute', zIndex: 2 }}>
                            <div
                                style={{
                                    position: 'fixed',
                                    top: '0px',
                                    right: '0px',
                                    bottom: '0px',
                                    left: '0px',
                                }}
                                onClick={() => handleClose("headerBackgroundColor")}
                            />
                            <BlockPicker
                                color={props.event.headerBackgroundColor}
                                onChangeComplete={(color: any) => { handleColorChange("headerBackgroundColor", color) }} />
                        </div>
                        : null}
                </div>
                <div className="color-wrapper field-wrapper">
                    <TextField
                        id="headerFontColor"
                        name="headerFontColor"
                        className="color-picker"
                        label="Header Font Colour"
                        variant="outlined"
                        value={props.event.headerFontColor || ""}
                        helperText="This will set the font colour of the header serction."
                        onClick={() => handleClick("headerFontColor")}
                    />
                    {isDisplayHeaderFontPicker ?
                        <div style={{ position: 'absolute', zIndex: 2 }}>
                            <div
                                style={{
                                    position: 'fixed',
                                    top: '0px',
                                    right: '0px',
                                    bottom: '0px',
                                    left: '0px',
                                }}
                                onClick={() => handleClose("headerFontColor")}
                            />
                            <BlockPicker
                                color={props.event.headerFontColor}
                                onChangeComplete={(color: any) => { handleColorChange("headerFontColor", color) }} />
                        </div>
                        : null}
                </div>
                <div className="color-wrapper field-wrapper">
                    <TextField
                        id="menuBarBackgroundColor"
                        name="menuBarBackgroundColor"
                        className="color-picker"
                        label="Menu Bar Background Colour"
                        variant="outlined"
                        value={props.event.menuBarBackgroundColor || ""}
                        helperText="This will set the background colour of the menu bar (not the header section)."
                        onClick={() => handleClick("menuBarBackgroundColor")}
                    />
                    {isDisplayMenuBarBackgroundPicker ?
                        <div style={{ position: 'absolute', zIndex: 2 }}>
                            <div
                                style={{
                                    position: 'fixed',
                                    top: '0px',
                                    right: '0px',
                                    bottom: '0px',
                                    left: '0px',
                                }}
                                onClick={() => handleClose("menuBarBackgroundColor")}
                            />
                            <BlockPicker
                                color={props.event.menuBarBackgroundColor}
                                onChangeComplete={(color) => { handleColorChange("menuBarBackgroundColor", color) }} />
                        </div>
                        : null}
                </div>
                <div className="color-wrapper field-wrapper">
                    <TextField
                        id="menuBarFontColor"
                        name="menuBarFontColor"
                        className="color-picker"
                        label="Menu Bar Font Colour"
                        variant="outlined"
                        value={props.event.menuBarFontColor || ""}
                        helperText="This will set the font colour of the menu bar (not the header section)."
                        onClick={() => handleClick("menuBarFontColor")}
                    />
                    {isDisplayMenuBarFontPicker ? <div style={{ position: 'absolute', zIndex: 2 }}>
                        <div style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                        }} onClick={() => handleClose("menuBarFontColor")} />
                        <BlockPicker
                            color={props.event.menuBarFontColor}
                            onChangeComplete={(color) => { handleColorChange("menuBarFontColor", color) }} />
                    </div> : null}
                </div>
                <div className="color-wrapper field-wrapper">
                    <TextField
                        id="footerBackgroundColor"
                        name="footerBackgroundColor"
                        className="color-picker"
                        label="Footer Background Colour"
                        variant="outlined"
                        value={props.event.footerBackgroundColor || ""}
                        helperText="This will set the background colour of the footer."
                        onClick={() => handleClick("footerBackgroundColor")}
                    />
                    {isDisplayFooterBackgroundPicker ? <div style={{ position: 'absolute', zIndex: 2 }}>
                        <div style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                        }} onClick={() => handleClose("footerBackgroundColor")} />
                        <BlockPicker
                            color={props.event.footerBackgroundColor}
                            onChangeComplete={(color) => { handleColorChange("footerBackgroundColor", color) }} />
                    </div> : null}
                </div>
                <div className="color-wrapper field-wrapper">
                    <TextField
                        id="footerFontColor"
                        name="footerFontColor"
                        className="color-picker"
                        label="Footer Font Colour"
                        variant="outlined"
                        value={props.event.footerFontColor || ""}
                        helperText="This will set the font colour of the footer."
                        onClick={() => handleClick("footerFontColor")}
                    />
                    {isDisplayFooterFontPicker ? <div style={{ position: 'absolute', zIndex: 2 }}>
                        <div style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                        }} onClick={() => handleClose("footerFontColor")} />
                        <BlockPicker
                            color={props.event.footerFontColor}
                            onChangeComplete={(color) => { handleColorChange("footerFontColor", color) }} />
                    </div> : null}
                </div>
                <div className="color-wrapper field-wrapper">
                    <TextField
                        id="buttonBackgroundColor"
                        name="buttonBackgroundColor"
                        className="color-picker"
                        label="Button Background Colour"
                        variant="outlined"
                        value={props.event.buttonBackgroundColor || ""}
                        helperText="This will set the font colour of the footer."
                        onClick={() => handleClick("buttonBackgroundColor")}
                    />
                    {isDisplayButtonBackgroundPicker ? <div style={{ position: 'absolute', zIndex: 2 }}>
                        <div style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                        }} onClick={() => handleClose("buttonBackgroundColor")} />
                        <BlockPicker
                            color={props.event.buttonBackgroundColor}
                            onChangeComplete={(color) => { handleColorChange("buttonBackgroundColor", color) }} />
                    </div> : null}
                </div>
                <div className="color-wrapper field-wrapper">
                    <TextField
                        id="buttonFontColor"
                        name="buttonFontColor"
                        className="color-picker"
                        label="Button Font Colour"
                        variant="outlined"
                        value={props.event.buttonFontColor || ""}
                        helperText="This will set the font colour of the button."
                        onClick={() => handleClick("buttonFontColor")}
                    />
                    {isDisplayButtonFontPicker ? <div style={{ position: 'absolute', zIndex: 2 }}>
                        <div style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                        }} onClick={() => handleClose("buttonFontColor")} />
                        <BlockPicker
                            color={props.event.buttonFontColor}
                            onChangeComplete={(color) => { handleColorChange("buttonFontColor", color) }} />
                    </div> : null}
                </div>
            </Paper>
        </section>
    )

}

export default EventThemeSection
