import React from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material"

interface InputProps {
    isDialogOpen: boolean,
    handleClose: (boolean: boolean) => void,
    type: string,
    label: string, //so that specific actions can be called from the component calling the dialog
    title: string,
    message: string
}

const ConfirmationDialog: React.FunctionComponent<InputProps> = (props) => {

    return (
            <Dialog
                open={props.isDialogOpen}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <span>{props.message}</span>
                </DialogContent>
                <DialogActions>
                    {props.type==="AREYOUSURE" && <Button onClick={() => props.handleClose(false)} >
                        Cancel
                    </Button>}
                    <Button onClick={() => props.handleClose(true)} className="lh-button">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
    )
}

export default ConfirmationDialog