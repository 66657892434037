import React, { useEffect, useState } from 'react'
import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import IEvent from '../../interfaces/event'
import IEventOrganiser from '../../interfaces/eventOrganiser'
import EventOrganiserDialog from '../Dialogs/EventOrganiserDialog/EventOrganiserDialog'
import { IValidation } from '../../interfaces/validation'

interface InputProps {
    event: IEvent
    setEvent: (event: IEvent) => void
    eventValidation: any
}

const EventOrganiserSection = (props: InputProps) => {

    const [isEventOrganiserDialogOpen, setIsEventOrganiserDialogOpen] = useState<boolean>(false);
    const [eventOrganiserToBeEdited, setEventOrganiserToBeEdited] = useState<IEventOrganiser>({} as IEventOrganiser);


    const deleteEventOrganiser = (index: number) => {
        let tempEventOrganiserListObject: IEventOrganiser[] = [...props.event.eventOrganiserList]
        tempEventOrganiserListObject.splice(index, 1)
        props.setEvent({ ...props.event, eventOrganiserList: tempEventOrganiserListObject })
    }

    const closeDialog = () => {
        props.eventValidation.validateInputs(props.event)
        setIsEventOrganiserDialogOpen(false)
        setEventOrganiserToBeEdited({} as IEventOrganiser)
    }

    const getValidation = (name): IValidation => {
        let returnValue: IValidation = {} as IValidation
        props.eventValidation.validationObject.forEach(element => {
            if (name === element.name) {
                returnValue = element
            }
        });
        return returnValue
    }

    useEffect(() => {
        if (eventOrganiserToBeEdited._id) {
            setIsEventOrganiserDialogOpen(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [eventOrganiserToBeEdited]);

    return (
        <div className="event-organiser-section">
            <h2 className="subtitle">Event Organisers.</h2>
            <span className="description">The contact details of the event organisers who will be notified of the winners once the event closes.</span>
            <Paper className="input-wrapper">
                {props.event.eventOrganiserList && props.event.eventOrganiserList.length > 0 ?
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <div className="table-header-cell">
                                        <span>First Name</span>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div className="table-header-cell">
                                        <span>Surname</span>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div className="table-header-cell">
                                        <span>Email</span>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div className="table-header-cell">
                                        <span>Phone</span>
                                    </div>
                                </TableCell>
                                <TableCell className="hide-on-mobile">
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.event.eventOrganiserList.map((eventOrganiser: IEventOrganiser, index) => (
                                <TableRow key={index}>
                                    <TableCell>{eventOrganiser.firstName}</TableCell>
                                    <TableCell>{eventOrganiser.surname}</TableCell>
                                    <TableCell>{eventOrganiser.email}</TableCell>
                                    <TableCell>{eventOrganiser.phone}</TableCell>
                                    <TableCell className="hide-on-mobile">
                                        <div className="action-cell">
                                            <div onClick={() => setEventOrganiserToBeEdited(eventOrganiser)}><Tooltip title="Edit Event Organiser"><CreateIcon /></Tooltip></div>
                                            <div onClick={() => deleteEventOrganiser(index)}><Tooltip title="Delete Event Organiser"><DeleteIcon /></Tooltip></div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    :
                    <div className="no-entries">No event organisers defined.</div>
                }

                <div className="button-wrapper" style={{ flexDirection: "column", alignItems: "flex-end" }}>
                    {!getValidation("eventOrganiserList").isValid && <span className="validation-text" style={{ marginBottom: 12 }}>{getValidation("eventOrganiserList").validationMessage}</span>}
                    <Button className="lh-button" onClick={() => setIsEventOrganiserDialogOpen(true)}>Add Event Organiser</Button>
                </div>
            </Paper>
            <EventOrganiserDialog
                handleClose={closeDialog}
                isDialogOpen={isEventOrganiserDialogOpen}
                event={props.event}
                setEvent={props.setEvent}
                eventOrganiser={eventOrganiserToBeEdited}
                eventValidation={props.eventValidation}
            />
        </div>
    )
}

export default EventOrganiserSection