import React, { useState } from 'react'
import { AppBar, Button, Drawer, MenuItem, Toolbar } from '@mui/material'
import { Link } from 'react-router-dom'
import "./eventMenuBar.scss"
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import IEvent from '../../interfaces/event'
import { ReactComponent as LighthouseLogo } from '../../assets/lighthouse-logo-inline-white.svg';

interface InputProps {
    event: IEvent
}

const EventMenuBar = (props: InputProps) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)



    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setIsDrawerOpen(open);
    };

    return (
        <>
            {props.event &&
                <div className="event-menu-bar" style={{ "--menu-bar-font-color": props.event.menuBarFontColor, "--menu-bar-background-color": props.event.menuBarBackgroundColor } as React.CSSProperties}>
                    <AppBar>
                        <Toolbar style={{ "--menu-bar-font-color": props.event.menuBarFontColor, "--menu-bar-background-color": props.event.menuBarBackgroundColor } as React.CSSProperties}>
                        {/* <Toolbar style={{ backgroundColor: props.event.menuBarBackgroundColor }}> */}

                            <div className="logo-wrapper">
                                <Link to={`/${props.event.eventId}`}>
                                    <LighthouseLogo />
                                </Link>
                            </div>
                            <ul className="menu-items">
                                <li>
                                    <Link to={`/${props.event.eventId}/`} style={{ color: props.event.menuBarFontColor }}>
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/${props.event.eventId}/prizes`} style={{ color: props.event.menuBarFontColor }}>
                                        Prizes
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/${props.event.eventId}/tickets`} style={{ color: props.event.menuBarFontColor }}>
                                        Buy Tickets
                                    </Link>
                                </li>
                            </ul>
                            <div className="clickable-icon hamburger-menu" aria-controls="simple-menu" aria-haspopup="true"
                                onClick={toggleDrawer(true)}
                            >
                                <MenuIcon style={{ color: props.event.menuBarFontColor }} />
                            </div>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        anchor="top"
                        open={isDrawerOpen}
                        onClose={toggleDrawer(false)}
                        className="menu-drawer"
                        style={{'--menu-bar-background-color': props.event.menuBarBackgroundColor} as React.CSSProperties}
                    >
                        <div className="close-icon-container">
                            <Button
                                className="clickable-icon hamburger-menu"
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={toggleDrawer(false)}
                                style={{ color: props.event.menuBarFontColor }}
                            >
                                <CloseIcon />
                            </Button>
                        </div>
                        <Link to={`/${props.event.eventId}/`} style={{ color: props.event.menuBarFontColor }}>
                            <MenuItem onClick={() => { setIsDrawerOpen(false) }}>Home</MenuItem>
                        </Link>
                        <Link to={`/${props.event.eventId}/prizes`} style={{ color: props.event.menuBarFontColor }}>
                            <MenuItem onClick={() => { setIsDrawerOpen(false) }}>Prizes</MenuItem>
                        </Link>
                        <Link to={`/${props.event.eventId}/tickets`} style={{ color: props.event.menuBarFontColor }}>
                            <MenuItem onClick={() => { setIsDrawerOpen(false) }}>Buy Tickets</MenuItem>
                        </Link>
                    </Drawer>
                </div>
            }
        </>
    )
}

export default EventMenuBar