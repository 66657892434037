import React, { useEffect, useState } from 'react'
import Loading from '../../components/Loading/Loading'
import useFetch from '../../hooks/useFetch'
import IPrize from '../../interfaces/prize'
import IPageProps from '../../interfaces/page'
import { Card, TableCell, Tooltip } from '@mui/material'
import { Button, CardContent, Table, TableBody, TableHead, TableRow } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import { Link } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog/ConfirmationDialog'

const PrizeListPage: React.FunctionComponent<IPageProps & RouteComponentProps<any>> = (props) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')
    const [prizeList, setPrizeList] = useState<IPrize[]>([])
    const prizeApi = useFetch('prizes')
    const eventApi = useFetch('events')
    const [dialogProperties, setDialogProperties] = useState<any>({ type: '', title: '', label: '', message: '' })
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false)
    const [prizeToBeDeleted, setPrizeToBeDeleted] = useState<IPrize>({} as IPrize)

    const getPrizes = (): void => {
        setLoading(true)
        eventApi
            .get(`/${props.match.params.eventId}/prizes`)
            .then((prizes: IPrize[]) => {
                setPrizeList(prizes.sort((a, b) => a.orderNumber - b.orderNumber))
                setLoading(false)
            })
            .catch((err: Error) => {
                setError(err.message)
                setLoading(false)
            })
    }

    const increasePrizeOrder = (prize: IPrize): void => {
        var prizeToBeMoved: IPrize = prize
        var prizeToBeReplaced: IPrize = prizeList.filter((prizeToBeReplaced) => prizeToBeReplaced.orderNumber === prizeToBeMoved.orderNumber + 1)[0]

        if (prizeToBeReplaced) {
            prizeApi
                .post(`/increasePrizeOrder`, {
                    prizeToBeMovedId: prizeToBeMoved._id,
                    prizeToBeMovedOrderNumber: prizeToBeMoved.orderNumber,
                    prizeToBeReplacedId: prizeToBeReplaced._id,
                    prizeToBeReplacedOrderNumber: prizeToBeReplaced.orderNumber
                })
                .then(() => {
                    getPrizes()
                })
                .catch((err: Error) => {
                    setError(err.message)
                })
        } else {
            prizeToBeMoved.orderNumber = prizeToBeMoved.orderNumber + 1

            prizeApi
                .post(`/update`, {
                    prizeId: prizeToBeMoved._id,
                    prize: prizeToBeMoved
                })
                .then(() => {
                    getPrizes()
                })
                .catch((err: Error) => {
                    setError(err.message)
                })
        }
    }

    const decreasePrizeOrder = (prize: IPrize): void => {
        var prizeToBeMoved: IPrize = prize
        var prizeToBeReplaced: IPrize = prizeList.filter((prizeToBeReplaced) => prizeToBeReplaced.orderNumber === prizeToBeMoved.orderNumber - 1)[0]

        if (prizeToBeReplaced) {
            prizeApi
                .post(`/decreasePrizeOrder`, {
                    prizeToBeMovedId: prizeToBeMoved._id,
                    prizeToBeMovedOrderNumber: prizeToBeMoved.orderNumber,
                    prizeToBeReplacedId: prizeToBeReplaced._id,
                    prizeToBeReplacedOrderNumber: prizeToBeReplaced.orderNumber
                })
                .then(() => {
                    getPrizes()
                })
                .catch((err: Error) => {
                    setError(err.message)
                })
        } else {
            if (prizeToBeMoved.orderNumber - 1 > 0) {
                prizeToBeMoved.orderNumber = prizeToBeMoved.orderNumber - 1

                prizeApi
                    .post(`/update`, {
                        prizeId: prizeToBeMoved._id,
                        prize: prizeToBeMoved
                    })
                    .then(() => {
                        getPrizes()
                    })
                    .catch((err: Error) => {
                        setError(err.message)
                    })
            } else {
                console.log('Order number must be greater than 0')
            }
        }
    }

    const duplicatePrize = (existingPrize: IPrize): void => {
        let prizeToBeCopied: IPrize = { ...existingPrize }

        prizeToBeCopied.title = existingPrize.title + '-copy-' + new Date().getTime()
        prizeToBeCopied.orderNumber = prizeList.length + 1

        prizeApi
            .post('/create', prizeToBeCopied)
            .then((createdPrize: IPrize) => {
                getPrizes()
            })
            .catch((err: Error) => {
                setError(err.message)
            })
    }

    const deletePrize = (prize: IPrize): void => {
        prizeApi
            .get(`/delete/${prize._id}`)
            .then(() => {
                setDialogProperties({ type: 'SUCCESS', title: 'Prize Deleted', message: 'Prize deleted successfully.', label: 'PRIZE DELETED' })
                setIsConfirmationDialogOpen(true)
                getPrizes()
            })
            .catch((err: Error) => {
                setError(err.message)
            })
    }

    const handleCloseDialog = (isConfirmed: boolean) => {
        if (dialogProperties.type === 'AREYOUSURE') {
            if (isConfirmed) {
                deletePrize(prizeToBeDeleted)
            }
        }
        setDialogProperties({})
        setIsConfirmationDialogOpen(false)
    }

    const openDeleteConfirmationDialog = (prize: IPrize) => {
        setDialogProperties({ type: 'AREYOUSURE', title: 'Confirm Delete', message: 'Are you sure you want to delete this prize?', label: 'DELETE' })
        setIsConfirmationDialogOpen(true)
        setPrizeToBeDeleted(prize)
    }

    useEffect(() => {
        getPrizes()
        // eslint-disable-next-line
    }, [])

    if (loading) {
        return <Loading />
    }

    if (error) {
        return <p>{error}</p>
    }

    return (
        <div className="prize-list-page">
            {prizeList.length > 0 ? (
                <>
                    <Card>
                        <CardContent>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <div className="table-header-cell">
                                                <span>Order Number</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-header-cell">
                                                <span>Prize</span>
                                            </div>
                                        </TableCell>
                                        <TableCell className="hide-on-mobile"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {prizeList.map((prize, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{prize.orderNumber}</TableCell>
                                            <TableCell>{prize.title}</TableCell>
                                            {/* <TableCell className="hide-on-mobile">{moment(prize.createdDate).format("HH:mm DD/MM/YYYY")}</TableCell>
                                    <TableCell className="hide-on-mobile">{moment(prize.editedDate).format("HH:mm DD/MM/YYYY")}</TableCell> */}

                                            <TableCell className="hide-on-mobile">
                                                <div className="action-cell">
                                                    <div onClick={() => decreasePrizeOrder(prize)}>
                                                        <Tooltip title="Promote Prize" enterDelay={500}>
                                                            <ExpandLessIcon />
                                                        </Tooltip>
                                                    </div>
                                                    <div onClick={() => increasePrizeOrder(prize)}>
                                                        <Tooltip title="Demote Prize" enterDelay={500}>
                                                            <ExpandMoreIcon />
                                                        </Tooltip>
                                                    </div>
                                                    <Link to={`/admin/events/${props.match.params.eventId}/prizes/${prize._id}`}>
                                                        <Tooltip title="Edit Prize">
                                                            <CreateIcon />
                                                        </Tooltip>
                                                    </Link>

                                                    <div onClick={() => duplicatePrize(prize)}>
                                                        <Tooltip title="Duplicate Prize" enterDelay={500}>
                                                            <FileCopyIcon />
                                                        </Tooltip>
                                                    </div>
                                                    <div onClick={() => openDeleteConfirmationDialog(prize)}>
                                                        <Tooltip title="Delete Prize" enterDelay={500}>
                                                            <DeleteIcon />
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </>
            ) : (
                <Card>
                    <CardContent>
                        <div className="no-entries">No prizes configured</div>
                    </CardContent>
                </Card>
            )}
            <div className="button-wrapper space-between">
                <div className="hide-on-mobile"></div>
                <Link to={`/admin/events/${props.match.params.eventId}/prizes/new`}>
                    <Button className="lh-button">New Prize</Button>
                </Link>
            </div>
            <ConfirmationDialog
                handleClose={handleCloseDialog}
                isDialogOpen={isConfirmationDialogOpen}
                message={dialogProperties.message}
                title={dialogProperties.title}
                type={dialogProperties.type}
                label={dialogProperties.label}
            />
        </div>
    )
}

export default PrizeListPage
