import React, { useContext } from 'react'
import { Link } from "react-router-dom"
import AdminMenuBar from '../components/AdminMenuBar/AdminMenuBar'
import { Drawer, List, ListItem, ListItemIcon, ListItemText, AppBar, Toolbar } from '@mui/material'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import GavelIcon from '@mui/icons-material/Gavel'
import RedeemIcon from '@mui/icons-material/Redeem'
import "./layoutAuthenticated.scss"
import LighthouseLogoOnly from '../assets/lighthouse-logo-only.png'
import { useParams } from 'react-router-dom'
import ListAltIcon from '@mui/icons-material/ListAlt'
import AdminFooter from '../components/AdminFooter/AdminFooter';
import UserContext from '../contexts/user'
import ManageSearchIcon from '@mui/icons-material/ManageSearch';


interface InputProps {
    children?: any
    auth?: any
    title?: string
    authorisedUserProfile?: any
}

interface Params {
    eventId: string
}

const LayoutAuthenticated = (props: InputProps) => {
    const { eventId } = useParams<Params>()
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const userContext = useContext(UserContext)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div className="drawer-container">
            <AppBar position="fixed" className="menu-bar">
                <Toolbar>
                    {userContext.userState.user.isAdmin ?
                        <Link className="clickable-icon" to={'/admin/events'} >
                            <img className="logo" alt="logo" src={LighthouseLogoOnly} />
                        </Link>
                        :
                        <div className="clickable-icon" >
                            <img className="logo" alt="logo" src={LighthouseLogoOnly} />
                        </div>

                    }
                </Toolbar>
            </AppBar>
            <List>
                {userContext.userState.user.isAdmin &&
                    <ListItem button component={Link} to={`/admin/events/${eventId}`}>
                        <ListItemIcon><GavelIcon /></ListItemIcon>
                        <ListItemText primary="Event Settings" />
                    </ListItem >
                }
                {userContext.userState.user.isAdmin &&
                    <ListItem button component={Link} to={`/admin/events/${eventId}/prizes`}>
                        <ListItemIcon><RedeemIcon /></ListItemIcon>
                        <ListItemText primary="Prize Settings" />
                    </ListItem >
                }
                <ListItem button component={Link} to={`/admin/events/${eventId}/purchases`}>
                    <ListItemIcon><ListAltIcon /></ListItemIcon>
                    <ListItemText primary="Purchase Report" />
                </ListItem >
                {userContext.userState.user.isAdmin &&
                    <ListItem button component={Link} to={`/admin/events/${eventId}/winners`}>
                        <ListItemIcon><FormatListBulletedIcon /></ListItemIcon>
                        <ListItemText primary="Winner Report" />
                    </ListItem >
                }
                {userContext.userState.user.isAdmin &&
                    <ListItem button component={Link} to={`/admin/events/${eventId}/logs`}>
                        <ListItemIcon><ManageSearchIcon /></ListItemIcon>
                        <ListItemText primary="Event Logs" />
                    </ListItem >
                }
            </List>
        </div>
    )

    return (
        <div className="layout-authenticated">
            <AdminMenuBar />
            <div className="root">
                <Drawer
                    variant="temporary"
                    className="mobile-drawer"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{
                        paper: "drawer-paper",
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    classes={{
                        paper: "drawer-paper",
                    }}
                    variant="permanent"
                    className="desktop-drawer"
                    open
                >
                    {drawer}
                </Drawer>
                <main>
                    <div className="header-bar">
                        {/* <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                        >
                            <MenuIcon />
                        </IconButton> */}
                        <span>{props.title}</span>
                    </div>
                    <div className="full-height-content">
                        {props.children}
                    </div>
                    <AdminFooter />
                </main>

            </div>
        </div>
    )
}

export default LayoutAuthenticated