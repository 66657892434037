import { Button, Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import Loading from '../../components/Loading/Loading'
import useFetch from '../../hooks/useFetch'
import IEvent from '../../interfaces/event'
import IPageProps from '../../interfaces/page'
import _ from 'lodash'
import IPrize from '../../interfaces/prize'
import ITicket from '../../interfaces/ticket'
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog/ConfirmationDialog'
import useExcel from '../../hooks/useExcel'
import { Link } from 'react-router-dom'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import WinnerPdfDownloadDialog from '../../components/Dialogs/WinnerPdfDownloadDialog/WinnerPdfDownloadDialog'
import IWinningTicket from '../../interfaces/winningTicket'

const WinnerReportPage: React.FunctionComponent<IPageProps & RouteComponentProps<any>> = (props) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>("")
    const [event, setEvent] = useState<IEvent>({} as IEvent)
    const [prizeList, setPrizeList] = useState<IPrize[]>([])
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
    const [dialogProperties, setDialogProperties] = useState<any>({ type: "", title: "", message: "", label: "" })
    const [paidTicketList, setPaidTicketList] = useState<ITicket[]>([])
    const [eventToggle, setEventToggle] = useState<boolean>(false)
    const [isGenerateLocked, setIsGenerateLocked] = useState<boolean>(false)
    const [pdfDialogProperties, setPdfDialogProperties] = useState<any>({ isOpen: false, type: "" })
    const [ticketToDownload, setTicketToDownload] = useState<IWinningTicket>({} as IWinningTicket)

    const eventApi = useFetch("events");
    const excel = useExcel();

    const getEvent = (): void => {
        setLoading(true)
        eventApi.get(`/getEventById/${props.match.params.eventId}/true`).then((event: IEvent) => {
            setEvent(event)
            if (event.winningTicketList && event.winningTicketList.length > 0) {
                setIsGenerateLocked(true)
            }
            eventApi.get(`/${props.match.params.eventId}/prizes`).then((prizeList: IPrize[]) => {
                setPrizeList(prizeList)
                setLoading(false)
            }).catch((err: Error) => {
                setError(err.message)
                setLoading(false)
            })
        }).catch((err: Error) => {
            setError(err.message)
            setLoading(false)
        })
    }

    const setWinningTicketList = (winningTicketList: ITicket[]): void => {
        setLoading(true)

        let winningTicketAndPrizeList: IWinningTicket[] = winningTicketList.map((winningTicket, index) => { return { ticket: winningTicket, prizeId: prizeList[index]._id } })

        console.log(winningTicketAndPrizeList)

        eventApi.post('/setWinnerList', { _id: event._id, winningTicketList: winningTicketAndPrizeList })
            .then((event: IEvent) => {
                setEvent(event)
                setLoading(false)

                // TODO - fix this hack, which is the page not refreshing on setEvent above?
                setEventToggle(!eventToggle)

            }).catch((err: Error) => {
                setError(err.message)
                setLoading(false)
            })
    }

    const generateWinners = () => {
        console.log("generate winners")
        let paidTicketNumbers: ITicket[] = []

        //calling this again to ensure getting up to date purchases
        eventApi.get(`/getEventById/${props.match.params.eventId}/false`).then((latestEvent: IEvent) => {
            setEvent(latestEvent)

            let isPendingFound = false
            let isAllPending = true

            latestEvent.purchases.forEach(purchase => {
                if (purchase.status === "PAID") {
                    isAllPending = false
                    purchase.ticketNumbers.forEach(element => {
                        paidTicketNumbers.push({ ticketNumber: element, purchase: purchase })
                    });
                } else {
                    isPendingFound = true
                }
            });

            setPaidTicketList(paidTicketNumbers)

            if (isAllPending) {
                setDialogProperties({ type: "ERROR", title: "No Paid Purchases Found", message: `No purchases with a status of 'PAID' were found. Cannot generate winners.` })
                setIsConfirmationDialogOpen(true)
            } else if (prizeList.length < 1) {
                setDialogProperties({ type: "ERROR", title: "No Prizes Found.", message: "No prizes have been configured. Please configure prizes to be able to generate winners." })
                setIsConfirmationDialogOpen(true)
            } else if (isPendingFound) {
                setDialogProperties({ type: "AREYOUSURE", title: "Pending Purchases Found.", message: "There are still purchases, that have not been paid for, with a 'PENDING' status. Are you sure you want to generate winners exluding these purchases?", label: "PENDING-PURCHASES-DETECTED" })
                setIsConfirmationDialogOpen(true)
            } else {
                setWinningTicketList(_.sampleSize(paidTicketNumbers, prizeList.length))
            }
        }).catch((err: Error) => {
            setError(err.message)
        })
    }

    const handleCloseDialog = (isConfirmed: boolean) => {
        if (dialogProperties.label === "PENDING-PURCHASES-DETECTED") {
            if (isConfirmed) {
                setWinningTicketList(_.sampleSize(paidTicketList, prizeList.length))
            } else {
                setPaidTicketList([])
            }

        } else if (dialogProperties.label === "UNLOCK") {
            if (isConfirmed) {
                setIsGenerateLocked(false)
            }
        }
        setDialogProperties({})
        setIsConfirmationDialogOpen(false)
    }

    const openUnlockDialog = () => {
        setDialogProperties({ type: "AREYOUSURE", title: "Unlock Winner Generation", message: "Winners have already been generated. Existing winners will be lost permanently if winners are re-generated. Are you sure you want to do this?", label: "UNLOCK" })
        setIsConfirmationDialogOpen(true)
    }

    const handleWinnerPdfDownloadDialogClose = () => {
        setPdfDialogProperties({ isOpen: false })
        setTicketToDownload({} as IWinningTicket)
    }

    const generateWinnerPdf = (winningTicket: IWinningTicket) => {

        fetch(`${process.env.PUBLIC_URL || "http://localhost:8080"}/pdfs/createWinnerPdf`,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    winningTicket: winningTicket,
                    prize: prizeList.filter(prize => prize._id === winningTicket.prizeId)[0],
                    event: event
                })
            })
            .then(response => response.blob())
            .then(blob => {
                let blobUrl = URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', `Lighthouse Lotto - ${event.name} - Winner - ${winningTicket.ticket.purchase.name}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode!.removeChild(link);
            })
            .catch((err: Error) => {
                setError(err.message)
                console.log(err)
            })
    }
    const generateWinnerSummaryPdf = () => {

        fetch(`${process.env.PUBLIC_URL || "http://localhost:8080"}/pdfs/createWinnerSummaryPdf`,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    event: event,
                    prizeList: prizeList
                })
            })
            .then(response => response.blob())
            .then(blob => {
                let blobUrl = URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', `Lighthouse Lotto - ${event.name} - Winner Summary.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode!.removeChild(link);
            })
            .catch((err: Error) => {
                setError(err.message)
                console.log(err)
            })
    }

    useEffect(() => {
        getEvent()

        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    useEffect(() => {
        getEvent()
        // eslint-disable-next-line
    }, [eventToggle]);

    useEffect(() => {
        if (ticketToDownload.ticket && ticketToDownload.ticket.ticketNumber) {
            setPdfDialogProperties({ isOpen: true })
        }
        // eslint-disable-next-line
    }, [ticketToDownload]);

    if (loading) {
        return (
            <Loading />
        )
    }

    if (error) {
        return (
            <p>{error}</p>
        )
    }

    return (
        <div className="winner-report-page">
            {event.purchases && event.purchases.length > 0 ?
                event.winningTicketList && event.winningTicketList.length > 0 ?
                    <>
                        <div className="button-wrapper space-between" style={{ marginBottom: 6 }}>
                            <div></div>
                            <Link to={`/admin/events/${event.eventId}/winner-generator`}>Open Winner Generator Screen <OpenInNewIcon style={{ width: 13, height: 13 }} /></Link>
                        </div>
                        <Card>
                            <CardContent>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <div className="table-header-cell">
                                                    <span>Winner Number</span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="table-header-cell">
                                                    <span>Ticket Number</span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="table-header-cell">
                                                    <span>Ticket Owner</span>
                                                </div>
                                            </TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {event.winningTicketList.map((winningTicket, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{`${event.eventPrefix}_${winningTicket.ticket.ticketNumber}`}</TableCell>
                                                <TableCell>{winningTicket.ticket.purchase.name}</TableCell>
                                                <TableCell>
                                                    <div className="action-cell centralised">
                                                        <div onClick={() => generateWinnerPdf(winningTicket)}>
                                                            {/* <div onClick={() => setTicketToDownload(winningTicket)}> */}
                                                            <Tooltip title="Generate Winner PDF" enterDelay={500}><ArrowCircleDownIcon /></Tooltip>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card >
                        <div className="button-wrapper space-between">
                            <div>
                                <Link to={'#'} onClick={() => excel.generateWinnerReport(event)} style={{ marginRight: 18 }}>Download Winner Report</Link>
                                <Link to={'#'} onClick={() => generateWinnerSummaryPdf()}>Generate Winner Summary PDF</Link>
                                {/* <Link to={'#'} onClick={() => setPdfDialogProperties({ isOpen: true, type: "SUMMARY" })}>Generate Winner Summary PDF</Link> */}
                            </div>
                            {event.winningTicketList.length > 0 && isGenerateLocked ?
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Button disabled className="lh-button disabled">Locked</Button>
                                    <Link to={'#'} onClick={openUnlockDialog} style={{ color: 'blue' }}>Unlock</Link>
                                </div>
                                :
                                <Button onClick={generateWinners} className="lh-button">Generate Winners</Button>
                            }

                        </div>
                    </>
                    :
                    <>
                        <Card><CardContent><div className="no-entries">No winners found.</div></CardContent></Card>
                        <div className="button-wrapper">
                            <Button onClick={generateWinners} className="lh-button">Generate Winners</Button>
                        </div>
                    </>
                :
                <Card><CardContent><div className="no-entries">No purchases found.</div></CardContent></Card>
            }

            <ConfirmationDialog
                handleClose={handleCloseDialog}
                isDialogOpen={isConfirmationDialogOpen}
                message={dialogProperties.message}
                title={dialogProperties.title}
                type={dialogProperties.type}
                label={dialogProperties.label}
            />

            <WinnerPdfDownloadDialog
                handleClose={handleWinnerPdfDownloadDialogClose}
                isDialogOpen={pdfDialogProperties.isOpen}
                event={event}
                winningTicket={ticketToDownload}
                prize={prizeList.filter(prize => prize._id === ticketToDownload.prizeId)[0]}
                type={pdfDialogProperties.type}
                prizeList={prizeList}
            />

        </div>
    )
}

export default WinnerReportPage
