import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import IPurchase from "../../interfaces/purchase"
import './purchaseFilters.scss'
import UserContext from '../../contexts/user'

interface InputProps {
    purchaseFilters: any
    setPurchaseFilters: (purchaseFilters: any) => void
    purchases: IPurchase[]
    getTableNumber: (purchase: IPurchase) => number
    isTableNumber: boolean
}

const PurchaseFilters = (props: InputProps) => {

    const [uniqueTableNumbers, setUniqueTableNumbers] = useState<number[]>([])

    const userContext = useContext(UserContext)

    const handleChange = (event) => {
        const { name, value } = event.target;
        props.setPurchaseFilters({
            ...props.purchaseFilters,
            [name]: value
        });
    }

    useEffect(() => {

        let filteredPurchases = props.purchases;

        if (!userContext.userState.user.isAdmin) {
            filteredPurchases = filteredPurchases.filter(purchase => {
                return (purchase.status === "PENDING")
            })
        }

        let tableNumbers: number[] = filteredPurchases.map(purchase => props.getTableNumber(purchase))
        setUniqueTableNumbers([...new Set(tableNumbers)].sort())

        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    return (
        <div className="purchase-filters">
            <div className="filter-inputs">
                <TextField
                    id="name-filter"
                    name="name"
                    label="Filter by Purchaser Name"
                    variant="outlined"
                    value={props.purchaseFilters.name}
                    onChange={handleChange}
                />
                {props.isTableNumber &&
                    <FormControl className="table-filter">
                        <InputLabel id="table-filter-select-label">Table Number</InputLabel>
                        <Select
                            labelId="table-filter-select-label"
                            id="table-filter-select"
                            name="tableNumber"
                            value={props.purchaseFilters.tableNumber}
                            onChange={handleChange}
                            label="Table Number"
                        >
                            <MenuItem value="All">All</MenuItem>
                            {
                                uniqueTableNumbers.map((tableNumber: number, index: number) => {
                                    return (<MenuItem key={index} value={tableNumber}>{tableNumber}</MenuItem>)
                                })
                            }
                        </Select>
                    </FormControl>
                }

            </div>
            <div className="clear-filters" onClick={() => props.setPurchaseFilters({ name: "", tableNumber: "All" })}>Clear Filters</div>
        </div>
    )
}

export default PurchaseFilters
