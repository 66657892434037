import React from "react"
import "./eventFooter.scss"
import LanguageIcon from '@mui/icons-material/Language'
import MailIcon from '@mui/icons-material/Mail'
import { Link } from 'react-router-dom'
import IEvent from "../../interfaces/event"

interface InputProps {
    event: IEvent
}

const EventFooter = (props: InputProps) => {
    return (
        <div className="footer">
            {props.event &&
                <section className="footer-section" style={{ backgroundColor: props.event.footerBackgroundColor, color: props.event.footerFontColor }}>
                    <div className="left-content">
                        <div className="website-wrapper">
                            <LanguageIcon />
                            <a href={`https://www.lighthouseclub.org/`}>www.lighthouseclub.org</a>
                        </div>


                        <div className="email-wrapper">
                            <MailIcon />
                            <a href={`mailto:info@lighthouseclub.org`}>info@lighthouseclub.org</a>
                        </div>

                    </div>
                    <div className="middle-content">
                        <span>Registered Charity No. UK 1149488 | Registered Charity No. ROI 20200334 | Company No. 08244118 | © Copyright Lighthouse Construction Industry Charity 2021</span>
                    </div>
                    <div className="right-content">
                        <div className="terms-and-conditions-wrapper">
                            <Link to={`/${props.event.eventId}/terms-and-conditions`}>Terms & Conditions</Link>
                        </div>
                    </div>
                </section>
            }
        </div>
    )
}

export default EventFooter