import { useState } from "react";
import IPrize from "../../interfaces/prize";
import { IValidation } from "../../interfaces/validation";

const usePrizeValidation = (prize: IPrize) => {
    const [validationObject, setValidationObject] = useState<IValidation[]>([
        { name: "title", isValid: true, validationMessage: "" },
    ])
    const [isValidationPassed, setIsValidationPassed] = useState<boolean>(true)

    const validateInputs = (): boolean => {

        let tempValidationObject: IValidation[] = [
            { name: "title", isValid: true, validationMessage: "" },
        ]

        let tempIsValidationPassed = true

        const setFailedValidation = (name, message) => {
            tempIsValidationPassed = false
            tempValidationObject.forEach(element => {
                if (element.name === name) {
                    element.isValid = false
                    element.validationMessage = message
                }
            });
        }

        if (prize.title === "" || typeof (prize.title) === "undefined") {
            setFailedValidation("title", "Please provide a prize title.")
        }

        setValidationObject(tempValidationObject)
        setIsValidationPassed(tempIsValidationPassed)
        return tempIsValidationPassed
    }


    return {
        validationObject,
        setValidationObject,
        isValidationPassed,
        setIsValidationPassed,
        validateInputs
    }

}

export default usePrizeValidation