import React, { useState, useEffect } from "react"
// import { ReactComponent as PacManSVG } from '../../images/Pacman.svg'

interface InputProps {}

const Loading: React.FunctionComponent<InputProps> = () => {

    const [isDisplayLoading, setIsDisplayLoading] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => enableLoading(), 500);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    const enableLoading = () => {
        setIsDisplayLoading(true)
    }

    return (
        isDisplayLoading ?
            <div className="loading-wrapper">
                <h2>Loading...</h2>
            </div > : null
    )
}

export default Loading