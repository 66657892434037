import { Page, Text, View, Document, StyleSheet, Image} from '@react-pdf/renderer'
import moment from 'moment'
import IEvent from "../interfaces/event"
import lighthouseLogo from '../assets/Lighthouse-logo.png'
import IWinningTicket from "../interfaces/winningTicket"
import IPrize from '../interfaces/prize'

const usePdfGenerator = (event: IEvent) => {

    const styles = StyleSheet.create({
        table: { width: "auto", borderStyle: "solid", borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0 },
        redemptionTable: { width: "auto", borderStyle: "solid", borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0, marginBottom: 12 },
        tableRow: { margin: "auto", flexDirection: "row" },
        tableCol: { borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
        tableCol25: { width: "25%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
        tableCol50: { width: "50%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
        tableCell: { textAlign: 'center', marginTop: 5, fontSize: 10, padding: 3 },
        boldTableCell: { textAlign: 'center', marginTop: 5, fontSize: 10, fontFamily: "Helvetica-Bold" },
    });


    const generateWinnerPdf = (winningTicket: IWinningTicket, prize: IPrize) => {

        if (winningTicket.prizeId) {

            return (
                <Document>
                    <Page size="A4" style={{ padding: 48, display: "flex", justifyContent: "space-between" }} >
                        <View>
                            <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 24 }}>
                                <View>
                                    <Text style={{ fontSize: 32, marginBottom: 6, fontFamily: "Helvetica-Bold" }}>
                                        Lighthouse Lotto
                                    </Text>
                                    <Text style={{ fontSize: 32, marginBottom: 6, fontFamily: "Helvetica-Bold" }}>
                                        Winner!
                                    </Text>
                                </View>
                                <Image src={lighthouseLogo} style={{ width: 130, height: 100, marginRight: 30 }} />
                            </View>
                            <View>
                                <Text style={{ fontSize: 14, marginBottom: 6 }}>{`Name: ${winningTicket.ticket.purchase.name}`}</Text>
                                <Text style={{ fontSize: 14, marginBottom: 6 }}>{`Ticket Number: ${event.eventPrefix}-${winningTicket.ticket.ticketNumber}`}</Text>
                                <Text style={{ fontSize: 14, marginBottom: 6 }}>{`Event: ${event.name}`}</Text>
                                <Text style={{ fontSize: 14, marginBottom: 6 }}>{`Date: ${moment(event.endDateTime).format('DD/MM/yyyy')}`}</Text>
                            </View>
                            <View style={{ marginTop: 12 }}>
                                <Text style={{ fontSize: 20, marginBottom: 6, fontFamily: "Helvetica-Bold" }}>
                                    {prize.title}
                                </Text>
                                <Text style={{ fontSize: 18, marginBottom: 12, fontFamily: "Helvetica-Bold" }}>
                                    {prize.subtitle}
                                </Text>
                                <Image src={prize.media[0].src} style={{ width: 450, height: 300 }} />
                                {/* <Image src={prize.descriptionHtml.toBase64Image()}  /> */}
                            </View>

                        </View>
                        <View style={{ marginTop: 12, display: "flex", alignItems: "center", textAlign: "center" }}>
                            <Text style={{ fontSize: 10 }}>
                                Registered Charity No. UK 1149488 | Registered Charity No. ROI 20200334 | Company No. 08244118
                            </Text>
                            <Text style={{ fontSize: 10 }}>
                                Copyright Lighthouse Construction Industry Charity 2022
                            </Text>
                        </View>
                    </Page>
                </Document>
            )
        } else {
            return <Document></Document>
        }

    }
    const generateWinnerSummaryPdf = (prizeList: IPrize[]) => {




        let winnerAndPrizeList = event.winningTicketList.map(winningTicket => { return ({ winningTicket: winningTicket, prize: prizeList.filter((prize) => prize._id === winningTicket.prizeId)[0] }) })

        return (
            <Document>
                <Page size="A4" style={{ padding: 48, display: "flex", justifyContent: "space-between" }} orientation="landscape">
                    <View>
                        <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 24 }}>
                            <View>
                                <Text style={{ fontSize: 32, marginBottom: 6, fontFamily: "Helvetica-Bold" }}>
                                    Lighthouse Lotto
                                </Text>
                                <Text style={{ fontSize: 32, marginBottom: 6, fontFamily: "Helvetica-Bold" }}>
                                    Winner Summary
                                </Text>
                            </View>
                            <Image src={lighthouseLogo} style={{ width: 130, height: 100, marginRight: 30 }} />
                        </View>
                        <View>
                            <Text style={{ fontSize: 14, marginBottom: 6 }}>{`Event: ${event.name}`}</Text>
                            <Text style={{ fontSize: 14, marginBottom: 6 }}>{`Date: ${moment(event.endDateTime).format('DD/MM/yyyy')}`}</Text>
                        </View>

                        <View style={styles.table}>
                            {/* TableHeader */}
                            <View style={styles.tableRow}>
                                <View style={[styles.tableCol, {width:"10%"}]}>
                                    <Text style={styles.tableCell}>Prize #</Text>
                                </View>
                                <View style={[styles.tableCol, {width:"20%"}]}>
                                    <Text style={styles.tableCell}>Prize Name</Text>
                                </View>
                                <View style={[styles.tableCol, {width:"20%"}]}>
                                    <Text style={styles.tableCell}>Winner</Text>
                                </View>
                                <View style={[styles.tableCol, {width:"20%"}]}>
                                    <Text style={styles.tableCell}>Phone</Text>
                                </View>
                                <View style={[styles.tableCol, {width:"20%"}]}>
                                    <Text style={styles.tableCell}>Email</Text>
                                </View>
                                <View style={[styles.tableCol, {width:"10%"}]}>
                                    <Text style={styles.tableCell}>Ticket #</Text>
                                </View>
                            </View>
                            {/* TableContent */}
                            {winnerAndPrizeList.map((element: any, index) => {
                                return (
                                    <View style={styles.tableRow} key={index}>
                                        <View style={[styles.tableCol, {width:"10%"}]}>
                                            <Text style={styles.tableCell}>{index + 1}</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width:"20%"}]}>
                                            <Text style={styles.tableCell}>{element.prize.title}</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width:"20%"}]}>
                                            <Text style={styles.tableCell}>{element.winningTicket.ticket.purchase.name}</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width:"20%"}]}>
                                            <Text style={styles.tableCell}>{element.winningTicket.ticket.purchase.phone}</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width:"20%"}]}>
                                            <Text style={styles.tableCell}>{element.winningTicket.ticket.purchase.email}</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width:"10%"}]}>
                                            <Text style={styles.tableCell}>{event.eventPrefix}-{element.winningTicket.ticket.ticketNumber}</Text>
                                        </View>

                                    </View>
                                )
                            })}
                        </View>
                    </View>
                    <View style={{ marginTop: 12, display: "flex", alignItems: "center", textAlign: "center" }}>
                        <Text style={{ fontSize: 10 }}>
                            Registered Charity No. UK 1149488 | Registered Charity No. ROI 20200334 | Company No. 08244118
                        </Text>
                        <Text style={{ fontSize: 10 }}>
                            Copyright Lighthouse Construction Industry Charity 2022
                        </Text>
                    </View>
                </Page>
            </Document>
        )
    }


    return {
        generateWinnerPdf,
        generateWinnerSummaryPdf
    };
};
export default usePdfGenerator;