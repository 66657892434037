import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import Error from '../../components/Error/Error'
import EventFooter from '../../components/EventFooter/EventFooter'
import EventMenuBar from '../../components/EventMenuBar/EventMenuBar'
import Loading from '../../components/Loading/Loading'
import PrizeCard from '../../components/PrizeCard/PrizeCard'
import useFetch from '../../hooks/useFetch'
import IEvent from '../../interfaces/event'
import IPageProps from '../../interfaces/page'
import IPrize from '../../interfaces/prize'
import './prizes.scss'


const PrizesPage: React.FunctionComponent<IPageProps & RouteComponentProps<any>> = (props) => {

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>("")
    const [event, setEvent] = useState<IEvent>({} as IEvent)
    const [prizes, setPrizes] = useState<IPrize[]>([])

    const eventApi = useFetch("events")

    const getEvent = (): void => {
        setLoading(true)
        eventApi.get(`/getEventById/${props.match.params.eventId}/false`).then((event: IEvent) => {
            eventApi.get(`/${props.match.params.eventId}/prizes`).then((prizes: IPrize[]) => {
                setEvent(event)
                setPrizes(prizes.sort((a, b) => a.orderNumber - b.orderNumber))
                setLoading(false)
            }).catch((err: Error) => {
                setError(err.message)
                setLoading(false)
            })
        }).catch((err: Error) => {
            setError(err.message)
            setLoading(false)
        })
    }

    useEffect(() => {
        getEvent()

        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    if (loading) {
        return (
            <div className="prizes-page">
                <EventMenuBar event={event} />
                <Loading />
            </div>
        )
    }

    if (error) {
        return (
            <div className="prizes-page">
                <EventMenuBar event={event} />
                <Error buttonAction={() => window.location.reload()} error={error} eventId={props.match.params.auctionId} />
                {/* <AuctionFooter appConfig={auction.appConfig} isFixedBidSection={false} /> */}
            </div>
        )
    }


    return (
        <div className="prizes-page">
            <EventMenuBar event={event} />
            <div className="header-section" style={{ backgroundColor: event.headerBackgroundColor, color: event.headerFontColor }}>Prizes</div>
            <div className="content">
                {prizes.length > 0 ?
                    <div className="prize-wrapper">
                        {prizes.map((prize, index) => {
                            return (
                                <PrizeCard key={index} prize={prize} event={event} />
                            )
                        })}
                    </div>
                    :
                    <div className="no-prizes-wrapper">
                        <h2>This event currently has no prizes</h2>
                    </div>
                }
            </div>
            <EventFooter event={event} />
        </div>
    )
}

export default PrizesPage
