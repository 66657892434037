import React, { useEffect, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableHead, TableRow, TableCell, TableBody, Checkbox } from "@mui/material"
import IUser from "../../../interfaces/user";

interface InputProps {
    isDialogOpen: boolean
    handleClose: () => void
    allUsers: IUser[]
    setAllUsers: (allUsers: IUser[]) => void
    eventId: string
}

const AddUserDialog = (props: InputProps) => {

    const [selected, setSelected] = useState<string[]>([])
    const [validUsers, setValidUsers] = useState<IUser[]>([])

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            // console.log(validUsers)
            const newSelecteds = validUsers.map((user) => user.uid);
            // console.log(newSelecteds)
            setSelected(newSelecteds);
        }else{
            setSelected([])
        }
    };

    const handleClick = (name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: any[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const isSelected = (user: any) => selected.indexOf(user) !== -1;

    const save = () => {
        let tempAllUsers = [...props.allUsers]
        selected.forEach(selectedUid => {
            tempAllUsers.forEach(user => {
                if (user.uid === selectedUid){
                    user.allocatedEventId = props.eventId
                }
            }); 
        });
        props.setAllUsers(tempAllUsers)
        setSelected([])
        props.handleClose()

    }

    useEffect(() => {
        setValidUsers(props.allUsers.filter(user => (user.allocatedEventId !== props.eventId) && (user.isAdmin !== true)))
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [props.allUsers]);

    return (
        <>
            <Dialog
                open={props.isDialogOpen}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Users</DialogTitle>
                <DialogContent>
                    {/* <pre>allUsers: {JSON.stringify(props.allUsers)}</pre>
                    <pre>validUsers: {JSON.stringify(validUsers)}</pre>
                    <pre>selected: {JSON.stringify(selected)}</pre> */}
                    {validUsers.length > 0 ?
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox" className="hide-on-mobile">
                                        <Checkbox
                                            indeterminate={selected.length > 0 && selected.length < validUsers.length}
                                            checked={validUsers.length > 0 && selected.length === validUsers.length}
                                            onChange={handleSelectAllClick}
                                            inputProps={{ 'aria-label': 'select all users' }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <div className="table-header-cell">
                                            <span>Username</span>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className="table-header-cell">
                                            <span>Current Assigned Event</span>
                                        </div>
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.allUsers.filter(user => (user.allocatedEventId !== props.eventId) && (user.isAdmin !== true)).map((user: IUser, index) => (
                                    <TableRow key={user._id}>
                                        <TableCell padding="checkbox" className="hide-on-mobile">
                                            <Checkbox
                                                checked={isSelected(user.uid)}
                                                inputProps={{ 'aria-labelledby': user._id }}
                                                onClick={() => handleClick(user.uid)}
                                            />
                                        </TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>{user.allocatedEventId}</TableCell>
                                    </TableRow>

                                ))}
                            </TableBody>
                        </Table>
                        :
                        <div className="no-entries">No users found.</div>
                    }


                </DialogContent>
                <DialogActions>
                    <div className="save-button-wrapper">
                        <div className="buttons">
                            <Button id="cancel-button" onClick={props.handleClose} >
                                Cancel
                            </Button>
                            <Button id="save-button" onClick={save} className="lh-button">
                                Save
                            </Button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AddUserDialog