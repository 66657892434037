import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, Select, FormHelperText, MenuItem } from "@mui/material"
import usePurchaseValidation from "./usePurchaseStatusValidation";
import IPurchase from "../../../interfaces/purchase";
import './editPurchaseDialog.scss'
import moment from 'moment'
import IEvent from "../../../interfaces/event";

interface InputProps {
    isDialogOpen: boolean,
    handleClose: () => void,
    savePurchase: () => void,
    purchase: IPurchase,
    setPurchase: (purchase: IPurchase) => void
    isTableNumber: boolean
    tableNumber: any
    event: IEvent
    totalTicketsBought: number
    totalTicketPrice: number
}

const EditPurchaseStatusDialog = (props: InputProps) => {

    const purchaseStatusValidation = usePurchaseValidation(props.purchase)

    const handleChange = (event: any) => {
        const { name, value } = event.target
        props.setPurchase({ ...props.purchase, [name]: value })
    }

    const savePurchase = () => {
        if (purchaseStatusValidation.validateInputs()) {
            props.savePurchase()
        }
    }

    return (
        <>
            <Dialog
                open={props.isDialogOpen}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
                className="edit-purchase-status-dialog"
            >
                <DialogTitle id="form-dialog-title">Purchase Details</DialogTitle>
                <DialogContent>
                    <div className="header">
                        <div className='detail'>
                            <div className='label'>Timestamp: </div>
                            <div className='value'>{moment(props.purchase.timestamp).format("HH:mm DD/MM/YYYY")}</div>
                        </div>
                        <div className='detail'>
                            <div className='label'>Purchaser Name: </div>
                            <div className='value'>{props.purchase.name}</div>
                        </div>
                        <div className='detail'>
                            <div className='label'>Transaction Type: </div>
                            <div className='value'>{props.purchase.transactionType}</div>
                        </div>
                        {props.purchase.stripePaymentIntentId &&
                            <div className='detail'>
                                <div className='label'>Stripe Payment Intent ID: </div>
                                <div className='value'>{props.purchase.stripePaymentIntentId}</div>
                            </div>
                        }
                        {props.isTableNumber &&
                            <div className='detail'>
                                <div className='label'>Table Number: </div>
                                <div className='value'>{props.tableNumber}</div>
                            </div>
                        }
                        <div className='detail'>
                            <div className='label'>Tickets Bought: </div>
                            <div className='value'>{new Intl.NumberFormat().format(props.totalTicketsBought)}</div>
                        </div>
                        <div className='detail'>
                            <div className='label'>Total Cost: </div>
                            <div className='value'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'GBP', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(props.totalTicketPrice)}</div>
                        </div>
                        <div className='detail'>
                            <div className='label'>Ticket No(s): </div>
                            <div className='value'>
                                <span>{`${props.event.eventPrefix}_${props.purchase.ticketNumbers && props.purchase.ticketNumbers[0]}`}</span>
                                {props.purchase.ticketNumbers && props.purchase.ticketNumbers.length > 1 &&
                                    <span>
                                        {`- ${props.event.eventPrefix}_${props.purchase.ticketNumbers && props.purchase.ticketNumbers[props.purchase.ticketNumbers.length - 1]}`}
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                    {props.purchase.transactionType === "OFFLINE" &&
                        <div className="data-inputs-wrapper">
                            <FormControl
                                variant="outlined"
                                fullWidth
                                required
                            >
                                <InputLabel id="type-label">Status</InputLabel>
                                <Select
                                    labelId="type-label"
                                    id="status"
                                    name="status"
                                    value={props.purchase.status}
                                    onChange={handleChange}
                                    label="Status *"
                                >
                                    <MenuItem value={'PAID'}>PAID</MenuItem>
                                    <MenuItem value={'PENDING'}>PENDING</MenuItem>
                                </Select>

                            </FormControl>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                required
                                error={!purchaseStatusValidation.getValidation("paymentType").isValid}
                            >
                                <InputLabel id="type-label">Payment Type</InputLabel>
                                <Select
                                    labelId="type-label"
                                    id="paymentType"
                                    name="paymentType"
                                    value={props.purchase.paymentType}
                                    onChange={handleChange}
                                    label="Payment Type *"
                                >
                                    {/* <MenuItem value={''}>&nbsp;</MenuItem> */}
                                    <MenuItem value={'CASH'}>CASH</MenuItem>
                                    <MenuItem value={'CARD'}>CARD</MenuItem>
                                </Select>
                                {!purchaseStatusValidation.getValidation("paymentType").isValid && <FormHelperText>{purchaseStatusValidation.getValidation("paymentType").validationMessage} </FormHelperText>}
                            </FormControl>

                            <TextField
                                variant="outlined"
                                id="paymentReference"
                                name="paymentReference"
                                label="Payment Reference"
                                type="text"
                                fullWidth
                                value={props.purchase.paymentReference}
                                onChange={handleChange}
                            />
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                    <div className="save-button-wrapper">
                        {!purchaseStatusValidation.isValidationPassed && <span className="validation-text">Errors highlighted in form - please resolve before saving.</span>}
                        <div className="buttons">
                            <Button id="cancel-button" onClick={props.handleClose} >
                                {props.purchase.transactionType === "OFFLINE" ? <span>Cancel</span> : <span>OK</span>}
                            </Button>
                            {props.purchase.transactionType === "OFFLINE" &&
                                <Button id="save-button" onClick={savePurchase} className="lh-button">
                                    Save
                                </Button>
                            }
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default EditPurchaseStatusDialog